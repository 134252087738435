import React, { useState, useEffect } from 'react';
import { baseUrl, linkServidor } from '../../services/apirest';
import axios from 'axios';
import '../../assets/css/admin.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 
import Swal from 'sweetalert2';

function Productos(){
  const [Productos, setProductos] = useState([]);
  const [Marcas, setMarcas] = useState([]);
  const [ProductoID, setProductoID] = useState([]);
  const [Categorias, setCategorias] = useState([]);
  const [Subcategorias, setSubcategorias] = useState([]);
  const [Etiquetas, setEtiquetas] = useState([]);
  const [CaracteristicasProducto, setCaracteristicasProducto] = useState([]);
  const [CategoriasEditar, setCategoriasEditar] = useState([]);
  const [SubCategoriasEditar, setSubCategoriasEditar] = useState([]);
  const [EtiquetasEditar, setEtiquetasEditar] = useState([]);
  const [CaracteristicaProductos, setCaracteristicaProductos] = useState([]);
  const [GaleriaProductos, setGaleriaProductos] = useState([]);

  const [IDProductoEdit, setIDProductoEdit] = useState('');
  const [Nombre, setNombre] = useState('');
  const [Marca, setMarca] = useState('');
  const [SKU, setSKU] = useState('');
  const [NombreCorto, setNombreCorto] = useState('');
  const [CategoriaEnvio, setCategoriaEnvio] = useState('');
  const [NumeroParte, setNumeroParte] = useState('');
  const [Precio, setPrecio] = useState('');
  const [PrecioWeb, setPrecioWeb] = useState('');
  const [PrecioPromocion, setPrecioPromocion] = useState('');
  const [Stock, setStock] = useState('');
  const [PrecioActivo, setPrecioActivo] = useState('');
  const [ImagenPri, setImagenPri] = useState('');
  const [ImagenSec, setImagenSec] = useState('');

  const [IDCaracteristica, setIDCaracteristica] = useState('');
  const [Caracteristica, setCaracteristica] = useState('');
  const [DescripcionCaracte, setDescripcionCaracte] = useState('');
  const [EstadoCaraste, setEstadoCaraste] = useState(false);
  
  const [ProductosPromo, setProductosPromo] = useState([]);
  const [IDElegido, setItem] = useState([]);
  const [Identificado, setIdentificadores] = useState([]);

  let Elegido = JSON.parse(sessionStorage.getItem('combo'))?JSON.parse(sessionStorage.getItem('combo')):[];
  let Identificador = JSON.parse(sessionStorage.getItem('idcombo'))?JSON.parse(sessionStorage.getItem('idcombo')):[];

  const GetPromo = () =>{
    setItem(Elegido);
    setIdentificadores(Identificador);
  }

  const getProducto = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/producto`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setProductos(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/marca`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setMarcas(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/categoria`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setCategorias(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/subcategoria`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setSubcategorias(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/etiqueta`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setEtiquetas(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const addProduct = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/producto`, 
      data: JSON.stringify({
        description : Nombre,
        desc_corta : NombreCorto,
        sku : SKU,
        part_number : NumeroParte,
        marca_id : Marca,
        categoia_envio : CategoriaEnvio,
        stock : Stock,
        precio_activo : PrecioActivo,
        precio : Precio,
        precio_web : PrecioWeb,
        precio_prom : PrecioPromocion,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setProductoID(json.data.data.id);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getProducto();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const UploadPri = (e) => {
    const formData = new FormData();
    formData.append("dir_img_pri",  e);
    let token = localStorage.getItem("token");
    axios({ 
      method: 'post',
      url : `${baseUrl}/producto/updateImgPri/${ProductoID}`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getProducto();
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const UploadSec = (e) => {
    const formData = new FormData();
    formData.append("dir_img_sec",  e);
    let token = localStorage.getItem("token");
    axios({ 
      method: 'post',
      url : `${baseUrl}/producto/updateImgSec/${ProductoID}`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getProducto();
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const onCambioCategoria = (e) => {
    const Cat = e.target.value;
    const EstadoCat = e.target.checked;
    console.log(ProductoID, Cat, EstadoCat);
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/CategoriaProducto/setCategoriasByProducto`, 
      data: JSON.stringify({
        producto_id : ProductoID,
        categoria_id : Cat,
        estado : EstadoCat,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const onCambioSubCategoria = (e) => {
    const Subcat = e.target.value;
    const EstadoSubcat = e.target.checked;
    console.log(ProductoID, Subcat, EstadoSubcat);
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/SubCategoriaProducto/setSubcategoriasByProduct`, 
      data: JSON.stringify({
        producto_id : ProductoID,
        subcategoria_id : Subcat,
        check : EstadoSubcat,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const onCambioEtiqueta = (e) => {
    const Eti = e.target.value;
    const EstadoEti = e.target.checked;
    console.log(ProductoID, Eti, EstadoEti);
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/EtiquetaProducto/setEtiquetasByProducto`, 
      data: JSON.stringify({
        producto_id : ProductoID,
        etiqueta_id : Eti,
        check : EstadoEti,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const DetalleCaracteristicas = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/caracteristica/getByProductoId/${ProductoID}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data.data);
      setCaracteristicasProducto(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (IDCaracteristica) {
      EditaCaracteProduct(e);
    } else {
      addProductCaracte(e);
    }
  };

  const addProductCaracte = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    console.log(ProductoID);
    console.log(IDProductoEdit);
    axios({
      method: "POST",
      url : `${baseUrl}/caracteristica`, 
      data: JSON.stringify({
        producto_id : IDProductoEdit,
        caracteristica : Caracteristica,
        descripcion : DescripcionCaracte,
        estado : EstadoCaraste,
      }),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      axios.get(`${baseUrl}/caracteristica/getByProductoId/${IDProductoEdit}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response.data.data);
        setCaracteristicaProductos(response.data.data);
      })
      .catch((err)=>{
        console.log(err);
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const UploadGaleria = (e) => {
    const formData = new FormData();
    formData.append("dir_img",  e);
    formData.append("producto_id",  ProductoID);
    let token = localStorage.getItem("token");
    axios({ 
    method: 'post',
      url : `${baseUrl}/galeriaProducto`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      })
      .then((response) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardado con éxito',
          showConfirmButton: false,
          timer: 1500
        });
        axios.get(`${baseUrl}/galeriaProducto/getGalleryProduct/${ProductoID}`, {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        })
        .then((response) => {
          console.log(response.data.data);
          setGaleriaProductos(response.data.data);
          $(".otraimagen").click();
        })
        .catch((err)=>{
          console.log(err);
        });
      })
    .catch((err)=>{
      console.log(err);
    });
  };

  const EditProduct = (edit) => {
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/producto/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data.data);
      setIDProductoEdit(response.data.data.id);
      setNombre(response.data.data.description);
      setMarca(response.data.data.marca_id);
      setSKU(response.data.data.sku);
      setPrecio(response.data.data.precio);
      setPrecioWeb(response.data.data.precio_web);
      setPrecioPromocion(response.data.data.precio_prom);
      setNombreCorto(response.data.data.desc_corta);
      setCategoriaEnvio(response.data.data.categoia_envio);
      setNumeroParte(response.data.data.part_number);
      setStock(response.data.data.stock);
      setPrecioActivo(response.data.data.precio_activo);
      setImagenPri(response.data.data.dir_img_pri)
      setImagenSec(response.data.data.dir_img_sec)
      $('#verimagen').css('display', 'none');
      $('#verimagens').css('display', 'none');
      $('#ocultarimagen').css('display', 'block');
      $('#ocultarimagens').css('display', 'block');
      $('.imagespri span').css('display', 'block');
      $('.imagestwo span').css('display', 'block');
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/CategoriaProducto/getCategoriasByProducto/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data);
      setCategoriasEditar(response.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/SubCategoriaProducto/getSubcategoriasByProducto/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data);
      setSubCategoriasEditar(response.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/EtiquetaProducto/getEtiquetasByProducto/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data);
      setEtiquetasEditar(response.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/caracteristica/getByProductoId/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data.data);
      setCaracteristicaProductos(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/galeriaProducto/getGalleryProduct/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data.data);
      setGaleriaProductos(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const EditaProduct = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/producto/update/${IDProductoEdit}`, 
      data: JSON.stringify({
        description : Nombre,
        desc_corta : NombreCorto,
        sku : SKU,
        part_number : NumeroParte,
        marca_id : Marca,
        categoia_envio : CategoriaEnvio,
        stock : Stock,
        precio_activo : PrecioActivo,
        precio : Precio,
        precio_web : PrecioWeb,
        precio_prom : PrecioPromocion,
      }),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data.data);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getProducto();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const EditUploadPri = (e) => {
    const formData = new FormData();
    formData.append("dir_img_pri",  e);
    console.log(e);
    let token = localStorage.getItem("token");
    axios({ 
      method: 'POST',
      url : `${baseUrl}/producto/updateImgPri/${IDProductoEdit}`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response.data);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getProducto();
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const EditUploadSec = (e) => {
    const formData = new FormData();
    formData.append("dir_img_sec",  e);
    let token = localStorage.getItem("token");
    axios({ 
      method: 'post',
      url : `${baseUrl}/producto/updateImgSec/${IDProductoEdit}`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getProducto();
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const onCambioCategoriaEdit = (e) => {
    const Cat = e.target.value;
    const EstadoCat = e.target.checked;
    console.log(IDProductoEdit, Cat, EstadoCat);
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/CategoriaProducto/setCategoriasByProducto`, 
      data: JSON.stringify({
        producto_id : IDProductoEdit,
        categoria_id : Cat,
        estado : EstadoCat,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      axios.get(`${baseUrl}/CategoriaProducto/getCategoriasByProducto/${IDProductoEdit}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response.data);
        setCategoriasEditar(response.data);
      })
      .catch((err)=>{
        console.log(err);
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const onCambioSubCategoriaEdit = (e) => {
    const Subcat = e.target.value;
    const EstadoSubcat = e.target.checked;
    console.log(IDProductoEdit, Subcat, EstadoSubcat);
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/SubCategoriaProducto/setSubcategoriasByProduct`, 
      data: JSON.stringify({
        producto_id : IDProductoEdit,
        subcategoria_id : Subcat,
        check : EstadoSubcat,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      axios.get(`${baseUrl}/SubCategoriaProducto/getSubcategoriasByProducto/${IDProductoEdit}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response.data);
        setSubCategoriasEditar(response.data);
      })
      .catch((err)=>{
        console.log(err);
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const onCambioEtiquetaEdit = (e) => {
    const Eti = e.target.value;
    const EstadoEti = e.target.checked;
    console.log(IDProductoEdit, Eti, EstadoEti);
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/EtiquetaProducto/setEtiquetasByProducto`, 
      data: JSON.stringify({
        producto_id : IDProductoEdit,
        etiqueta_id : Eti,
        check : EstadoEti,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      axios.get(`${baseUrl}/EtiquetaProducto/getEtiquetasByProducto/${IDProductoEdit}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response.data);
        setEtiquetasEditar(response.data);
      })
      .catch((err)=>{
        console.log(err);
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const clickeCaracteristicas = (e) =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/caracteristica/${e}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data.data);
      console.log(response.data.data.id);
      setIDCaracteristica(response.data.data.id);
      setCaracteristica(response.data.data.caracteristica);
      setDescripcionCaracte(response.data.data.descripcion);
      if(response.data.data.estado == 1){
        setEstadoCaraste(true);
      }else{
        setEstadoCaraste(false);
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const EditaCaracteProduct = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/caracteristica/update/${IDCaracteristica}`, 
      data: JSON.stringify({
        producto_id : IDProductoEdit,
        caracteristica : Caracteristica,
        descripcion : DescripcionCaracte,
        estado : EstadoCaraste,
      }),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      e.target.reset();
      console.log(json.data.data);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Editado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      axios.get(`${baseUrl}/caracteristica/getByProductoId/${IDProductoEdit}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response.data.data);
        setCaracteristicaProductos(response.data.data);
      })
      .catch((err)=>{
        console.log(err);
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const DeletCaracteProduct = (id) =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/caracteristica/destroy/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((responses) => {
      console.log(responses);
      axios.get(`${baseUrl}/caracteristica/getByProductoId/${IDProductoEdit}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response.data.data);
        setCaracteristicaProductos(response.data.data);
      })
      .catch((err)=>{
        console.log(err);
      });
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Eliminado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const UploadGaleriaEditar = (e) => {
    console.log(e);
    const formData = new FormData();
    formData.append("dir_img",  e);
    formData.append("producto_id",  IDProductoEdit);
    let token = localStorage.getItem("token");
    axios({ 
    method: 'post',
      url : `${baseUrl}/galeriaProducto`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      })
      .then((response) => {
        console.log(response);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardado con éxito',
          showConfirmButton: false,
          timer: 1500
        });
        axios.get(`${baseUrl}/galeriaProducto/getGalleryProduct/${IDProductoEdit}`, {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        })
        .then((response) => {
          console.log(response.data.data);
          setGaleriaProductos(response.data.data);
          $(".otraimagen").click();
        })
        .catch((err)=>{
          console.log(err);
        });
      })
    .catch((err)=>{
      console.log(err);
    });
  };

  const DeleteGaleria = (id) =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/galeriaProducto/destroy/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      console.log(response.data.data);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Eliminado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      axios.get(`${baseUrl}/galeriaProducto/getGalleryProduct/${IDProductoEdit}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response.data.data);
        setGaleriaProductos(response.data.data);
      })
      .catch((err)=>{
        console.log(err);
      });
    })
    .catch((error) => {
    });
  }

  const onElegirProductos = (e) => {
    const productos = e.target.value;
    if(productos == ''){

    }else{
      let token = localStorage.getItem("token");
      fetch(`${baseUrl}/Combo/buscarProductos`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({
          term : productos,
        }),
      })
      .then((response) => response.json())
      .then((json)=> {
        console.log(json);
        setProductosPromo(json.data);
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  };

  const unique = (id) => {
    const resultado = Elegido.find( fruta => fruta.ident == id );
    return resultado;
  }

  const agregarProductos = (id, sku) => {
    GetPromo();
    if(!unique(id)){
      Elegido.push({ident: id, skuu : sku });
      Identificador.push(id);
      console.log(Elegido);
      console.log(Identificador);
    }
    sessionStorage.setItem("combo", JSON.stringify(Elegido));
    sessionStorage.setItem("idcombo", JSON.stringify(Identificador));
  }

  const eliminarProducto = (e) =>{
    console.log(e);
    Elegido = Elegido.filter(usuario => usuario.ident != e);
    Identificador = Identificador.filter(usuario => usuario != e);
    GetPromo();
    sessionStorage.setItem("combo", JSON.stringify(Elegido));
    sessionStorage.setItem("idcombo", JSON.stringify(Identificador));
  }

  const componentDidMount = () => {
    $(document).ready(function() {
      // IMAGEN PRIMARIA DEL CREAR
      $('#imageInputPri').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
          $('.image-preview-primg').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-button-primg').css('display', 'none');
          $('.image-preview-primg').css('display', 'block');
          $('.change-image-primg').css('display', 'block');
        }
      });
      $('.change-image-primg').on('click', function() {
        let $control = $(this);			
        $('#imageInputPri').val('');	
        let $preview = $('.image-preview-primg');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-button-primg').css('display', 'block');
      });
      // FIN DE LA IMAGEN PRIMARIA DEL CREAR
  
      // IMAGEN SECUNDARIA DEL CREAR
      $('#imageInputtwo').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
          $('.image-previewtwo').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-buttons-twosec').css('display', 'none');
          $('.image-previewtwo').css('display', 'block');
          $('.change-imagen-twosec').css('display', 'block');
        }
      });
      $('.change-imagen-twosec').on('click', function() {
        let $control = $(this);			
        $('#imageInputtwo').val('');	
        let $preview = $('.image-previewtwo');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-buttons-twosec').css('display', 'block');
      });
      // FIN DE LA IMAGEN SECUNDARIA DEL CREAR

      // GALERIA DE IMAGENES PRINCIPAL DEL CREAR
      $('#imageInputgaleria-pri').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
            $('.image-preview-galeria-pri').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-buttonns-pri').css('display', 'none');
          $('.image-preview-galeria-pri').css('display', 'block');
          $('.change-image-prigaleria').css('display', 'block');
        }
      });
      $('.change-image-prigaleri').on('click', function() {
        let $control = $(this);			
        $('#imageInputgaleria-pri').val('');	
        let $preview = $('.image-preview-galeria-pri');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-buttonns-pri').css('display', 'block');
      });
      // FIN DE LA GALERIA DE IMAGENES PRINCIPAL DEL CREAR
      
      // IMAGEN PRINCIPAL DEL EDITAR
      $('#imageInputs').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
            $('.image-previewedit').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-buttonspri').css('display', 'none');
          $('.image-previewedit').css('display', 'block');
          $('.change-imagens').css('display', 'block');
        }
      });
      $('.change-imagens').on('click', function() {
        let $control = $(this);			
        $('#imageInputs').val('');	
        let $preview = $('.image-previewedit');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-buttonspri').css('display', 'block');
      });
      // FIN DE LA IMAGEN PRINCIPAL DEL EDITAR

      // IMAGEN SECUNDARIA DEL EDITAR
      $('#imageInputss').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
            $('.image-preview-twoedit').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-buttons-two').css('display', 'none');
          $('.image-preview-twoedit').css('display', 'block');
          $('.change-image-two').css('display', 'block');
        }
      });
      $('.change-image-two').on('click', function() {
        let $control = $(this);			
        $('#imageInputss').val('');	
        let $preview = $('.image-preview-twoedit');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-buttons-two').css('display', 'block');
      });
      // FIN DE LA IMAGEN SECUNDARIA DEL EDITAR

      // GALERIA DE IMAGENES DEL EDITAR
      $('#imageInputgaleria-edit').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
            $('.image-preview-galeria-edit').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-buttonns-galeri').css('display', 'none');
          $('.image-preview-galeria-edit').css('display', 'block');
          $('.change-image-edit-galeria').css('display', 'block');
        }
      });
      $('.change-image-edit-galeria').on('click', function() {
        let $control = $(this);			
        $('#imageInputgaleria-edit').val('');	
        let $preview = $('.image-preview-galeria-edit');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-buttonns-galeri').css('display', 'block');
      });
      // FIN DE LA GALERIA DE IMAGENES DEL EDITAR

      $(document).ready(function () {
        $('#example').DataTable();
      });
    });
  }

  useEffect(()=>{
    componentDidMount();
    getProducto();
  }, []);

  return(
    <div className="admin espacio-admin">
      <main className="page-content">
        <h2 className="titulo-admin">MODULO DE PRODUCTOS</h2>
        <div className="col-12 button-categoria">
          <button type="button" className="btn btn-inverse-success btn-fw" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Agregar Producto
          </button>
        </div>
        <div className="tabla-admin">
          <table id="example" className="table table-striped" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ID</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>NOMBRE</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>STOCK</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>PRECIO ACTIVO</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>PRECIO</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>PRECIO WEB</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>PRECIO PROMOCIÓN</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ESTADO</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ display: "none" }}> 
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
              </tr>
              {Productos.map((value, index) =>{
                return (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{value.id}</td>
                    <td style={{ textAlign: "center" }}>{value.description}</td>
                    <td style={{ textAlign: "center" }}>{value.stock}</td>
                    <td style={{ textAlign: "center" }}>{value.precio_activo}</td>
                    <td style={{ textAlign: "center" }}>{value.precio}</td>
                    <td style={{ textAlign: "center" }}>{value.precio_web}</td>
                    <td style={{ textAlign: "center" }}>{value.precio_prom}</td>
                    <td style={{ textAlign: "center" }}>{value.estado}</td>
                    <td style={{ textAlign: "center" }} onClick={() => {EditProduct(value.id)}}>
                      <div style={{ justifyContent: "space-evenly", display: "flex", alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" data-bs-toggle="modal" data-bs-target="#exampleModale" height="21" fill="currentColor" className="bi bi-pencil-square edit-admin" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </main>

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Agregar Producto</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div style={{ margin: "2% 1% 5%" }}>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Datos</button>
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Imagenes</button>
                  <button className="nav-link"data-bs-toggle="tab" data-bs-target="#asignacion-pro" type="button" role="tab" aria-controls="asignacion-pro" aria-selected="false">Asignaciones</button>
                  <button className="nav-link"data-bs-toggle="tab" data-bs-target="#caracteristicas-pro" type="button" role="tab" aria-controls="caracteristicas-pro" aria-selected="false">Caracteristicas</button>
                  <button className="nav-link"data-bs-toggle="tab" data-bs-target="#galeria-pro" type="button" role="tab" aria-controls="galeria-pro" aria-selected="false">Galeria</button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                  <form onSubmit={(e) => {addProduct(e)}}>
                    <div className="modal-body" style={{ display: "flex" }}>
                      <div className="col-6">
                        <div className="form__group field">
                          <input required="" placeholder="nombre" className="form__field" type="input" id="nombre" name="description" onChange={(e) => { setNombre(e.target.value) }}/>
                          <label className="form__label" for="nombre" style={{ marginTop: "2%" }}>Nombre</label>
                        </div>
                        <div className="form__group field" style={{ marginTop: "3%" }}>
                          <label className="form__label" for="icono">Marca</label>
                          <select className="form-select" aria-label="Default select example" style={{ marginTop: "3%" }} name="marca_id" onChange={(e) => { setMarca(e.target.value) }}>
                            <option value={0} selected>Seleccionar</option>
                            {Marcas.map((elemento, index)  => (
                              <option key={index} value={elemento.id}>{elemento.descripcion}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="sku" className="form__field" type="input" id="sku" name="sku" onChange={(e) => { setSKU(e.target.value) }}/>
                          <label className="form__label" for="sku" style={{ marginTop: "2%" }}>SKU</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="precio" className="form__field" type="input" id="precio" name="precio" onChange={(e) => { setPrecio(e.target.value) }}/>
                          <label className="form__label" for="precio">Precio</label>
                        </div> 
                        <div className="form__group field">
                          <input required="" placeholder="precio web" className="form__field" type="input" id="precio_web" name="precio_web" onChange={(e) => { setPrecioWeb(e.target.value) }}/>
                          <label className="form__label" for="precio web">Precio web</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="precio promocion" className="form__field" type="input" id="precio_prom" name="precio_prom" onChange={(e) => { setPrecioPromocion(e.target.value) }}/>
                          <label className="form__label" for="precio promocion">Precio promoción</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form__group field">
                          <input required="" placeholder="nombre corto" className="form__field" type="input" id="nombrecorto" name="desc_corta" onChange={(e) => { setNombreCorto(e.target.value) }}/>
                          <label className="form__label" for="nombre corto" style={{ marginTop: "2%" }}>Nombre corto</label>
                        </div>
                        <div className="form__group field" style={{ marginTop: "3%" }}>
                          <label className="form__label" for="icono">Categoria de envío</label>
                          <select className="form-select" aria-label="Default select example" style={{ marginTop: "3%" }} name="categoia_envio" onChange={(e) => { setCategoriaEnvio(e.target.value) }}>
                            <option selected>Seleccionar</option>
                            <option value="1">Periferico</option>
                            <option value="2">PC Completa</option>
                            <option value="3">Silla Gamer</option>
                            <option value="4">Monitores</option>
                          </select>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="numero de parte" className="form__field" type="input" id="numerodeparte" name="part_number" onChange={(e) => { setNumeroParte(e.target.value) }}/>
                          <label className="form__label" for="numero de parte" style={{ marginTop: "2%" }}>Numero de parte</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="stock" className="form__field" type="input" id="stock" name="stock" onChange={(e) => { setStock(e.target.value) }}/>
                          <label className="form__label" for="stock" style={{ marginTop: "2%" }}>Stock</label>
                        </div>
                        <div className="form__group field" style={{ marginTop: "3%" }}>
                          <label className="form__label" for="icono">Precio activo</label>
                          <select className="form-select" aria-label="Default select example" style={{ marginTop: "3%" }} name="precio_activo" onChange={(e) => { setPrecioActivo(e.target.value) }}>
                            <option selected>Seleccionar</option>
                            <option value="1">Precio</option>
                            <option value="2">Precio Web</option>
                            <option value="3">Precio Promoción</option>
                          </select>
                        </div>
                        <div className="form__group field" style={{ justifyContent: "end", display: "flex" }}>
                          <button type="submit" className="btn btn-inverse-success btn-fw">
                            Guardar Cambios
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                  <div className="modal-body" style={{ display: "flex" }}>
                    <div className="col-6">
                      <form onSubmit={(e) => {UploadPri(e)}}>
                        <div className="form__group field" style={{ marginTop: "-3%" }}>
                          <div className="image-input">
                            <input type="file" accept="image/*" id="imageInputPri" name="dir_img_pri" onChange={(e) => UploadPri(e.target.files[0])}/>
                            <label for="imageInputPri" className="image-button-primg">
                              <i className="far fa-image"></i> 
                              Elegir imagen principal
                            </label>
                            <img src="" className="image-preview-primg"/>
                            <span className="change-image-primg">Elige otra imagen principal</span>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-6">
                      <form onSubmit={(e) => {UploadSec(e)}}>
                        <div className="form__group field" style={{ marginTop: "-3%" }}>
                          <div className="image-input">
                            <input type="file" accept="image/*" id="imageInputtwo" name="dir_img_sec" onChange={(e) => UploadSec(e.target.files[0])}/>
                            <label for="imageInputtwo" className="image-buttons-twosec">
                              <i className="far fa-image"></i> 
                              Elegir imagen secundaria
                            </label>
                            <img src="" className="image-previewtwo"/>
                            <span className="change-imagen-twosec">Elige otra imagen secundaria</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="asignacion-pro" role="tabpanel" aria-labelledby="asignacion-tab" tabindex="0">
                  <div className="modal-body">
                    <div style={{ display: "flex" }}>
                      <div className="col-6">
                        <h6>Categorias</h6>
                        <div className="categoriaelegir" style={{ marginBottom: "6%" }}>
                          {Categorias.map(elemento =>{
                            return(
                              <div className="form__group">
                                <input type="checkbox" id={`box-a${elemento.id}`} value={elemento.id} name="categoria_id" onChange={(e) => { onCambioCategoria(e) }}/>
                                <label className="checkbox-custom-label" for={`box-a${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-6">
                        <h6>Subcategorias</h6>
                        <div className="categoriaelegir" style={{ marginBottom: "6%" }}>
                          {Subcategorias.map(elemento =>{
                            return(
                              <div className="form__group">
                                <input type="checkbox" id={`box-e${elemento.id}`} value={elemento.id} name="subcategoria_id" onChange={(e) => { onCambioSubCategoria(e) }}/>
                                <label className="checkbox-custom-label" for={`box-e${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <h6>Etiquetas</h6>
                      <div className="categoriaelegir">
                        {Etiquetas.map(elemento =>{
                          return(
                            <div className="form__group">
                              <input type="checkbox" id={`box-i${elemento.id}`} value={elemento.id} name="etiqueta_id" onChange={(e) => { onCambioEtiqueta(e) }}/>
                              <label className="checkbox-custom-label" for={`box-i${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="caracteristicas-pro" role="tabpanel" aria-labelledby="caracteristicas-tab" tabindex="0">
                  <div className="modal-body" style={{ display: "flex" }}>
                    <div className="col-6">
                      <form onSubmit={(e) => {addProductCaracte(e)}}>
                        <div className="form__group field">
                          <input required="" placeholder="caracteristica" className="form__field" type="input" id="caracteristica" name="caracteristica" onChange={(e) => { setCaracteristica(e.target.value) }}/>
                          <label className="form__label" for="caracteristica" style={{ marginTop: "2%" }}>Caracteristica</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="descripcion-caracte" className="form__field" type="input" id="descripcion-caracte" name="descripcion-caracte" onChange={(e) => { setDescripcionCaracte(e.target.value) }}/>
                          <label className="form__label" for="descripcion-caracte" style={{ marginTop: "2%" }}>Descripcion</label>
                        </div>
                        <div className="form__group" style={{ paddingTop: "7%" }}>
                          <input type="checkbox" id="box-0001" name="estado-caracte" value={EstadoCaraste} onChange={(e) => { setEstadoCaraste(e.target.checked) }} />
                          <label className="checkbox-custom-label" for="box-0001">Estado</label>
                        </div>
                        <div className="form__group field" style={{ justifyContent: "end", display: "flex" }}>
                          <button type="submit" className="btn btn-inverse-success btn-fw">
                            Guardar Cambios
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-6" style={{ display: "flex", marginTop:"2%" }}>
                      {CaracteristicasProducto.length == 0 &&
                        <div style={{ width: "100%", fontSize: "20px", justifyContent: "center", alignItems: "center", display: "flex", fontWeight: "200" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ marginRight: "2%" }} className="bi bi-card-checklist" viewBox="0 0 16 16">
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                          </svg>
                          <span>Agregar caracteristicas</span>
                        </div>
                      }
                      {CaracteristicasProducto.length != 0 &&
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Caracteristica</th>
                              <th>Descripción</th>
                              <th>Estado</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CaracteristicasProducto.map((value, index) =>{
                              return (
                              <tr key={index}>
                                <th style={{ borderBottom:"transparent" }}>{value.caracteristica}</th>
                                <th style={{ borderBottom:"transparent" }}>{value.descripcion}</th>
                                <th style={{ borderBottom:"transparent" }}>{value.estado?'Activo':'Inactivo'}</th>
                                <th style={{ borderBottom:"transparent" }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-pencil-square edit-admin" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                  </svg>
                                </th>
                              </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      }
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="galeria-pro" role="tabpanel" aria-labelledby="galeria-tab" tabindex="0">
                  <div className="modal-body" style={{ display: "flex" }}>
                    <div className="col-6">
                      <form onSubmit={(e) => {UploadGaleria(e)}}>
                        <div className="form__group field">
                          <div className="image-input">
                            <input type="file" accept="image/*" multiple id="imageInputgaleria-pri" name="dir_img"  onChange={(e) => UploadGaleria(e.target.files[0])}/>
                            <label for="imageInputgaleria-pri" className="image-buttonns-pri">
                              <i className="far fa-image"></i> 
                              Elegir imagen
                            </label>
                            <img src="" className="image-preview-galeria-pri" style={{ maxWidth: "250px" }}/>
                            <span id='btn-imagen' className="change-image-prigaleri otraimagen">Elige otra imagen</span>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className='col-6'>
                      <div className="jumbotron" style={{ padding: "2rem 1rem" }}>
                        {GaleriaProductos.length == 0 &&
                          <div style={{ width: "100%", fontSize: "20px", justifyContent: "center", alignItems: "center", display: "flex", fontWeight: "200" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ marginRight: "2%" }} className="bi bi-images" viewBox="0 0 16 16">
                              <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                              <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>
                            </svg>
                            <span>Agregar galeria</span>
                          </div>
                        }
                        {GaleriaProductos.length != 0 &&
                          <div>
                            {GaleriaProductos.map((value) =>{
                              return (
                                <div className='col-6' style={{ display: "inline-table", marginBottom: "2%", padding: "1%" }}>
                                  <button type="button" className="btn btn-primary" style={{ position: "absolute", marginLeft: "-2%", marginTop: "-2%", borderRadius: "50%", width: "50px", height: "50px", backgroundColor: "rgba(0,0,0, .5)", color: "#fff", border: "transparent"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                  </button>
                                  <img width='100%' src={linkServidor + '/' + value.dir_img}/>
                                </div>
                              )
                            })}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**************************************************************** EDITAR PRODUCTO **********************************************************/}

      <div className="modal fade" id="exampleModale" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Editar Producto</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div style={{ margin: "2% 1% 5%" }}>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-home2" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Datos</button>
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#nav-profile2" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Imagenes</button>
                  <button className="nav-link"data-bs-toggle="tab" data-bs-target="#asignacion-pro2" type="button" role="tab" aria-controls="asignacion-pro" aria-selected="false">Asignaciones</button>
                  <button className="nav-link"data-bs-toggle="tab" data-bs-target="#caracteristicas-pro2" type="button" role="tab" aria-controls="caracteristicas-pro" aria-selected="false">Caracteristicas</button>
                  <button className="nav-link"data-bs-toggle="tab" data-bs-target="#galeria-pro2" type="button" role="tab" aria-controls="galeria-pro" aria-selected="false">Galeria</button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home2" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                  <form onSubmit={(e) => {EditaProduct(e)}}>
                    <div className="modal-body" style={{ display: "flex" }}>
                      <div className="col-6">
                        <div className="form__group field">
                          <input required="" placeholder="nombre" className="form__field" type="input" value={Nombre} onChange={(e) => { setNombre(e.target.value) }}/>
                          <label className="form__label" for="nombre" style={{ marginTop: "2%" }}>Nombre</label>
                        </div>
                        <div className="form__group field" style={{ marginTop: "3%" }}>
                          <label className="form__label" for="icono">Marca</label>
                          <select className="form-select" style={{ marginTop: "3%" }} value={Marca} onChange={(e) => { setMarca(e.target.value) }}>
                            <option value={0} selected>Seleccionar</option>
                            {Marcas.map((elemento, index)  => (
                              <option key={index} value={elemento.id}>{elemento.descripcion}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="sku" className="form__field" type="input" value={SKU} onChange={(e) => { setSKU(e.target.value) }}/>
                          <label className="form__label" for="sku" style={{ marginTop: "2%" }}>SKU</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="precio" className="form__field" type="input" value={Precio} onChange={(e) => { setPrecio(e.target.value) }}/>
                          <label className="form__label" for="precio">Precio</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="precio web" className="form__field" type="input" value={PrecioWeb} onChange={(e) => { setPrecioWeb(e.target.value) }}/>
                          <label className="form__label" for="precio web">Precio web</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="precio promocion" className="form__field" type="input" value={PrecioPromocion} onChange={(e) => { setPrecioPromocion(e.target.value) }}/>
                          <label className="form__label" for="precio promocion">Precio promoción</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form__group field">
                          <input required="" placeholder="nombre corto" className="form__field" type="input" value={NombreCorto} onChange={(e) => { setNombreCorto(e.target.value) }}/>
                          <label className="form__label" for="nombre corto" style={{ marginTop: "2%" }}>Nombre corto</label>
                        </div>
                        <div className="form__group field" style={{ marginTop: "3%" }}>
                          <label className="form__label" for="icono">Categoria de envío</label>
                          <select className="form-select" style={{ marginTop: "3%" }} value={CategoriaEnvio} onChange={(e) => { setCategoriaEnvio(e.target.value) }}>
                            <option selected>Seleccionar</option>
                            <option value="1">Periferico</option>
                            <option value="2">PC Completa</option>
                            <option value="3">Silla Gamer</option>
                            <option value="4">Monitores</option>
                          </select>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="numero de parte" className="form__field" type="input" value={NumeroParte} onChange={(e) => { setNumeroParte(e.target.value) }}/>
                          <label className="form__label" for="numero de parte" style={{ marginTop: "2%" }}>Numero de parte</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="stock" className="form__field" type="input" value={Stock} onChange={(e) => { setStock(e.target.value) }}/>
                          <label className="form__label" for="stock" style={{ marginTop: "2%" }}>Stock</label>
                        </div>
                        <div className="form__group field" style={{ marginTop: "3%" }}>
                          <label className="form__label" for="icono">Precio activo</label>
                          <select className="form-select" style={{ marginTop: "3%" }} value={PrecioActivo} onChange={(e) => { setPrecioActivo(e.target.value) }}>
                            <option selected>Seleccionar</option>
                            <option value="1">Precio</option>
                            <option value="2">Precio Web</option>
                            <option value="3">Precio Promoción</option>
                          </select>
                        </div>
                        <div className="form__group field" style={{ justifyContent: "end", display: "flex" }}>
                          <button type="submit" className="btn btn-inverse-success btn-fw">
                            Guardar Cambios
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane fade" id="nav-profile2" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                  <div className="modal-body" style={{ display: "flex" }}>
                    <div className="col-6">
                      <form onSubmit={(e) => {EditUploadPri(e)}}>
                        <div className="form__group field" style={{ marginTop: "-3%" }}>
                          <div className="image-input imagespri">
                            <input type="file" accept="image/*" id="imageInputs" name="dir_img_pri" onChange={(e) => EditUploadPri(e.target.files[0])}/>
                            <label id='verimagen' for="imageInputs" className="image-buttonspri">
                              <i className="far fa-image"></i> 
                              Elegir imagen principal
                            </label>
                            <img src={linkServidor+'/'+ImagenPri} className="image-previewedit" id='ocultarimagen'/>
                            <span className="change-imagens">Elige otra imagen principal</span>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-6">
                      <form onSubmit={(e) => {EditUploadSec(e)}}>
                        <div className="form__group field" style={{ marginTop: "-3%" }}>
                          <div className="image-input imagestwo">
                            <input type="file" accept="image/*" id="imageInputss" name="dir_img_sec" onChange={(e) => EditUploadSec(e.target.files[0])}/>
                            <label id='verimagens' for="imageInputss" className="image-buttons-two">
                              <i className="far fa-image"></i> 
                              Elegir imagen secundaria
                            </label>
                            <img src={linkServidor+'/'+ImagenSec} className="image-preview-twoedit" id='ocultarimagens'/>
                            <span className="change-image-two">Elige otra imagen secundaria</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="asignacion-pro2" role="tabpanel" aria-labelledby="asignacion-tab" tabindex="0">
                  <div className="modal-body">
                    <div style={{ display: "flex" }}>
                      <div className="col-6">
                        <h6>Categorias</h6>
                        <div className="categoriaelegir" style={{ marginBottom: "6%" }}>
                          {CategoriasEditar.map(elemento =>{
                            return(
                              <div className="form__group">
                                {elemento.access == true &&
                                  <div>
                                    <input type="checkbox" id={`box-ou${elemento.id}`} value={elemento.id} checked onChange={(e) => { onCambioCategoriaEdit(e) }}/>
                                    <label className="checkbox-custom-label" for={`box-ou${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                                  </div>
                                }
                                {elemento.access != true &&
                                  <div>
                                    <input type="checkbox" id={`box-ou${elemento.id}`} value={elemento.id} onChange={(e) => { onCambioCategoriaEdit(e) }}/>
                                    <label className="checkbox-custom-label" for={`box-ou${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                                  </div>
                                }
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-6">
                        <h6>Subcategorias</h6>
                        <div className="categoriaelegir" style={{ marginBottom: "6%" }}>
                          {SubCategoriasEditar.map(elemento =>{
                            return(
                              <div className="form__group">
                                {elemento.access == true &&
                                  <div>
                                    <input type="checkbox" id={`box-0u${elemento.id}`} value={elemento.id} checked onChange={(e) => { onCambioSubCategoriaEdit(e) }}/>
                                    <label className="checkbox-custom-label" for={`box-0u${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                                  </div>
                                }
                                {elemento.access != true &&
                                  <div>
                                    <input type="checkbox" id={`box-0u${elemento.id}`} value={elemento.id} onChange={(e) => { onCambioSubCategoriaEdit(e) }}/>
                                    <label className="checkbox-custom-label" for={`box-0u${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                                  </div>
                                }
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <h6>Etiquetas</h6>
                      <div className="categoriaelegir">
                        {EtiquetasEditar.map(elemento =>{
                          return(
                            <div className="form__group">
                              {elemento.access == true &&
                                <div>
                                  <input type="checkbox" id={`box-00${elemento.id}`} value={elemento.id} checked onChange={(e) => { onCambioEtiquetaEdit(e) }}/>
                                  <label className="checkbox-custom-label" for={`box-00${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                                </div>
                              }
                              {elemento.access == false &&
                                <div>
                                  <input type="checkbox" id={`box-00${elemento.id}`} value={elemento.id} onChange={(e) => { onCambioEtiquetaEdit(e) }}/>
                                  <label className="checkbox-custom-label" for={`box-00${elemento.id}`} style={{ color: "black" }} >{elemento.descripcion}</label>
                                </div>
                              }
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="caracteristicas-pro2" role="tabpanel" aria-labelledby="caracteristicas-tab" tabindex="0">
                  <div className="modal-body" style={{ display: "flex" }}>
                    <div className="col-6">
                      <form onSubmit={handleSubmit}>
                        <div className="form__group field">
                          <input required="" placeholder="caracteristica" className="form__field" type="input" value={Caracteristica} onChange={(e) => { setCaracteristica(e.target.value) }}/>
                          <label className="form__label" for="caracteristica" style={{ marginTop: "2%" }}>Caracteristica</label>
                        </div>
                        <div className="form__group field">
                          <input required="" placeholder="descripcion-caracte" className="form__field" type="input" value={DescripcionCaracte} onChange={(e) => { setDescripcionCaracte(e.target.value) }}/>
                          <label className="form__label" for="descripcion-caracte" style={{ marginTop: "2%" }}>Descripcion</label>
                        </div>
                        {EstadoCaraste == true &&
                          <div className="form__group" style={{ paddingTop: "7%" }}>
                            <input type="checkbox" id="box-0001" name="estado-caracte" value={EstadoCaraste} checked onChange={(e) => { setEstadoCaraste(e.target.checked) }} />
                            <label className="checkbox-custom-label" for="box-0001">Estado</label>
                          </div>
                        }
                        {EstadoCaraste == false &&
                          <div className="form__group" style={{ paddingTop: "7%" }}>
                            <input type="checkbox" id="box-0001" name="estado-caracte" value={EstadoCaraste} onChange={(e) => { setEstadoCaraste(e.target.checked) }} />
                            <label className="checkbox-custom-label" for="box-0001">Estado</label>
                          </div>
                        }
                        <div className="form__group field" style={{ justifyContent: "end", display: "flex" }}>
                          <button type="submit" className="btn btn-inverse-success btn-fw">
                            Guardar Cambios
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-6" style={{ display: "flex", marginTop:"2%" }}>
                      {CaracteristicaProductos.length == 0 &&
                        <div style={{ width: "100%", fontSize: "20px", justifyContent: "center", alignItems: "center", display: "flex", fontWeight: "200" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ marginRight: "2%" }} className="bi bi-card-checklist" viewBox="0 0 16 16">
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                            </svg>
                          <span>Agregar caracteristicas</span>
                        </div>
                      }
                      {CaracteristicaProductos.length != 0 &&
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Caracteristica</th>
                              <th>Descripción</th>
                              <th>Estado</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CaracteristicaProductos.map((value, index) =>{
                              return (
                              <tr key={index}>
                                <th style={{ borderBottom:"transparent" }}>{value.caracteristica}</th>
                                <th style={{ borderBottom:"transparent" }}>{value.descripcion}</th>
                                <th style={{ borderBottom:"transparent" }}>{value.estado?'Activo':'Inactivo'}</th>
                                <th style={{ borderBottom:"transparent" }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" onClick={() => {clickeCaracteristicas(value.id)}} fill="currentColor" className="bi bi-pencil-square edit-admin" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style={{ marginLeft:"10%" }} onClick={() => {DeletCaracteProduct(value.id)}} fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                  </svg>
                                </th>
                              </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      }
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="galeria-pro2" role="tabpanel" aria-labelledby="galeria-tab" tabindex="0">
                  <div className="modal-body" style={{ display: "flex" }}>
                    <div className="col-6">
                      <form onSubmit={(e) => {UploadGaleriaEditar(e)}}>
                        <div className="form__group field">
                          <div className="image-input">
                            <input type="file" accept="image/*" multiple id="imageInputgaleria-edit" name="dir_img"  onChange={(e) => UploadGaleriaEditar(e.target.files[0])}/>
                            <label for="imageInputgaleria-edit" className="image-buttonns-galeri">
                              <i className="far fa-image"></i> 
                              Elegir imagen
                            </label>
                            <img src="" className="image-preview-galeria-edit" style={{ maxWidth: "250px" }}/>
                            <span id='btn-imagen' className="change-image-edit-galeria otraimagen">Elige otra imagen</span>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className='col-6'>
                      <div className="jumbotron" style={{ padding: "2rem 1rem" }}>
                        {GaleriaProductos.length == 0 &&
                          <div style={{ width: "100%", fontSize: "20px", justifyContent: "center", alignItems: "center", display: "flex", fontWeight: "200" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ marginRight: "2%" }} className="bi bi-images" viewBox="0 0 16 16">
                              <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                              <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>
                            </svg>
                            <span>Agregar galeria</span>
                          </div>
                        }
                        {GaleriaProductos.length != 0 &&
                          <div>
                            {GaleriaProductos.map((value) =>{
                              return (
                                <div className='col-6' style={{ display: "inline-table", marginBottom: "2%", padding: "1%" }}>
                                  <button type="button" className="btn btn-primary"  onClick={() => {DeleteGaleria(value.id)}} style={{ position: "absolute", marginLeft: "-2%", marginTop: "-2%", borderRadius: "50%", width: "50px", height: "50px", backgroundColor: "rgba(0,0,0, .5)", color: "#fff", border: "transparent"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                  </button>
                                  <img width='100%' src={linkServidor + '/' + value.dir_img}/>
                                </div>
                              )
                            })}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Productos;