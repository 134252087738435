import React, { useState, useEffect }  from 'react';
import { baseUrl, linkServidor } from '../../services/apirest';
import axios from 'axios';
import $ from 'jquery'; 
import Swal from 'sweetalert2';

function Banner() {
  const [Banner, setBanner] = useState([]);
  const [Link, setLink] = useState('');

  const getBanners = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/banner`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setBanner(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const upload = (e) => {
    const formData = new FormData();
    formData.append("dir_img",  e);
    formData.append("link",  Link);
    let token = localStorage.getItem("token");
    axios({ 
    method: 'post',
      url : `${baseUrl}/banner`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      })
      .then((response) => {
        $('input[type="text"]').val('');
        getBanners();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardado con éxito',
          showConfirmButton: false,
          timer: 1500
        });
        $("#btn-imagen").trigger("click");
      })
    .catch((err)=>{
      console.log(err);
    });
  };

  const componentDidMount = () => {
    $(document).ready(function() {
      $('#imageInput').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
            $('.image-preview').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-button').css('display', 'none');
          $('.image-preview').css('display', 'block');
          $('.change-image').css('display', 'block');
        }
      });
                    
      $('.change-image').on('click', function() {
        let $control = $(this);			
        $('#imageInput').val('');	
        let $preview = $('.image-preview');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-button').css('display', 'block');
      });
    
      $('#imagen').addClass('quitardiv');
    });
  }

  const DeleteBanner = (id) =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/banner/destroy/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      console.log(response);
      getBanners();
    })
    .catch((error) => {
    });
  }

  useEffect(()=>{
    componentDidMount();
    getBanners();
  }, []);

  return (
    <div style={{ display: "contents" }}>
      <div className="admin espacio-admin">
        <main className="page-content">
          <h2 className="titulo-admin">MODULO DE BANNERS</h2>
          <div className='container'>
            <div className="col-md-12 col-lg-4 mt-3" style={{ padding: "0px" }}>
              <label className='title-name-detalle'>Link</label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ color: "white" }} fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                  </svg>
                </span>
                <input type="text" className="form-control input-detalle" onChange={(e) => { setLink(e.target.value) }}/>
              </div>
            </div>
            <form onSubmit={(e) => {upload(e)}}>
              <div className="form__img field" style={{ paddingTop: "2%" }}>
                <div className="image-input" style={{ marginBottom: "4%" }}>
                  <input type="file" accept="image/*" multiple id="imageInput" name="dir_img" onChange={(e) => upload(e.target.files[0])}/>
                  <label for="imageInput" className="image-button">
                    <i className="far fa-image"></i> 
                    Elegir imagen
                  </label>
                  <img src="" className="image-preview" style={{ maxWidth: "250px" }}/>
                  <span id='btn-imagen' className="change-image">Elige otra imagen</span>
                </div>
              </div>
            </form>
          </div>
        </main>
        <div className='container'>
          <div className="jumbotron">
            {Banner.map((value) =>{
              return (
                <div className='col-6' style={{ display: "inline-table", marginBottom: "2%", padding: "1%" }}>
                  <button type="button" className="btn btn-primary" onClick={() => {DeleteBanner(value.id)}} style={{ position: "absolute", marginLeft: "-2%", marginTop: "-2%", borderRadius: "50%", width: "50px", height: "50px", backgroundColor: "rgba(0,0,0, .5)", color: "#fff", border: "transparent"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                  </button>
                  <img width='100%' src={linkServidor +'/' +value.dir_img}/>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner