import React, { useEffect, useState } from 'react';
import '../../assets/css/sidebar.css';
import { ecoUrl } from '../../services/apirest';
import axios from 'axios';

function Home(){
  const [conteoArray, setConteoIDs] = useState({});
  
  const [ConteoCategoria, setConteoCategoria] = useState({});

  const getVistas = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${ecoUrl}/Coti/getVistas`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setConteoIDs(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getVistasCategoria = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${ecoUrl}/Coti/getVistasCategoria`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setConteoCategoria(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  // Convertir el objeto en un array de arrays y ordenarlo por el valor (cantidad)
  const conteoArrayEntries = Object.entries(conteoArray).sort((a, b) => b[1] - a[1]);
  const ConteoCategoriaArrayEntries = Object.entries(ConteoCategoria).sort((a, b) => b[1] - a[1]);
  console.log(ConteoCategoriaArrayEntries);

  useEffect(()=>{
    getVistas();
    getVistasCategoria();
  }, []);

  return(
    <div className="admin espacio-admin">
      <main className="page-content">
        <h2>DASHBOARD</h2>
        <div style={{ display: "flex" }}>
          <div className="tabla-admin">
            <table id="example" className="table table-striped" style={{ width: "20%", marginRight: "5%" }}>
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>Productos</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ display: "none" }}> 
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                </tr>
                {conteoArrayEntries.map(([id, cantidad]) => (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="tabla-admin">
            <table id="example" className="table table-striped" style={{ width: "20%" }}>
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>Categorias</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ display: "none" }}> 
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                </tr>
                {ConteoCategoriaArrayEntries.map(([id, cantidad]) => (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;