import React from 'react';
import '../../assets/css/agregadosfooter.css';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';

function Devoluciones() {
  
  window.scrollTo(0, 0);

  return (
    <div>
      <Navbar/>
      <div className='container'>
        <h2 className="title-devoluciones col-12">Políticas de Cambios y/o Devoluciones</h2>
        <ul className="politicas-cambios-devoluciones">
          <li className="concepto-terminos">
            ●	Compuplaza Computadoras E.I.R.L. solo acepta devoluciones dentro de las 72 horas posterior a la recepción del producto, previa evaluación y
            diagnóstico de Servicio Técnico.
          </li>
          <li className="concepto-terminos">
            ●	La solicitud se deberá hacer de manera presencial, se verificará el estado del producto que deberá estar completamente sin uso y 
            en óptimas condiciones, incluyendo manuales y empaque original. Los equipos deberán traer sus cajas, tecnopor, accesorios, en perfectas 
            condiciones como los adquirió, sin ningún daño físico ocasionado por el cliente, en caso de las impresoras NO deberán estar instaladas las 
            tintas, toners o cintas; en caso de los suministros, NO deberán ser abiertos de su empaque original, si no cumplen con estas condiciones, 
            no se acepta la devolución.
          </li>
          <li className="concepto-terminos">
            ●	Se le recomienda al cliente que, al recibir su pedido, verifique que el producto se encuentra en buenas condiciones y con los accesorios 
            listados. 
          </li>
          <li className="concepto-termino">
            ●	Compuplaza Computadoras E.I.R.L. no se responsabiliza por el software u otros datos almacenados en discos duros internos y externos, 
            USB y otros dispositivos de almacenamiento. Se recomienda hacer Backups o copias de seguridad previas a la entrega a nuestro centro de 
            servicio.
          </li>
        </ul>
        <h5>Términos y Condiciones de la Garantía</h5>
        <p className="concepto-terminos">
          Agradecemos su confianza al adquirir este producto en Compuplaza Computadoras E.I.R.L. y esperamos que esté satisfecho con su compra. 
          En caso su compra presente inconvenientes o fallas, tenga en cuenta lo siguiente:
        </p>
        <ul className="politicas-cambios-devoluciones">
          <li className="concepto-terminos">
            ●	La garantía que brinda Compuplaza Computadoras E.I.R.L cubre sólo los desperfectos originados por  fallas o defectos de fabricación y 
            se aplica solo al cliente de Compuplaza Computadoras E.I.R.L. y no se extiende a terceros, solo se presta en nuestras instalaciones de 
            servicio técnico. Cada producto tiene Garantía, la cual varía de acuerdo con la marca y el fabricante, según información impresa en los 
            documentos de venta que acompañan al producto y/o en los presentes términos y condiciones.
          </li>
          <li className="concepto-terminos">
            ●	La garantía de los equipos de la marca: HP, Lenovo, Asus, Acer, HP, Epson, Canon, Brother, Samsung, LG, Teros, lo cubre el mismo 
            fabricante a través de los CENTROS AUTORIZADOS DE SERVICIO bajo sus propios términos de garantía (Para más información sírvase a 
            ingresar a la página web del fabricante y ponerse en contacto con su área encargada), Compuplaza Computadoras E.I.R.L no tiene 
            responsabilidad ni injerencia alguna en la garantía de estos equipos.
          </li>
          <li className="concepto-terminos">
            ●	La garantía de los equipos o productos que no cuenten con un CENTRO AUTORIZADO DE SERVICIO será cubierto por 
            Compuplaza Computadoras E.I.R.L, para tal fin los productos que estén dentro del periodo de garantía, deben ser entregados con su 
            respectiva fotocopia de la Boleta o Factura de venta emitido Compuplaza Computadoras E.I.R.L.
          </li>
          ●	El Periodo de garantía son:
          <ul className="politicas-cambios">
            <li className="concepto-terminos">
              -	Para los productos que cubre la garantía Compuplaza Computadoras EIRL (mano de obra, repuestos o cambio de producto) será de 01 año 
              (12 meses) a partir de la fecha de compra.
            </li>
            <li className="concepto-terminos">
              -	Para los productos que cubre la garantía el fabricante a través de sus CENTRO AUTORIZADO DE SERVICIO será hasta 1 año 
              (12 meses) adicional, dependiendo el producto adquirido, a partir del término de la garantía brindada por el fabricante y 
              cubrirá solo el costo de mano de obra.
            </li>
          </ul>  
          ●	La garantía tiene las siguientes excepciones y será rechazada cuando:
          <ul className="politicas-cambios">
            <li className="concepto-terminos">
              -	El producto presente etiquetas del Fabricante o de Compuplaza Computadoras adulteradas o removida.
            </li>
            <li className="concepto-terminos">
              -	El producto muestre evidencia de intento de manipulación, reparación o modificación.
            </li>
            <li className="concepto-terminos">
              -	El producto presente daño físico alguno o señales de uso inapropiado. 
            </li>
            <li className="concepto-terminos">
              -	El producto presente daños causados por fallas eléctricas (integrados quemados o derretidos), sobrecargas, mala instalación, 
              falta de mantenimiento o la presencia de cualquier elemento extraño (ácidos, líquidos, químicos e insectos) así como la corrosión 
              y/o óxido. 
            </li>
          </ul>   
          ●	La garantía no cubre:
          <ul className="politicas-cambios">
            <li className="concepto-terminos">
              -	Accesorios, conectores, puertos de entrada y salida (paralelo, serial, USB, HDMI etc.), o Cabezales de Impresoras. 
              y así mismo no cubre consumibles o componentes, que se desgasten por el uso del mismo (tintas, cintas, toners, etc.)
            </li>
            <li className="concepto-terminos">
              -	Ningún tipo de software instalado en los equipos como computadoras, laptops, tablets, discos duros, usb, etc.
            </li>
            <li className="concepto-terminos">
              -	Daños producidos por transporte o mala manipulación de terceros.
            </li>
          </ul>    
          <li className="concepto-terminos">
            ●	El proceso de garantía será en un plazo mínimo de 72 horas y un máximo de 30 días calendarios días según la disponibilidad de stock de las 
            partes y piezas, así como la respuesta del fabricante. En caso se necesite exceder el tiempo máximo de atención, Compuplaza Computadoras 
            enviará una comunicación al cliente, sin previa consulta, detallando el motivo del retraso y el plazo adicional para la atención definitiva.
          </li>
          <li className="bottom concepto-terminos">
            ●	La garantía es aplicable para cambio de producto o remanufacturación (sujeto a validación), en caso no se contase con stock se otorgará 
            un cambio alterno del mismo valor o similar, o se asignará una nota de crédito.
          </li>
        </ul>
      </div>
      <Footer/>
    </div>
  )
}

export default Devoluciones