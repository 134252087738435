import React, { useState, useEffect } from 'react';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import axios from 'axios';
import { ecoUrl } from '../../services/apirest';
import Swal from 'sweetalert2';
import chat from '../../assets/images/corporativo/chat.svg';
import telefono from '../../assets/images/corporativo/telefono.svg';
import tiendacontacto from '../../assets/images/corporativo/tienda-contacto.svg';

function Contacto() {

  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [Telefono, setTelefono] = useState();
  const [Mensaje, setMensaje] = useState();

  const getScroll = () => {
    window.scrollTo(0, 0);
  }

  const addContac = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url : `${ecoUrl}/Contacto/Contac`, 
      data: JSON.stringify({
        name : Name,
        email : Email,
        telefono : Telefono,
        mensaje : Mensaje,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((json)=>{
      //console.log(json)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Enviado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      e.target.reset();
      getScroll();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  useEffect(()=>{
    getScroll();
  }, []);

  return (
    <div>
      <Navbar/>
      <div className='container'>
        <div className="row contacto-icon-texto" style={{ justifyContent: "center", marginBottom: "3%" }}>
          <div className="col-md-12 col-lg-4 contacto">
            <img src={chat} />
            <h3 className="title-contac">Chatea con nosotros</h3>
            <p>Te atendemos las 24 horas</p>
          </div>
          <div className="col-md-12 col-lg-4 contacto">
            <img src={telefono} />
            <h3 className="title-contac">Llámanos al</h3>
            <p>(044) 243081</p>
          </div>
          <div className="col-md-12 col-lg-4 contacto">
            <img src={tiendacontacto} />
            <h3 className="title-contac">Horario de atención</h3>
            <p>De lunes a domingo de 08:00 am a 6:00 pm</p>
          </div>
        </div>

        <div className="row pb-5">
          <div className="col-md-12 col-lg-6">
            <h3 className="title-contac title-contacto">Escríbenos</h3>
            <h1 className="title-contact-form" style={{ fontWeight: "100" }}>
              Nuestro equipo de soporte estará gustoso de brindarte asistencia en lo que necesites.
            </h1>
            <form onSubmit={(e) => {addContac(e)}}>
              <div style={{ padding: "0% 7%" }}>
                <div className="input-bottom">
                  <label className="label-coti">Nombres:</label>
                  <input className="form-control input-contac" type="text" placeholder='Nombres' required onChange={(e) => { setName(e.target.value) }}></input>
                </div>
                <div className="input-bottom">
                  <label className="label-coti">Correo Electrónico:</label>
                  <input className="form-control input-contac" type="email" placeholder='Correo Electrónico' required onChange={(e) => { setEmail(e.target.value) }}></input>
                </div>
                <div className="input-bottom">
                  <label className="label-coti">Teléfono:</label>
                  <input className="form-control input-contac" type="text" placeholder='Teléfono' required onChange={(e) => { setTelefono(e.target.value) }}></input>
                </div>
                <div>
                  <label className="label-coti">Mensaje:</label>
                  <textarea className="form-control input-contac" rows="3" style={{ color: "black" }} placeholder='Mensaje' required onChange={(e) => { setMensaje(e.target.value) }}></textarea>
                </div>
              </div>
              <center>
                <button type="submit" className="btn-subir-enviar mt-3">Enviar</button>
              </center>
            </form>
          </div>
          <div className="col-md-12 col-lg-6" style={{ margin: "auto" }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.908242929785!2d-79.02630468461476!3d-8.110825183398159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ad3d814dcec311%3A0x4b5efb2e3cd8e7d3!2sCOMPUPLAZA!5e0!3m2!1ses!2spe!4v1618926059193!5m2!1ses!2spe" width="100%" height="450" style={{ border: "0px" }} allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Contacto