import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { linkServidor } from '../../services/apirest';
import bot from '../../assets/images/Bot/CABEZA.webp';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';

function Carrito() {

  const [GetallCart, setGetallCart] = useState([]);
  const [loader, setLoader] = useState(false);
  const [Total, setTotal] = useState('');
  const [Ahorrado, setAhorrado] = useState('');
  window.scrollTo(0, 0);

  let item = JSON.parse(sessionStorage.getItem('carrito'))?JSON.parse(sessionStorage.getItem('carrito')):[];
  let cantidad = JSON.parse(sessionStorage.getItem('cantidad'))?JSON.parse(sessionStorage.getItem('cantidad')):0;
  let total = JSON.parse(sessionStorage.getItem('total'))?JSON.parse(sessionStorage.getItem('total')):0;

  const getcart = () =>{
    if(item.length == 0){
      setLoader(true)
      console.log('no hay ningun producto en tu carrito');
    }else{
      //console.log(item);
      setGetallCart(item);
      setLoader(false)
    }

    let precio = 0;
    let precioweb = 0;
    let precioprom = 0;
    let preciowebtotal = 0;

    item.map((value)=>{
      if (value.precio_activo == 1) {
        precio += value.precio * value.cantidad_pd;
      } else if(value.precio_activo == 2) {
        precioweb += value.precio_web * value.cantidad_pd;
      }else{
        precioprom += value.precio_prom * value.cantidad_pd;
      }
      
      preciowebtotal += value.precio * value.cantidad_pd;
      //console.log(preciowebtotal);
      //console.log(precio, precioweb, precioprom);
    });

    //console.log(precio+precioweb+precioprom);
    setTotal((precio+precioweb+precioprom).toFixed(2));
    setAhorrado((preciowebtotal).toFixed(2));
    total=(precio+precioweb+precioprom).toFixed(2);
    sessionStorage.setItem('total', JSON.stringify(total));
  }

  const AdicionarCantidad = (producto) =>{
    let IDencontrado = false;

    item.forEach((elemento)=>{
      if(elemento.id == producto.id){
        IDencontrado = true;
        if(elemento.cantidad_pd < (producto.stock + producto.stock_lima)) {
          cantidad++;
          elemento.cantidad_pd++;
          getcart();
        } else {
          console.log('ya no hay mas stock');
        }
      }
    });

    sessionStorage.setItem('carrito', JSON.stringify(item));
    sessionStorage.setItem('cantidad', JSON.stringify(cantidad));
    sessionStorage.setItem('total', JSON.stringify(total));
  }

  const RestarCarrito = (producto) => {
    let IDencontrado = false;
    cantidad--;
    item.forEach((elemento)=>{
      if(elemento.id == producto.id){
        IDencontrado = true;
        elemento.cantidad_pd--;
        getcart();
      }

      if(elemento.cantidad_pd == 0){
        this.item = this.item.filter((item) => item.id !== producto.id);
        getcart();
      }
    });
    sessionStorage.setItem("carrito", JSON.stringify(item));
    sessionStorage.setItem('cantidad', JSON.stringify(cantidad));
    sessionStorage.setItem('total', JSON.stringify(total));
  }

  const LimpiarCarrito = (producto) => {
    item.map((elemento)=>{
      if(elemento.id == producto.id){
        item = item.filter((item) => item.id !== producto.id);
        cantidad = cantidad - elemento.cantidad_pd;
        getcart();
      }
    });
    sessionStorage.setItem('carrito', JSON.stringify(item));
    sessionStorage.setItem('cantidad', JSON.stringify(cantidad));
    sessionStorage.setItem('total', JSON.stringify(total));
  }

  useEffect(()=>{
    getcart();
  }, []);

  return (
    <div>
      <Navbar/>
      <div className='container'>
        <div className='conten-cart'>
          <h2 className='car-title poppins-bold'>CARRITO</h2>
        </div>
        {loader != false &&
          <span className='dinproRegular' style={{ fontSize: '19px', color: '#414141', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            NO HAY PRODUCTO <img style={{ marginLeft: '2%', width: '7%' }} src={bot} />
          </span>
        }
        {loader == false &&
          <div className="table-responsive">
            <table className="table" style={{ marginBottom:"0px" }}>
              <thead>
                <tr>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Imagen</th>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Descripción</th>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Precio Normal</th>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Precio Final</th>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Cantidad</th>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Total</th>
                </tr>
              </thead>
            </table>
            <table class = "gfg">
              
            <tbody>
              {GetallCart.map((value, index) =>{
                return (
                  <tr key={index}>
                    <td className='estilos-carrito' style={{ borderLeft: "1px solid #fe6313", borderTopLeftRadius: "14px", borderBottomLeftRadius: "14px" }}>
                      <img src={linkServidor + '/' + value.dir_img_pri} alt="logo" style={{ width: "50%" }} />
                    </td>
                    <td className='estilos-carrito'>{value.description}</td>
                    <td className='estilos-carrito' style={{ textDecoration: "line-through" }}>S/ {value.precio}</td>
                    <td className='estilos-carrito'><span style={{ background: "#001683", padding: "7%", borderRadius: "10px", color: "white", fontSize: "20px" }}>S/ {value.precio_venta_dp}</span></td>
                    <td className='estilos-carrito' style={{ color: "black" }}>
                      {value.cantidad_pd == 1 &&
                        <button className='button-delete' onClick={()=>LimpiarCarrito(value)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" style={{ color: "white", marginTop: "-26%" }} height="18" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                          </svg>
                        </button>
                      }
                      {value.cantidad_pd > 1 &&
                        <button className='button-delete' onClick={()=>RestarCarrito(value)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" style={{ color: "white", marginTop: "-26%" }} height="20" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                          </svg>
                        </button>
                      }
                      {value.cantidad_pd}
                      {value.cantidad_pd != (value.stock + value.stock_lima) &&
                        <button className='button-plus' onClick={()=>AdicionarCantidad(value)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" style={{ color: "white", marginTop: "-26%" }} height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                          </svg>
                        </button>
                      }
                      {value.cantidad_pd == (value.stock + value.stock_lima) &&
                        <button className='button-plus' style={{ opacity: "0.5", cursor: "not-allowed" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" style={{ color: "white", marginTop: "-26%" }} height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                          </svg>
                        </button>
                      }
                    </td>
                    <td className='estilos-carrito' style={{ borderRight: "1px solid #fe6313", borderTopRightRadius: "14px", borderBottomRightRadius: "14px", color: "black" }}>S/ {(value.cantidad_pd * value.precio_venta_dp).toFixed(2)}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        }
        <center>
          <div className='resumen-cart'>
            <h2 className='car-title poppins-bold'>RESUMEN</h2> 
          </div>
          <div className='row' style={{ display: "block" }}>
            <div className='col-4 carrito-precios'>
              <span style={{ fontSize: "19px", color: "#414141" }}>Subtotal</span>
              <span style={{ fontSize: "19px", color: "#414141" }}>S/ {Total}</span>
            </div>
            <div className='col-4 carrito-precios-doble'>
              <span style={{ fontSize: "19px", color: "#414141" }}>Total</span>
              <span style={{ fontSize: "19px", color: "#414141" }}>S/ {Total}</span>
            </div>
            <div className='col-4 carrito-precios-doble' style={{ background: "#EDF1F4", padding: "0.8%", borderRadius: "10px" }}>
              <span style={{ fontSize: "25px", color: "#ed571d", fontWeight: "bold" }}>Ahorraste</span>
              <span style={{ fontSize: "25px", color: "#ed571d", fontWeight: "bold" }}>S/ {(Ahorrado - Total).toFixed(2)}</span>
            </div>
            <div className='col-6 btn-carrito-acciones'>
              {loader == false &&
                <Link to={`/venta`} className='col-lg-12'>
                  <button type="button" className="btn btn-resumen">Continuar</button>
                </Link>
              }
              <Link to={`/`} className='comprar-resumen col-lg-12' style={{ color: "#414141" }}>
                Seguir comprando
              </Link>
            </div>
          </div>
        </center>
      </div>
      <Footer/>
    </div>
  )
}

export default Carrito