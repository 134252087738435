import React, { useState, useEffect } from 'react';
import { baseUrl, linkServidor } from '../../services/apirest';
import axios from 'axios';
import { DataGrid } from '@material-ui/data-grid';

function Blanco(){
  const [Productos, setProductos] = useState([]);

  const getProducto = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/producto`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data.data)
      setProductos(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const columns = [
    { 
      field: 'id', 
      headerName: 'ID',
      width: 92, 
    },
    {
      field: 'description',
      headerName: 'Nombre',
      width: 250,
      editable: true,
    },
    {
      field: 'stock',
      headerName: 'Stock Trujillo',
      // type: 'number',
      width: 160,
      editable: true,
    },{
      field: 'stock_lima',
      headerName: 'Stock Lima',
      width: 160,
      editable: true,
    },
    {
      field: 'precio_activo',
      headerName: 'Precio Activo',
      width: 160,
      editable: true,
    },
    {
      field: 'precio',
      headerName: 'Precio',
      width: 120,
      editable: true,
    },
    {
      field: 'precio_web',
      headerName: 'Precio Web',
      width: 150,
      editable: true,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      width: 130,
      editable: true,
    },
    {
      field: 'Acciones',
      headerName: 'Acciones',
      sortable: false,
      width: 110,
      renderCell: (params) => (
        <div style={{ justifyContent: "space-evenly", display: "flex", alignItems: "center", width:"100%" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" onClick={() => handleEdit(params.id)} data-bs-toggle="modal" data-bs-target="#exampleModale" fill="currentColor" className="bi bi-pencil-square edit-admin" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
          </svg>
        </div>
      ),
    },
  ];

  const handleEdit = (id) => {
    // Aquí puedes implementar la lógica para manejar la edición del elemento con el ID proporcionado
    console.log(`Editar elemento con ID ${id}`);
  };

  useEffect(()=>{
    getProducto();
  }, []);

  return(
    <div style={{ height: 700, width: "100%" }}>
      <DataGrid
        rows={Productos}
        columns={columns}
        pageSize={11}
      />
    </div>
  );
}

export default Blanco;