import React, { useState, useEffect } from 'react';
import { ecoUrl, linkServidor } from '../../services/apirest';
import axios from 'axios';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import producto1 from '../../assets/images/productos/auto.webp';

function Pedidos() {
  window.scrollTo(0, 0);
  const [Pedidos, setPedidos] = useState([]);

  const getPedidos = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${ecoUrl}/WebUserOrder/getPedidoByUsuarioMail`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setPedidos(response.data.data);
      console.log(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  useEffect(()=>{
    getPedidos();
  }, []);

  return (
    <div>
      <Navbar/>
      <h2 style={{ fontSize: "40px", textAlign: "center", marginTop:"3%", color: "#FE6313", fontWeight: "bolder" }}>MIS PEDIDOS</h2>
      <div className='row' style={{ padding: "0px", margin: "0px" }}>
        <div className='col-6' style={{ padding: "3% 0% 2% 4%" }}>
          <table className="table" style={{ marginBottom:"0px" }}>
            <thead>
              <tr>
                <th scope="col" className='titulo-carrito' style={{ border: "transparent" }}>ID</th>
                <th scope="col" className='titulo-carrito' style={{ border: "transparent" }}>Imagen</th>
                <th scope="col" className='titulo-carrito' style={{ border: "transparent" }}>Nombre</th>
                <th scope="col" className='titulo-carrito' style={{ border: "transparent" }}>Fecha</th>
                <th scope="col" className='titulo-carrito' style={{ border: "transparent" }}>Total</th>
              </tr>
            </thead>
          </table>
          {/* <p style={{ textAlign: "center", margin: "5% 0%" }}>Nada encontrado</p> */}
          <div>

          {Pedidos.map((value, index) =>{
            return (
              <table class = "gfg" style={{ display: "flex", alignItems: "center" }}>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                </div>
                <tr style={{ width: "100%", display: "inline-table" }}>
                  <td className='estilos-carrito' style={{ borderLeft: "1px solid #fe6313", borderTopLeftRadius: "14px", borderBottomLeftRadius: "14px", color: "black" }}>
                    {value.pedidoDetalle.map((value, index) =>{
                      return (
                        <span>{value.pedido_id}</span>
                      )
                    })}
                  </td>
                  <td className='estilos-carrito'>
                    {value.pedidoDetalle.map((value, index) =>{
                      return (
                        <img src={linkServidor + '/' + value.dir_img_pri} alt="logo" style={{ width: "90%" }} />
                      )
                    })}
                  </td>
                  <td className='estilos-carrito' style={{ color: "black", fontSize: "15px" }}>
                    {value.pedidoDetalle.map((value, index) =>{
                      return (
                        <span>{value.descripcion}</span>
                      )
                    })}
                  </td>
                  <td className='estilos-carrito' style={{ color: "black" }}>
                    {value.pedidoDetalle.map((value, index) =>{
                      return (
                        <span>{Date(value.created_at)}</span>
                      )
                    })}
                  </td>
                  <td className='estilos-carrito' style={{ borderRight: "1px solid #fe6313", borderTopRightRadius: "14px", borderBottomRightRadius: "14px", color: "black" }}>
                    {value.pedidoDetalle.map((value, index) =>{
                      return (
                        <span style={{ background: "#001683", padding: "7%", borderRadius: "10px", color: "white", fontSize: "20px" }}>S/ {value.subtotal}</span>
                      )
                    })}
                  </td>
                </tr>
              </table>
            )
          })}
          </div>
        </div>
        <div className='col-6' style={{ padding: "5.8% 3% 2%" }}>
          <div style={{ border: "1px solid red", borderRadius: "15px", padding: "4% 5%" }}>
            {/* <p style={{ textAlign: "center", margin: "5% 0%" }}>Nada elegido</p> */}
            <h3 style={{ fontSize: "30px", fontWeight: "bolder", marginBottom: "2%", color: "#0056b9" }}>Detalles de tu pedido</h3>
            <div style={{ display: "flex" }}>
              <div className='col-5' style={{ padding: "0px" }}>
                <img src={producto1} alt="logo" style={{ width: "85%" }} />
              </div>
              <div className='col-6' style={{ paddingTop: "3%" }}>
                <strong style={{ fontSize: "20px", lineHeight: "1" }}>DISCO DURO EXTERNO 2TB</strong>
                <p style={{ fontSize: "20px", marginTop: "3%" }}>Isabel de bobadilla 262 trujillo - la libertad</p>
                <p style={{ fontSize: "20px" }}><strong>Pago: </strong>Tarjeta de crédito</p>
                <p style={{ fontSize: "20px" }}><strong>Cupón: </strong>No</p>
                <p style={{ fontSize: "20px" }}><strong>Total: </strong>S/ 300</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Pedidos