// export const baseUrl = 'http://127.0.0.1:8000/api/dash_v1';
// export const ecoUrl = 'http://127.0.0.1:8000/api/tienda_v1';

// export const linkServidor = 'http://127.0.0.1:8000';

// export const linkCuentas = 'http://localhost:3000';

export const baseUrl = 'https://apicompuplaza.compuplaza.net.pe/api/dash_v1';
export const ecoUrl = 'https://apicompuplaza.compuplaza.net.pe/api/tienda_v1';

export const linkServidor = 'https://apicompuplaza.compuplaza.net.pe';

export const linkCuentas = 'https://compuplaza.net.pe';