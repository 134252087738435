import React, { useState, useEffect } from 'react';
import { baseUrl, linkServidor} from '../../services/apirest';
import axios from 'axios';
import '../../assets/css/sidebar.css';
import '../../assets/css/admin.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 
import Swal from 'sweetalert2';

function Subcategoria(){
  const [post, setPost] = useState([]);
  const [IDEditar, setIDEditar] = useState();
  const [Categoria, setCategoria] = useState([]);
  const [CategoriaId, setCategoriaId] = useState([]);
  const [descripcion, setDescripcion] = useState('');
  const [isEstado, setEstado] = useState(false);
  const [Imagen, setImagen] = useState(null);
  const [id, setId] = useState('');

  const getSubcategoria = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/subcategoria`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setPost(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    let urll = baseUrl + "/categoria";
    axios.get(urll, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }).then(response=>{
      setCategoria(response.data.data);
    });
  }

  const addSubCategoria = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    fetch(`${baseUrl}/subcategoria`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        categoria_id : CategoriaId,
        descripcion : descripcion,
        estado : isEstado,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      setId(json.data.id);
      $('#imagen').addClass('agregardiv');
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getSubcategoria();
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const upload = (e) => {
    const formData = new FormData();
    formData.append("dir_img",  e);
    let token = localStorage.getItem("token");
    axios({ 
      method: 'post',
      url : `${baseUrl}/subcategoria/updateImg/${id}`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getSubcategoria();
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const clickSubCategoria = (edit) =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/subcategoria/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setIDEditar(response.data.data.id);
      setCategoriaId(response.data.data.categoria_id);
      setDescripcion(response.data.data.descripcion);
      if(response.data.data.estado == 1){
        setEstado(true);
      }else{
        setEstado(false);
      }
      setImagen((response.data.data.dir_img))
      $('#verimagen').css('display', 'none');
      $('#ocultarimagen').css('display', 'block');
      $('.image-input span').css('display', 'block');
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const editSubCategoria = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    fetch(`${baseUrl}/subcategoria/${IDEditar}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        categoria_id : CategoriaId,
        descripcion : descripcion,
        estado : isEstado,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      setId(json.data.id);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Editado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getSubcategoria();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const uploadedit = (e) => {
    const formDatas = new FormData();
    formDatas.append("dir_img",  e);
    let token = localStorage.getItem("token");
    axios({ 
    method: 'post',
      url : `${baseUrl}/subcategoria/updateImg/${IDEditar}`, 
      data: formDatas,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      })
      .then((response) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Editado con éxito',
          showConfirmButton: false,
          timer: 1500
        });
        getSubcategoria();
      })
    .catch((err)=>{
      console.log(err);
    });
  };

  const componentDidMount = () => {
    $(document).ready(function() {
      $('#imageInput').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
            $('.image-preview').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-button').css('display', 'none');
          $('.image-preview').css('display', 'block');
          $('.change-image').css('display', 'block');
        }
      });
                    
      $('.change-image').on('click', function() {
        let $control = $(this);			
        $('#imageInput').val('');	
        let $preview = $('.image-preview');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-button').css('display', 'block');
      });

      $('#imageInputs').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
            $('.image-preview').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-button').css('display', 'none');
          $('.image-preview').css('display', 'block');
          $('.change-image').css('display', 'block');
        }
      });
      $('.change-image').on('click', function() {
        let $control = $(this);			
        $('#imageInputs').val('');	
        let $preview = $('.image-preview');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-button').css('display', 'block');
      });
      
      $(document).ready(function () {
        $('#example').DataTable();
      });
    
      $('#imagen').addClass('quitardiv');
    });
  }

  useEffect(()=>{
    getSubcategoria();
    componentDidMount();
  }, []);

  return(
    <div className="admin espacio-admin">
      <main className="page-content">
        <h2 className="titulo-admin">MODULO DE SUBCATEGORIAS</h2>
        <div className="col-12 button-categoria">
          <button type="button" className="btn btn-inverse-success btn-fw" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Agregar Subcategoria
          </button>
        </div>
        <div className="tabla-admin">
          <table id="example" className="display">
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ID</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>CATEGORIA</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>NOMBRE</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ESTADO</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ display: "none" }}> 
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
              </tr>
              {post.map((value, index) =>{
                return (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{value.id}</td>
                    <td style={{ textAlign: "center" }}>{value.categoria_id}</td>
                    <td style={{ textAlign: "center" }}>{value.descripcion}</td>
                    <td style={{ textAlign: "center" }}>{value.estado}</td>
                    <td style={{ textAlign: "center" }} onClick={() => {clickSubCategoria(value.id)}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" data-bs-toggle="modal" data-bs-target="#exampleModale" height="21" fill="currentColor" className="bi bi-pencil-square edit-admin" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </main>

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Agregar Subcategoria</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={(e) => {addSubCategoria(e)}}>
              <div className="modal-body">
                <div className="form__group">
                  <label className="form__label" for="descripcion">Categoria</label>
                  <select className="form-select" aria-label="Default select example" id="categoria_id" name="categoria_id" onChange={(e) => { setCategoriaId(e.target.value) }}>
                    <option value={"0"}>Seleccionar</option>
                    {Categoria.map((elemento, index)  => (
                      <option key={index} value={elemento.id}>{elemento.descripcion}</option>
                    ))}
                  </select>
                </div>
                <div className="form__group field">
                  <input required="" placeholder="descripcion" className="form__field" type="input" id="descripcion" name="descripcion" onChange={(e) => { setDescripcion(e.target.value) }}/>
                  <label className="form__label" for="descripcion">Nombre</label>
                </div>
                <div className="form__group" style={{ paddingTop: "2%" }}>
                  <input type="checkbox" id="box-1" name="estado" value={isEstado} onChange={(e) => { setEstado(e.target.checked) }}/>
                  <label className="checkbox-custom-label" for="box-1">Estado</label>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-inverse-success btn-fw">
                  Guardar Cambios
                </button>
              </div>
            </form>
            <form id='imagen' onSubmit={(e) => {upload(e)}}>
              <div className="form__group field" style={{ paddingTop: "2%" }}>
                <div className="image-input" style={{ marginBottom: "4%" }}>
                  <input type="file" accept="image/*" id="imageInput" name="dir_img" onChange={(e) => upload(e.target.files[0])}/>
                  <label for="imageInput" className="image-button">
                    <i className="far fa-image"></i> 
                    Elegir imagen
                  </label>
                  <img src="" className="image-preview"/>
                  <span className="change-image">Elige otra imagen</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModale" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Editar Subcategoria</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={(e) => {editSubCategoria(e)}}>
              <div className="modal-body">
                <div className="form__group">
                  <label className="form__label" for="descripcion">Categoria</label>
                  <select className="form-select" aria-label="Default select example" value={CategoriaId} id="categoria_id" name="categoria_id" onChange={(e) => { setCategoriaId(e.target.value) }}>
                    <option value={"0"}>Seleccionar</option>
                    {Categoria.map((elemento, index)  => (
                      <option key={index} value={elemento.id}>{elemento.descripcion}</option>
                    ))}
                  </select>
                </div>
                <div className="form__group field">
                  <input required="" placeholder="descripcion" className="form__field" value={descripcion} type="input" id="descripcion" name="descripcion" onChange={(e) => { setDescripcion(e.target.value) }}/>
                  <label className="form__label" for="descripcion">Nombre</label>
                </div>
                {isEstado == true &&
                  <div className="form__group" style={{ paddingTop: "2%" }}>
                    <input type="checkbox" id="box-1" name="estado" value={isEstado} checked onChange={(e) => { setEstado(e.target.checked) }}/>
                    <label className="checkbox-custom-label" for="box-1">Estado</label>
                  </div>
                }
                {isEstado == false &&
                  <div className="form__group" style={{ paddingTop: "2%" }}>
                    <input type="checkbox" id="box-1" name="estado" value={isEstado} onChange={(e) => { setEstado(e.target.checked) }} />
                    <label className="checkbox-custom-label" for="box-1">Estado</label>
                  </div>
                }
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-inverse-success btn-fw">
                  Guardar Cambios
                </button>
              </div>
            </form>
            <form onSubmit={(event) => {uploadedit(event)}}>
              <div className="form__group field" style={{ paddingTop: "2%" }}>
                <div className="image-input" style={{ marginBottom: "4%" }}>
                  <input type="file" accept="image/*" id="imageInputs" name="dir_img" onChange={(event) => uploadedit(event.target.files[0])}/>
                  <label id='verimagen' for="imageInputs" className="image-button">
                    <i className="far fa-image"></i> 
                    Elegir imagen
                  </label>
                  <img src={linkServidor + Imagen} className="image-preview" id='ocultarimagen'/>
                  <span className="change-image">Elige otra imagen</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subcategoria;