import React, { useState, useEffect } from 'react';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import coti from '../../assets/images/coti.webp';
import boton1 from '../../assets/images/boton1.webp';
import boton2 from '../../assets/images/boton2.webp';
import axios from 'axios';
import { ecoUrl } from '../../services/apirest';
import Swal from 'sweetalert2';
import $ from 'jquery';

function Cotizador() {
  const [PC, setPC] = useState();
  const [TipoUso, setTipoUso] = useState();
  const [Estilo, setEstilo] = useState();
  const [PerifericosIncluidos, setPerifericosIncluidos] = useState();
  const [TipoPeriferico, setTipoPeriferico] = useState();
  const [CantidadRAM, setCantidadRAM] = useState();
  const [TipoGrafico, setTipoGrafico] = useState();
  const [TipoProcesador, setTipoProcesador] = useState();
  const [TipodeProcesador, setTipodeProcesador] = useState();
  const [PresupuestoEstimado, setPresupuestoEstimado] = useState();
  const [Telefono, setTelefono] = useState();
  const [Correo, setCorreo] = useState('');

  if(Correo != ''){
    $(".btn-fw").removeClass("disabled");
    $(".btn-fw").addClass("active");
  }else{
    $(".btn-fw").removeClass("active");
    $(".btn-fw").addClass("disabled");
  }

  const getScroll = () => {
    window.scrollTo(0, 0);
  }

  const addCotizacion = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url : `${ecoUrl}/Coti/StoreCoti`, 
      data: JSON.stringify({
        busca : PC,
        tipouso : TipoUso,
        estilo : Estilo,
        perifericosincluidos : PerifericosIncluidos,
        tipoperiferico : TipoPeriferico,
        ram : CantidadRAM,
        graficos : TipoGrafico,
        procesador : TipoProcesador,
        tipoprocesador : TipodeProcesador,
        presupuesto : PresupuestoEstimado,
        telefono : Telefono,
        email : Correo,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((json)=>{
      console.log(json)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Enviado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      e.target.reset();
      getScroll();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const componentDidMount = () => {

    // VALIDACION DE IMAGENES
      $(".margen").click(function() {
        $(".margen").removeClass("seleccionado");
        $(this).addClass("seleccionado");
        $(".todo").removeClass("oculto");
        $(".todo").addClass("activo");
      });

      $(".margenlaptop").click(function() {
        $(".prueba").removeClass("activo");
        $(".prueba").addClass("oculto");
      });

      $(".margenpc").click(function() {
        $(".prueba").removeClass("oculto");
        $(this).addClass("activo");
      });
    // FIN DE VALIDACION DE IMAGENES

    $(".botontipouso").click(function() {
      $(".botontipouso").removeClass("seleccionadobotones");
      $(this).addClass("seleccionadobotones");
    });

    $(".botonestilo").click(function() {
      $(".botonestilo").removeClass("seleccionadobotones");
      $(this).addClass("seleccionadobotones");
    });

    $(".botonperiferico").click(function() {
      $(".botonperiferico").removeClass("seleccionadobotones");
      $(this).addClass("seleccionadobotones");
    });

    $(".botoncantidad").click(function() {
      $(".botoncantidad").removeClass("seleccionadobotones");
      $(this).addClass("seleccionadobotones");
    });

    $(".botongrafico").click(function() {
      $(".botongrafico").removeClass("seleccionadobotones");
      $(this).addClass("seleccionadobotones");
    });

    $(".botonprocesador").click(function() {
      $(".botonprocesador").removeClass("seleccionadobotones");
      $(this).addClass("seleccionadobotones");
    });

    $(".procesadorintel").click(function() {
      $(".intelvisible").removeClass("oculto");
      $(this).addClass("activo");
      $(".intelvisible2").removeClass("activo");
      $(".intelvisible2").addClass("oculto");
    });

    $(".procesadorryzen").click(function() {
      $(".intelvisible2").removeClass("oculto");
      $(this).addClass("activo");
      $(".intelvisible").removeClass("activo");
      $(".intelvisible").addClass("oculto");
    });

    $(".fondorprocesador").click(function() {
      $(".fondorprocesador").removeClass("fondoprocesadores");
      $(this).addClass("fondoprocesadores");
    });

    $(".borderdeprocesador").click(function() {
      $(".borderdeprocesador").removeClass("fondosubprocesadores");
      $(this).addClass("fondosubprocesadores");
    });
  }

  useEffect(()=>{
    getScroll();
    componentDidMount();
  }, []);

  return (
    <div>
      <Navbar/>
      <div>
        <form onSubmit={(e) => {addCotizacion(e)}}>
          <img src={coti} style={{ width: "100%", padding: "3% 3% 2%", borderRadius: "90px" }} />
          <div className='container'>
            <h2 style={{ textAlign: "center", fontSize: "35px", fontWeight: "bold", color: "#004ba2" }}>¿Que estás buscando?</h2>
            <div style={{ borderTop: "thick solid #ed571d", height: "15%", width: "20%", margin: "auto" }}></div>
            <div className='laptopopc'>
              <div>
                <span style={{ display: "block", marginBottom: "5%", fontSize: "25px", fontWeight: "bold", color: "#004ba2" }}>Laptop</span>
                <button type="button" className="btn" style={{ padding: "1%", background: "transparent", border: "transparent" }} onClick={(e) => { setPC(e.target.name) }}>
                  <img className='margen margenlaptop' src={boton1} name="Laptop"/>
                </button>
              </div>
              <div className='tiradeimagenes'></div>
              <div>
                <span style={{ display: "block", marginBottom: "5%", fontSize: "25px", fontWeight: "bold", color: "#004ba2" }}>PC</span>
                <button type="button" className="btn" style={{ padding: "1%", background: "transparent", border: "transparent" }} onClick={(e) => { setPC(e.target.name) }}>
                  <img className='margen margenpc' src={boton2} name="PC"/>
                </button>
              </div>
            </div>
            <div className='todo oculto'>
              <h2 className='tipouso'>¿Tipo de uso?</h2>
              <div className="tipousoformulario">
                <div className='tipodeoficio' style={{ textAlign: "center" }}>
                  <button type="button" className="btn botontipouso cotizaciones" value="Gamer" onClick={(e) => { setTipoUso(e.target.value) }}>Gamer</button>
                  <span className='texto-coti'>(Arquitectura, Edición y Programación)</span>
                </div>
                <div className='tipodeoficio' style={{ textAlign: "center" }}>
                  <button type="button" className="btn botontipouso cotizaciones" value="Profesional" onClick={(e) => { setTipoUso(e.target.value) }}>Profesional</button>
                  <span className='texto-coti'>(Excel, Word y Power Point)</span>
                </div>
                <div className='tipodeoficio' style={{ textAlign: "center" }}>
                  <button type="button" className="btn botontipouso cotizaciones" value="Ofimática" onClick={(e) => { setTipoUso(e.target.value) }}>Ofimática</button>
                  <span className='texto-coti'>(Primaria y Secundaria)</span>
                </div>
              </div>
              <div className='col-lg-12 prueba oculto'>
                <div className='col-lg-5' style={{ width: "100%" }}>
                  <h2 className='estilocoti'>Estilo:</h2>
                  <div className='espaciobajo'>
                    <button type="button" className="btn botonestilo cotizaciones" value="Básico" onClick={(e) => { setEstilo(e.target.value) }}>Básico</button>
                    <button type="button" className="btn botonestilo cotizaciones" value="Gamer" onClick={(e) => { setEstilo(e.target.value) }}>Gamer</button>
                  </div>
                </div>
                <div className='lineaestilo'></div>
                <div className='col-lg-5' style={{ width: "100%" }}>
                  <h2 className='estilocoti'>Periféricos incluídos:</h2>
                  <div style={{ display: "flex", justifyContent: "space-evenly", margin: "3% 0%" }}>
                    <button type="button" className="btn botonperiferico cotizaciones" value="Si" onClick={(e) => { setPerifericosIncluidos(e.target.value) }}>Si</button>
                    <button type="button" className="btn botonperiferico cotizaciones" value="No" onClick={(e) => { setPerifericosIncluidos(e.target.value) }}>No</button>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 ramygraficos'>
                <div className='col-lg-5' style={{ width: "100%" }}>
                  <h2 className='estilocoti'>Cantidad de RAM:</h2>
                  <div style={{ display: "flex", justifyContent: "space-evenly", margin: "3% 0%" }}>
                    <button type="button" className="btn botoncantidad cotizaciones" value="4GB" onClick={(e) => { setCantidadRAM(e.target.value) }}>4GB</button>
                    <button type="button" className="btn botoncantidad cotizaciones" value="8GB" onClick={(e) => { setCantidadRAM(e.target.value) }}>8GB</button>
                    <button type="button" className="btn botoncantidad cotizaciones" value="16GB" onClick={(e) => { setCantidadRAM(e.target.value) }}>16GB</button>
                  </div>
                </div>
                <div className='linederam'></div>
                <div className='col-lg-5' style={{ width: "100%" }}>
                  <h2 className='estilocoti'>Tipo de gráficos:</h2>
                  <div style={{ display: "flex", justifyContent: "space-evenly", margin: "3% 0%" }}>
                    <button type="button" className="btn botongrafico cotizaciones" value="Dedicados" onClick={(e) => { setTipoGrafico(e.target.value) }}>Dedicados</button>
                    <button type="button" className="btn botongrafico cotizaciones" value="Integrados" onClick={(e) => { setTipoGrafico(e.target.value) }}>Integrados</button>
                  </div>
                </div>
              </div>
              <h2 className='tiprocesador'>Tipo de procesador:</h2>
              <div className="tipousoformulario estilolibre" style={{ margin: "2% 0% 0% 0%" }}>
                <div className='fondorprocesador'>
                  <button type="button" className="btn botonprocesador procesadorintel cotizaciones" value="Intel" onClick={(e) => { setTipoProcesador(e.target.value) }}>Intel</button>
                </div>
                <div className='fondorprocesador estilolibre'>
                  <button type="button" className="btn botonprocesador procesadorryzen cotizaciones" value="Ryzen" onClick={(e) => { setTipoProcesador(e.target.value) }}>AMD</button>
                </div>
              </div>
              <div className="tipousoformulario" style={{ margin: "0% 0% 2% 0%" }}>
                <div className='intelvisible oculto row'>
                  <div className='col-md-3 col-sm-6 espaciointel' style={{ textAlign: "center" }}>
                    <button type="button" className="btn borderdeprocesador cotizaciones" value="Intel 3" onClick={(e) => { setTipodeProcesador(e.target.value) }}>Core I3</button>
                  </div>
                  <div className='col-md-3 col-sm-6 espaciointel' style={{ textAlign: "center" }}>
                    <button type="button" className="btn borderdeprocesador cotizaciones" value="Intel 5" onClick={(e) => { setTipodeProcesador(e.target.value) }}>Core I5</button>
                  </div>
                  <div className='col-md-3 col-sm-6 espaciointel' style={{ textAlign: "center" }}>
                    <button type="button" className="btn borderdeprocesador cotizaciones" value="Intel 7" onClick={(e) => { setTipodeProcesador(e.target.value) }}>Core I7</button>
                  </div>
                  <div className='col-md-3 col-sm-6 espaciointel' style={{ textAlign: "center" }}>
                    <button type="button" className="btn borderdeprocesador cotizaciones" value="Intel 9" onClick={(e) => { setTipodeProcesador(e.target.value) }}>Core I9</button>
                  </div>
                </div>
                <div className='intelvisible2 oculto row'>
                  <div className='col-md-3 col-sm-6 espaciointel' style={{ textAlign: "center" }}>
                    <button type="button" className="btn borderdeprocesador cotizaciones" value="Ryzen 3" onClick={(e) => { setTipodeProcesador(e.target.value) }}>Ryzen 3</button>
                  </div>
                  <div className='col-md-3 col-sm-6 espaciointel' style={{ textAlign: "center" }}>
                    <button type="button" className="btn borderdeprocesador cotizaciones" value="Ryzen 5" onClick={(e) => { setTipodeProcesador(e.target.value) }}>Ryzen 5</button>
                  </div>
                  <div className='col-md-3 col-sm-6 espaciointel' style={{ textAlign: "center" }}>
                    <button type="button" className="btn borderdeprocesador cotizaciones" value="Ryzen 7" onClick={(e) => { setTipodeProcesador(e.target.value) }}>Ryzen 7</button>
                  </div>
                  <div className='col-md-3 col-sm-6 espaciointel' style={{ textAlign: "center" }}>
                    <button type="button" className="btn borderdeprocesador cotizaciones" value="Ryzen 9" onClick={(e) => { setTipodeProcesador(e.target.value) }}>Ryzen 9</button>
                  </div>
                </div>
              </div>

              <div className='col-12'>
                <h2 className='cantidad-ram presu'>Presupuesto estimado</h2>
                <div className="input-group container-estimado">
                  <span className="input-group-text" id="basic-addon1" style={{ color: "white", fontWeight: "bolder", fontSize: "20px", background: "#ed571d", border: "1px solid #ed571d" }}>S/</span>
                  <input type="text" className="form-control input-correo" placeholder="Ingresar monto" style={{ border: "1px solid #ed571d" }} onChange={(e) => { setPresupuestoEstimado(e.target.value) }}/>
                </div>
                <strong className='color-texto'>
                  En un plazo máximo de 12 horas le estaremos enviando la cotización a su número de whatsapp o su correo electrónico.
                </strong>
              </div>

              <div className='container-coti'>
                <div className='col-md-12 col-lg-6'>
                  <h2 className='cantidad-ram-doble presu'>Teléfono</h2>
                  <div className="input-group container-estimado-doble">
                    <span className="input-group-text" id="basic-addon1" style={{ background: "#ed571d", border: "1px solid #ed571d" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" style={{ color: "white", margin: "2% 0%" }} fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                      </svg>
                    </span>
                    <input type="text" className="form-control input-correo" placeholder="Teléfono" style={{ border: "1px solid #ed571d" }} onChange={(e) => { setTelefono(e.target.value) }}/>
                  </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                  <h2 className='cantidad-ram-doble presu'>Correo Electrónico</h2>
                  <div className="input-group container-estimado-doble">
                    <span className="input-group-text" id="basic-addon1" style={{ background: "#ed571d", border: "1px solid #ed571d" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" style={{ color: "white", margin: "2% 0%" }} fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                      </svg>
                    </span>
                    <input type="email" className="form-control input-correo" placeholder="Correo electrónico" style={{ border: "1px solid #ed571d" }} onChange={(e) => { setCorreo(e.target.value) }}/>
                  </div>
                  <div className="col-12 button-categoria">
                    <button type="submit" className="btn btn-fw btn-subir-enviar disabled" style={{ margin: "3% 20% 5% 0%" }}>
                      Enviar cotización
                    </button>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </form>
      </div>
      <Footer/>
    </div>
  )
}

export default Cotizador