import React from 'react';
import '../../assets/css/agregadosfooter.css';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';

function PreguntasFrecuentes() {

  window.scrollTo(0, 0);

  return (
    <div>
      <Navbar/>
      <h2 className='preguntasfree'>Preguntas Frecuentes</h2>
      <div className="container menupreguntas">
        <div className="question">
          <input type="checkbox" id="type1" className="accordion" />
          <label className='title-cuestion' for="type1">
            ¿Cuál será el horario de atención y retiro en las tiendas de COMPUPLAZA?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links1">
            <li style={{ paddingBottom: "0px" }}>
              Nuestro horario de atención en tiendas será el siguiente:
            </li>
            <li style={{ paddingBottom: "0px" }}>
              De lunes a domingo de 9:30 am a 8:00 pm
            </li>
            <li>
              Porque queremos cuidarte y cuidarnos, en COMPUPLAZA nos hemos comprometido a seguir rigurosamente cada una de las medidas 
              dictadas por el gobierno. Por ello, te pedimos compresión si en caso el proceso habitual de entrega de pedidos sufre algún retraso. 
              Sin embargo, para cualquier consulta tendrás a tu disposición nuestros canales de comunicación, donde gustosamente podremos absolver 
              todas tus dudas. Hoy es tiempo de cuidarnos entre todos, continuaremos trabajando con estricto cuidado para seguir llevándote bienestar 
              a través de cada uno de nuestros productos.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type2" className="accordion" />
          <label className='title-cuestion' for="type2">
            ¿Cómo crear una cuenta en www.compuplaza.net.pe?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links2">
            <li>
              Haz clic en "Registrarse" y completa los datos del formulario. 
              El sistema te pedirá tus datos personales, correo electrónico, contraseña y listo.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type3" className="accordion" />
          <label className='title-cuestion' for="type3">
            ¿Qué necesito saber antes de comprar por Internet?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links3">
            <li>
              Necesitas asegurarte que tu tarjeta de crédito o débito Visa o Mastercard se encuentre registrada en el sistema Verified by Visa, 
              de no encontrarse registrada el sistema no le permitirá continuar el proceso de compra.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type4" className="accordion" />
          <label className='title-cuestion' for="type4">
            ¿Por qué debo registrarme para realizar mi compra?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links4">
            <li>
              Porque es el modo más seguro de validar los datos de nuestros clientes a quienes se les va a enviar su pedido.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type5" className="accordion" />
          <label className='title-cuestion' for="type5">
            ¿Puedo comprar más de un producto a la vez?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links5">
            <li>
              Claro que sí, una vez que agregues tu primer producto al carrito, puedes elegir todos los productos que desees al mismo tiempo.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type6" className="accordion" />
          <label className='title-cuestion' for="type6">
            ¿Cómo puedo pagar mis compras?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links6">
            <li style={{ paddingBottom: "0px" }}>
              ●	Con tarjeta de crédito o débito Visa la cual se encuentre registrada en el sistema Verified by Visa.
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	Agentes y Agencias, con método de PAGO EFECTIVO. 
            </li>
            <li>
              ●	Con abono en cuenta corriente previa coordinación a través de nuestra central de atención al cliente ( 946 348 625) o 
              al correo electrónico ventasonline@compuplaza.net.pe
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type7" className="accordion" />
          <label className='title-cuestion' for="type7">
            ¿Qué tan seguro es comprar por internet a través de COMPUPLAZA.NET.PE?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links7">
            <li>
              Compuplaza Computadoras E.I.R.L. está comprometido en asegurar la seguridad de su información. 
              Para prevenir acceso a la misma o mantener la confiabilidad de los datos y controlar el uso apropiado de la información, 
              hemos puesto en su lugar los procedimientos físicos y electrónicos apropiados para resguardar y salvaguardar la información 
              que recibimos en línea de nuestros clientes.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type8" className="accordion" />
          <label className='title-cuestion' for="type8">
            ¿Cómo verifico el estado de mi orden de pedido?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links8">
            <li>
              Muy fácil, en primer lugar tendrá que ingresar a su cuenta previamente registrada; 
              es decir acceder a su cuenta, luego hacer clic en el link "Mi cuenta" ubicada en la parte superior derecha de la página 
              y finalmente hacer clic en "Mis pedidos". Ahí podrá ver detalladamente el estado de sus pedidos.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type9" className="accordion" />
          <label className='title-cuestion' for="type9">
            ¿Qué beneficios obtengo al comprar en COMPUPLAZA.NET.PE?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links9">
            <li>
              Comodidad, seguridad y tiempo; no necesitas salir de casa, ahora todo a traves de un clic.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type10" className="accordion" />
          <label className='title-cuestion' for="type10">
            ¿Cuáles son las Políticas de Cambios y/o Devoluciones?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links10">
            <li style={{ paddingBottom: "0px" }}>
              ●	Compuplaza Computadoras E.I.R.L. solo acepta devoluciones dentro de las 72 horas posterior a la recepción del producto, 
              previa evaluación y diagnóstico de Servicio Técnico.
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	La solicitud se deberá hacer de manera presencial, se verificará el estado del producto que deberá estar completamente sin uso 
              y en óptimas condiciones, incluyendo manuales y empaque original. Los equipos deberán traer sus cajas, tecnopor, accesorios, 
              en perfectas condiciones como los adquirió, sin ningún daño físico ocasionado por el cliente, en caso de las impresoras 
              NO deberán estar instaladas las tintas, toners o cintas; en caso de los suministros, NO deberán ser abiertos de su empaque original, 
              si no cumplen con estas condiciones, no se acepta la devolución.
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	Se le recomienda al cliente que, al recibir su pedido, verifique que el producto se encuentra en buenas condiciones y con los accesorios 
              listados. 
            </li>
            <li>
              ●	Compuplaza Computadoras E.I.R.L. no se responsabiliza por el software u otros datos almacenados en discos duros internos y externos, 
              USB y otros dispositivos de almacenamiento. Se recomienda hacer Backups o copias de seguridad previas a la entrega a nuestro centro de 
              servicio.
            </li>
          </ul>
        </div>
        <div className="question">
          <input type="checkbox" id="type11" className="accordion" />
          <label className='title-cuestion' for="type11">
            ¿Cuales son los términos y condiciones de la garantía de los productos en COMPUPLAZA?
            <div id="icon">
              <span aria-hidden="true"></span>
            </div>
          </label>
          <ul id="links11">
            <li style={{ paddingBottom: "0px" }}>
              Agradecemos su confianza al adquirir un producto en Compuplaza Computadoras E.I.R.L. y esperamos que esté satisfecho con su compra. 
              En caso su compra presente inconvenientes o fallas, tenga en cuenta lo siguiente:
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	La garantía que brinda Compuplaza Computadoras E.I.R.L cubre sólo los desperfectos originados por  fallas o defectos de fabricación 
              y se aplica solo al cliente de Compuplaza Computadoras E.I.R.L. y no se extiende a terceros, solo se presta en nuestras instalaciones de 
              servicio técnico. Cada producto tiene Garantía, la cual varía de acuerdo con la marca y el fabricante, según información impresa en los 
              documentos de venta que acompañan al producto y/o en los presentes términos y condiciones.
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	La garantía de los equipos de la marca: HP, Lenovo, Asus, Acer, HP, Epson, Canon, Brother, Samsung, LG, Teros, lo cubre el mismo 
              fabricante a través de los CENTROS AUTORIZADOS DE SERVICIO bajo sus propios términos de garantía (Para más información sírvase a 
              ingresar a la página web del fabricante y ponerse en contacto con su área encargada), Compuplaza Computadoras E.I.R.L no tiene 
              responsabilidad ni injerencia alguna en la garantía de estos equipos.
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	La garantía de los equipos o productos que no cuenten con un CENTRO AUTORIZADO DE SERVICIO será cubierto por 
              Compuplaza Computadoras E.I.R.L, para tal fin los productos que estén dentro del periodo de garantía, deben ser entregados con su 
              respectiva fotocopia de la Boleta o Factura de venta emitido Compuplaza Computadoras E.I.R.L.
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	El Periodo de garantía son:
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              -	Para los productos que cubre la garantía Compuplaza Computadoras EIRL (mano de obra, repuestos o cambio de producto) será de 01 año 
              (12 meses) a partir de la fecha de compra.
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              -	Para los productos que cubre la garantía el fabricante a través de sus CENTRO AUTORIZADO DE SERVICIO será hasta 1 año (12 meses) 
              adicional, dependiendo el producto adquirido, a partir del término de la garantía brindada por el fabricante y cubrirá solo el costo 
              de mano de obra.
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	La garantía tiene las siguientes excepciones y será rechazada cuando:
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              -	El producto presente etiquetas del Fabricante o de Compuplaza Computadoras adulteradas o removida.
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              -	El producto muestre evidencia de intento de manipulación, reparación o modificación.
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              -	El producto presente daño físico alguno o señales de uso inapropiado.
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              -	El producto presente daños causados por fallas eléctricas (integrados quemados o derretidos), sobrecargas, mala instalación, 
              falta de mantenimiento o la presencia de cualquier elemento extraño (ácidos, líquidos, químicos e insectos) 
              así como la corrosión y/o óxido. 
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	La garantía no cubre:
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              - Accesorios, conectores, puertos de entrada y salida (paralelo, serial, USB, HDMI etc.), o Cabezales de Impresoras. y así mismo no cubre 
              consumibles o componentes, que se desgasten por el uso del mismo (tintas, cintas, toners, etc.)
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              -	Ningún tipo de software instalados en los equipos como computadoras, laptops, tablets, discos duros, usb, etc.
            </li>
            <li style={{ paddingBottom: "0px", margin: "0% 2%" }}>
              -	Daños producidos por transporte o mala manipulación de terceros.
            </li>
            <li style={{ paddingBottom: "0px" }}>
              ●	El proceso de garantía será en un plazo mínimo de 72 horas y un máximo de 30 días calendarios días según la disponibilidad de stock 
              de las partes y piezas, así como la respuesta del fabricante. En caso se necesite exceder el tiempo máximo de atención, 
              Compuplaza Computadoras enviará una comunicación al cliente, sin previa consulta, detallando el motivo del retraso y el plazo adicional 
              para la atención definitiva.
            </li>
            <li>
              ● La garantía es aplicable para cambio de producto o remanufacturación (sujeto a validación), en caso no se contase con stock se otorgará 
              un cambio alterno del mismo valor o similar, o se asignará una nota de crédito
            </li>
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default PreguntasFrecuentes