import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ecoUrl, linkServidor, linkCuentas } from '../../services/apirest';
import camion from '../../assets/images/productos/deliveryicon.webp';
import tienda from '../../assets/images/productos/tiendaicon.webp';
import whatsap from '../../assets/images/productos/whatsapp.webp';
import Swal from 'sweetalert2';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import pago from '../../assets/images/productos/tarjetas.webp';
import banco from '../../assets/images/productos/bancos.webp';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import horas from '../../assets/images/48horas.webp';
import $ from 'jquery';

function DetalleProducto(){
  const [IDProducto, setIDProducto] = useState('');
  const [Descripcion, setDescripcion] = useState('');
  const [ImgPrincipal, setImgPrincipal] = useState('');
  const [ImgSecundaria, setImgSecundaria] = useState('');
  const [Precio, setPrecio] = useState('');
  const [PrecioActivo, setPrecioActivo] = useState('');
  const [PrecioPromo, setPrecioPromo] = useState('');
  const [PrecioWeb, setPrecioWeb] = useState('');
  const [Stock, setStock] = useState('');
  const [StockLimaLimon, setStockLimaLimon] = useState('');
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [CantidadProductos, setCantidadProducto] = useState('1');
  let CantidadProducto = CantidadProductos;
  
  let cantidad = JSON.parse(sessionStorage.getItem('cantidad'))?JSON.parse(sessionStorage.getItem('cantidad')):0;
  let item = JSON.parse(sessionStorage.getItem('carrito'))?JSON.parse(sessionStorage.getItem('carrito')):[];

  const [Caracteristicas, setCaracteristicas] = useState([]);
  const [CategoriasProducto, setCategoriasProducto] = useState([]);
  const [GaleriaProducto, setGaleriaProducto] = useState([]);
  const [Producto, setProducto] = useState([]);
  const [GetallCart, setItem] = useState([]);
  const [Cantidades, setCantidades] = useState();
  const [BusquedaProducto, setBusquedaProducto] = useState();
  const { detalleId } = useParams();
  const [ProductoDetalle, setProductoDetalle] = useState([]);

  const [ComboProductos, setComboProductos] = useState([]);

  const fecha = new Date().toLocaleDateString('en-US');

  console.log(detalleId, 1, fecha);

  const GetCart = () =>{
    setItem(item);
    setCantidades(cantidad);
  }

  const visitas = () => {
    fetch(`${ecoUrl}/Coti/storevisitas`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        producto : detalleId,
        cantidad : 1,
        fecha : fecha,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      console.log(json);
    })  
    .catch((err)=>{
      console.log(err);
    });
  }

  const getProductoById = () => {
    setLoader(true)
    axios.get(`${ecoUrl}/Store/getProductoById/${detalleId}`)
    .then((response) => {
      setLoader(false);
      setProducto(response.data.data);
      setIDProducto(response.data.data.id);
      setDescripcion(response.data.data.description);
      setImgPrincipal(response.data.data.dir_img_pri);
      setImgSecundaria(response.data.data.dir_img_sec);
      setPrecio(response.data.data.precio);
      setPrecioActivo(response.data.data.precio_activo);
      setPrecioPromo(response.data.data.precio_prom);
      setPrecioWeb(response.data.data.precio_web);
      setStock(response.data.data.stock);
      setStockLimaLimon(response.data.data.stock_lima);
      GetCart();
      visitas();
      axios.get(`${ecoUrl}/Store/getMarcasById/${response.data.data.marca_id}`)
      .then((response) => {
        // console.log(response);
        window.scrollTo(0, 0);
      })  
      .catch((err)=>{
        console.log(err);
      });
        //console.log(response.data.data);
      })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getCaracteristicasProductoById = () => {
    axios.get(`${ecoUrl}/Store/getCaracteristicasByProducto/${detalleId}`)
    .then((response) => {
      setCaracteristicas(response.data.data);
      GetCart();
      window.scrollTo(0, 0);
    })
    .catch((err)=>{
      console.log(err);
    });
  }
  
  const getGaleriaProductoById = () => {
    axios.get(`${ecoUrl}/Store/getGalleriaByProducto/${detalleId}`)
    .then((response) => {
      setGaleriaProducto(response.data.data);
      GetCart();
      //console.log(response.data.data);
      window.scrollTo(0, 0);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getEtiquetasByProducto = () => {
    axios.get(`${ecoUrl}/Store/getEtiquetasByProducto/${detalleId}`)
    .then((response) => {
      //console.log(response.data.data[1]);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const MinCantidad = () => {
    if ( CantidadProducto > 1 ) {
      CantidadProducto--;
    }
    GetCart();
    setCantidadProducto(CantidadProducto);
    //console.log(CantidadProducto);
  }

  const PlusCantidad = () => {
    if ( CantidadProducto < (Stock + StockLimaLimon)) {
      let producto = item.filter((item) => item.id == IDProducto)[0];
      //console.log(producto != null);
      if(producto != null ){
        if ((Stock + StockLimaLimon) > (CantidadProducto + producto.cantidad_pd)) {   
          CantidadProducto++;
          GetCart();
        }else{
          alert('YA NO HAY STOCK');
        }
      }else{
        CantidadProducto++;
        GetCart();
      }
    }else{
      alert('YA NO HAY STOCK');
    }
    setCantidadProducto(CantidadProducto);
    //console.log(CantidadProducto);
  }

  const adicionarCarritoByCantidad = (produc, cant) =>{
    //console.log(produc, cant);
    let IDencontrado = false;

    item.map((value, index)=>{
      if(value.id == produc){
        IDencontrado = true;
        if (value.cantidad_pd < (Stock + StockLimaLimon)) {
          parseInt(cantidad++);

          value.cantidad_pd++;
          GetCart();
          Swal.fire({
            title: 'Producto agregado',
            icon: 'success',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonColor: '#ed571d',
            confirmButtonText: 'Ir al carrito',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonColor: '#0e2f7f',
            cancelButtonText: 'Seguir comprando',
            cancelButtonAriaLabel: 'Thumbs down'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace(`${linkCuentas}/carrito`);
            }
          })
        } else {
          console.log('Sin stock');
        }
      }
    });

    if(!IDencontrado){
      cantidad = parseInt(cant);
      // console.log(cant);
      const product = { ...Producto, cantidad_pd: cant, precio_venta_dp: getPriceProduct(Producto)};
      GetCart();
      item.push(product);
      Swal.fire({
        title: 'Producto agregado',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonColor: '#ed571d',
        confirmButtonText: 'Ir al carrito',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonColor: '#0e2f7f',
        cancelButtonText: 'Seguir comprando',
        cancelButtonAriaLabel: 'Thumbs down'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.replace(`${linkCuentas}/carrito`);
        }
      })
    }

    sessionStorage.setItem("carrito", JSON.stringify(item));
    sessionStorage.setItem('cantidad', JSON.stringify(cantidad));
  }

  const getPriceProduct = (producto) =>{ 
    let price = 0;
    if (producto.precio_activo == 1) {
      price = producto.precio;
    } else if(producto.precio_activo == 2) {
      price = producto.precio_web;
    }else{
      price = producto.precio_prom;
    }
    //console.log(price);
    return price;
  }

  const cambio = (smallImg) =>{
    //console.log(smallImg.target);
    let fullImg = document.getElementById('imageBox');
    // FALTA CAMBIAR EL COLOR
    // $(".color-galeria").removeClass("color-galeria");
    fullImg.src = smallImg.target.src;
  };

  const getCateoriasProductoById = () => {
    axios.get(`${ecoUrl}/Store/getCatsByProducto/${detalleId}`)
    .then((response) => {
      //console.log(response.data.data);
      setCategoriasProducto(response.data.data);
      GetCart();
      window.scrollTo(0, 0);
      Busqueda(response.data.data[0].descripcion);
      //console.log(response.data.data[0].descripcion);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getComboAll = () => {
    setLoader(true)
    axios.get(`${ecoUrl}/Store/getComboAll/${detalleId}`)
    .then((response) => {
      setLoader(false)
      console.log(response);
      setComboProductos(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const Busqueda = (BusquedaProducto) =>{
    //console.log(BusquedaProducto);
    axios.get(`${ecoUrl}/Store/search/${BusquedaProducto}`)
    .then((response) => {
      //console.log(response.data.data.productos);
      setProductoDetalle(response.data.data.productos);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const DetalleProductoCaracteristica = (value) =>{
    let DPCaracteristica = value.descripcion;
    if(DPCaracteristica != ''){
      navigate('/search/'+DPCaracteristica);
    }else{
      console.log('busca algo')
    }
  }

  const componentDidMount = () => {
    $('.ir-arriba').click(function(){
      $('body, html').animate({
        scrollTop: '0px'
      }, 0);
    });

    $(window).scroll(function(){
      if( $(this).scrollTop() > 0 ){
        $('.ir-arriba').slideDown(600);
      } else {
        $('.ir-arriba').slideUp(600);
      }
    });
  }

  useEffect(()=>{
    getProductoById();
    getCaracteristicasProductoById();
    getGaleriaProductoById();
    getCateoriasProductoById();
    componentDidMount();
    GetCart();
    getEtiquetasByProducto();
    getComboAll();
  }, []);

  return(
    <div>
      <Navbar/>
      <a className="ir-arriba"  href="#" title="Volver arriba">
        <span className="fa-stack">
          <i className="fa fa-circle fa-stack-2x"></i>
          <i className="fa fa-angle-double-up fa-stack-1x fa-inverse" style={{ fontSize: "30px" }}></i>
        </span>
      </a>
      {loader == true &&
        <div className='detalle-contenedor'>
          <div className='producto-nombrestock'>
            <h2 className='dinproRegular title-descripcion-producto'>
              <div className="productos-index">
                <div className="card card--skeleton">
                  <div className="img-cont" style={{ height: "50px" }}>
                  
                  </div>
                </div>
              </div>
            </h2>
            <strong className='dinproRegular' style={{ color: "red", fontSize: "19px" }}>
              <div className="productos-index" style={{ width: "40%", paddingTop: "0px" }}>
                <div className="card card--skeleton">
                  <div className="img-cont" style={{ height: "30px" }}>
                  
                  </div>
                </div>
              </div>
            </strong>
          </div>

          <div className="col-lg-12 col-xl-7" style={{ display: "flex" }}>
            <div className="small-img-row">
              <div className="small-img-col" style={{ width: "100%" }}>
                <div className="productos-index" style={{ width: "100%" }}>
                  <div className="card card--skeleton">
                    <div className="img-galeria-product img-cont">
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="small-img-col" style={{ width: "100%" }}>
                <div className="productos-index" style={{ width: "100%" }}>
                  <div className="card card--skeleton">
                    <div className="img-galeria-product img-cont">
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="small-img-col" style={{ width: "100%" }}>
                <div className="productos-index" style={{ width: "100%" }}>
                  <div className="card card--skeleton">
                    <div className="img-galeria-product img-cont">
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="small-img-col" style={{ width: "100%" }}>
                <div className="productos-index" style={{ width: "100%" }}>
                  <div className="card card--skeleton">
                    <div className="img-galeria-product img-cont">
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="small-img-col" style={{ width: "100%" }}>
                <div className="productos-index" style={{ width: "100%" }}>
                  <div className="card card--skeleton">
                    <div className="img-galeria-product img-cont">
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "80%", margin: "auto", display: "flex", backgroundColor: "white", justifyContent: "center"}}>
              <div className="productos-index" style={{ width: "100%" }}>
                <div className="card card--skeleton">
                  <div className="img-cont img-principal">
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
                  
          <div className="col-lg-12 col-xl-5 info-detalle-conten">
            <div className='detalle-producto-nombrestock'>
              <h2 className='dinproRegular' style={{ fontSize: "35px" }}>
                <div className="productos-index" style={{ width: "100%", padding: "0px", paddingTop: "19px" }}>
                  <div className="card card--skeleton">
                    <div className="img-cont" style={{ height: "10rem" }}>
                    
                    </div>
                  </div>
                </div>
              </h2>
              <strong className='dinproRegular' style={{ color: "red", fontSize: "19px" }}>
                <div className="productos-index" style={{ width: "25%", padding: "0px", paddingTop: "19px" }}>
                  <div className="card card--skeleton">
                    <div className="img-cont" style={{ height: "3rem" }}>
                    
                    </div>
                  </div>
                </div>
              </strong>
            </div>
          </div>
        </div>
      }

      {loader == false &&
        <div className='detalle-contenedor'>
          <div className='producto-nombrestock'>
            <h2 className='dinproRegular title-descripcion-producto'>{Descripcion}</h2>
            {(Stock + StockLimaLimon) == 0 &&
              <strong className='dinproRegular' style={{ color: "red", fontSize: "19px" }}>SIN STOCK</strong>
            }
            {(Stock + StockLimaLimon) != 0 &&
              <strong className='dinproRegular' style={{ color: "#0e2f7f", fontSize: "19px" }}>STOCK: {Stock + StockLimaLimon} UND</strong>
            }
          </div>
          <div className="col-lg-12 col-xl-7" style={{ display: "flex" }}>
            <div className="small-img-row">
              <div className="small-img-col">{/* color-galeria */}
                <LazyLoadImage src={linkServidor + '/' + ImgPrincipal} width="100%" className="SmallImg" onClick={(e)=>cambio(e)} effect='opacity'/>
              </div>
              {GaleriaProducto.map((value, index) =>{
                return (
                  <div key={index} className="small-img-col">{/* color-galeria */}
                    <LazyLoadImage src={linkServidor + '/' + value.dir_img} width="100%" className="SmallImg" onClick={(e)=>cambio(e)} effect='opacity'/>
                  </div>
                )
              })}
            </div>
            <div style={{ width: "80%", margin: "auto", display: "flex", backgroundColor: "white", justifyContent: "center"}}>
              <div style={{ right: "11%", position: "absolute", zIndex: "1" }}>
                <span className='avertaRegular contenporcentaje'>
                  <span className='avertaBold porcentajenumero'>{Math.round((1-(PrecioPromo / Precio))*100)}</span>
                  <div className='avertaBold' style={{ display: "grid", marginLeft: "5%" }}>
                    <span className='porcentajepromo'>%</span>
                    <span className='nombrepromo'>Dscto</span>
                  </div>
                </span>
              </div>
              {/* <div style={{ margin: "auto 0px", height: "100%", padding: "0px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                </svg>
              </div> */}
              <LazyLoadImage id='imageBox' src={linkServidor + '/' + ImgPrincipal} alt="logo" style={{ width: "85%" }} effect='opacity'/>
              {/* <div style={{ margin: "auto 0px", height: "100%", padding: "0px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </div> */}
            </div>
          </div>
          <div className="col-lg-12 col-xl-5 info-detalle-conten">
            <div className='detalle-producto-nombrestock'>
              <h2 className='dinproRegular' style={{ fontSize: "35px" }}>{Descripcion}</h2>
              {(Stock + StockLimaLimon) == 0 &&
                <strong className='dinproRegular' style={{ color: "red", fontSize: "19px" }}>SIN STOCK</strong>
              }
              {(Stock + StockLimaLimon) != 0 &&
                <strong className='dinproRegular' style={{ color: "rgb(0, 86, 185)", fontSize: "19px" }}>STOCK: {Stock + StockLimaLimon} UND</strong>
              }
            </div>
            <div className='detalle-precio-producto'>
              <div className="col-md-7 col-sm-12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <div style={{ display: "flex" }}>
                  <div className='col-6' style={{ padding: "0px" }}>
                    {/* <div>
                    <div> */}
                    {PrecioActivo == 3 &&
                      <div>
                        <p style={{ textAlign: "center", marginBottom: "7%" }}>
                          <span className="avertaBold precioofertadetalle">S/ {PrecioPromo}</span>
                        </p>
                        <p className="avertaRegular precioonlinedetalle">
                          ONLINE <span className="rallaparaprecio avertaRegular numeroonlinedetalle">S/ {PrecioWeb}</span>
                        </p>
                        <p className="avertaRegular precionormaldetalle">
                          NORMAL <span className="rallaparaprecio avertaRegular numeronormaldetalle">S/ {Precio}</span>
                        </p>
                        
                        {/* <div className='col-12 promo-detalle-producto'>
                          <p className="precio-online"> 
                            <span className='poppins-bold promo-detalle-oferta'><span className='digito-detalle'>{Math.round((1-(PrecioPromo / Precio))*100)}%</span> DSCTO</span>
                            <span className='poppins-medium precio-promo-detalle'>S/ {PrecioPromo}</span>
                          </p>
                        </div>
                        <div style={{ display: "flex", marginBottom: "3%" }}>
                          <div className='col-5 precio-normal-detalle'>
                            <p className="precio-normal poppins-bold" style={{ marginTop: "0%", fontWeight: "700" }}>PRECIO ONLINE:</p>
                            <span className="rallaparaprecio poppins-regular precio-tamaño">S/ {PrecioWeb}</span>
                          </div>
                          <div className='col-6 precio-normal2-detalle'>
                            <p className="precio-normal poppins-bold" style={{ marginTop: "0%" }}>PRECIO NORMAL:</p>
                            <span className="rallaparaprecio poppins-regular precio-tamaño">S/ {Precio}</span>
                          </div>
                        </div> */}
                      </div>
                    }
                    {PrecioActivo == 2 &&
                      <div>
                        <p style={{ textAlign: "center", marginBottom: "7%" }}>
                          <span className="avertaBold precioofertadetalle">S/ {PrecioWeb}</span>
                        </p>
                        <p className="avertaRegular precionormaldetalle">
                          NORMAL <span className="rallaparaprecio avertaRegular numeronormaldetalle">S/ {Precio}</span>
                        </p>

                        {/* <div className='col-12 promo-detalle-producto'>
                          <p className="precio-online"> 
                            <span className='poppins-bold promo-detalle-oferta'><span className='digito-detalle'>{Math.round((1-(PrecioWeb / Precio))*100)}%</span> DSCTO</span>
                            <span className='poppins-medium precio-promo-detalle'>S/ {PrecioWeb}</span>
                          </p>
                        </div>
                        <div style={{ display: "flex", marginBottom: "3%" }}>
                          <div className='col-5 precio-normal-detalle'>
                          </div>
                          <div className='col-6 precio-normal2-detalle'>
                            <p className="precio-normal poppins-bold" style={{ marginTop: "0%" }}>PRECIO NORMAL:</p>
                            <span className="rallaparaprecio poppins-regular precio-tamaño">S/ {Precio}</span>
                          </div>
                        </div> */}
                      </div>
                    }
                  </div>
                  {/* <div>
                    {(Stock + StockLimaLimon) == 0 &&
                    <div style={{ textAlign: "center", marginBottom: "-8%" }}>
                    </div>
                    }
                    {(Stock + StockLimaLimon) != 0 &&
                      <div style={{ textAlign: "center", paddingTop: "4%", paddingBottom: "2%" }}>
                        <div style={{ border: "1px solid #FE6313", display: "initial", padding: "5% 4%", borderRadius: "10px" }}>
                          {CantidadProductos != 1 &&
                            <button onClick={()=>MinCantidad()} className='reduccantidadetalle'>
                              <i className="fas fa-minus" aria-hidden="true"></i>
                            </button>
                          }
                          <input className='poppins-regular' style={{ backgroundColor: "transparent", border: "transparent", width: "9%", textAlign: "center", fontSize: "18px", color: "#0056b9"}} type="text" min="0" max={Stock} value={CantidadProductos} />
                          {(Stock + StockLimaLimon) > CantidadProductos &&
                            <button onClick={()=>PlusCantidad()} className='addcantidadetalle'>
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          }
                        </div>
                      </div>
                    }
                  </div> */}
                  <div className='col-6' style={{ display: "flex", justifyContent: "end", alignItems: "end", marginBottom: "4%" }}>
                    {(Stock + StockLimaLimon) == 0 &&
                      <div style={{ textAlign: "center", marginBottom: "-8%" }}>
                      </div>
                    }
                    {(Stock + StockLimaLimon) != 0 &&
                      <div style={{ textAlign: "center", paddingTop: "4%", paddingBottom: "2%" }}>
                        <p style={{ marginBottom: "8%", color: "#001267", fontWeight: "700" }}>Cantidad</p>
                        <div style={{ border: "1px solid #FE6313", display: "initial", padding: "10% 12%", borderRadius: "10px" }}>
                          {CantidadProductos != 1 &&
                            <button onClick={()=>MinCantidad()} className='reduccantidadetalle'>
                              <i className="fas fa-minus" aria-hidden="true"></i>
                            </button>
                          }
                          <input className='poppins-regular' style={{ backgroundColor: "transparent", border: "transparent", width: "9%", textAlign: "center", fontSize: "18px", color: "#0056b9"}} type="text" min="0" max={Stock} value={CantidadProductos} />
                          {(Stock + StockLimaLimon) > CantidadProductos &&
                            <button onClick={()=>PlusCantidad()} className='addcantidadetalle'>
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div style={{ padding: "5% 11% 0%", justifyContent: "center", display: "flex" }}>
                  <img style={{ width: "80%" }} src={horas}/>
                </div>
                <div style={{ backgroundColor: "#f0f4f7", margin: "4% 9% 9%", borderRadius: "10px", padding: "2%" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "4%", marginTop: "2%" }}>
                    <img className="col-6 camion-detalle" src={camion}/>
                    <strong className="col-6 dinproRegular mensaje-envio">Delivery a domicilio</strong>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "2%" }}>
                    <img className="col-6 tienda-detalle" src={tienda}/>
                    <strong className="col-6 dinproRegular recojo-envio">Recojo en tienda</strong>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-sm-12 ">
                <div>
                  <div style={{ display: "flex", border: "1px solid #FE6313", borderRadius: "10px", marginTop: "4%" }}>
                    <strong className='asesor-texto'>Comunícate con nuestros asesores:</strong>
                    <a href='https://bit.ly/WHATSAPPCOMPUPLAZANEW' target="_blank" style={{ marginRight: "-6%" }}>
                      <img src={whatsap}/>
                    </a>
                  </div>
                  <div className='categorias-detalle-producto'>
                    <strong className='poppins-bold' style={{ fontSize: "19px" }}>CATEGORÍAS</strong>
                    <div style={{ display: "flex" }}>
                      {CategoriasProducto.map((value, index) =>{
                        return (
                          <a onClick={()=>DetalleProductoCaracteristica(value)} className='dinproRegular' style={{ fontSize: "15px", marginRight: "1%", textDecoration: "none", cursor: "pointer"}}>{value.descripcion},</a>
                        )
                      })}
                    </div>
                  </div>
                  {(Stock + StockLimaLimon) == 0 &&
                    <div style={{ justifyContent: "center", display: "flex" }}>
                    </div>
                  }
                  {(Stock + StockLimaLimon) != 0 &&
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      {PrecioPromo != "0.00" &&
                        <button type="button" className='dinproRegular btn agregarbolsa' onClick={()=>adicionarCarritoByCantidad(IDProducto, CantidadProductos)}>Agregar a la bolsa</button>
                      }
                    </div>
                  }
                  <div style={{ display: "grid" }}>
                    <strong className='dinproRegular metododepago-detalle'>Métodos de pago:</strong>
                    <div className='tarjetas-credito'>
                      <span className='poppins-bold' style={{ fontSize: "11px", color: "#9f9f9f" }}>DÉBITO O CRÉDITO</span>
                      <img className='medios-de-pago-detalle' src={pago}/>
                    </div>
                    <div className='banco-pagos'>
                      <span className='poppins-bold' style={{ fontSize: "11px", color: "#9f9f9f" }}>TRANSF. BANCARIA</span>
                      <img className='medios-de-pago-detalle' src={banco}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <div>
        {ComboProductos.length == 0 &&
          <div>
          </div>
        }
        {ComboProductos.length != 0 &&
          <h3 className='poppins-bold caracteristicas-product-title espacio-movil' style={{ marginBottom: "1%" }}>ARMA TU COMBO</h3>
        }
      </div>

      <div style={{ width: "100%", marginBottom: "2%", justifyContent: "center", display: "flex", alignItems: "center" }}>
        <div style={{ width: "100%" }}>
          <div className='row' style={{ width: "100%", padding: "0% 6%" }}>
            <Swiper
              slidesPerView={1}
              spaceBetween={4}
              // autoplay={{ delay: 3000, disableOnInteraction: false }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 4,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 4,
                },
                1500: {
                  slidesPerView: 3,
                  spaceBetween: 4,
                }
              }}
              className="mySwiper"
            >
              {loader ? 
                <div className="contenedor-producto-principal contenedor_icon">
                  <div className="productos-index">
                    <div className="card card--skeleton">
                      <div className="img-cont">
                      
                      </div>
                    </div>
                  </div>
                </div>
                :ComboProductos.map((value, index) =>{
                  return (
                    <SwiperSlide>
                      <div style={{ padding: "2%", border: "transparent" }}>
                        <div style={{ width: "100%", display: "flex", borderLeft: "2px solid blue", borderTop: "2px solid blue", borderRight: "2px solid blue" }}>
                          <div className='col-5' style={{ width: "100%", justifyContent: "center", display: "flex", padding: "0%" }}>
                            <a href={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black", display: "flex", justifyContent: "center" }}>
                              <LazyLoadImage src={linkServidor + '/' + value.dir_img_pri} alt="logo" style={{ height: "auto" }} effect='opacity'/>
                            </a>
                          </div>
                          <div className='col-7' style={{ width: "100%", padding: "0px"}}>
                            <a href={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black" }}>
                              <div className='col-12' style={{ width: "100%", height: "40%", alignItems: "center", display: "flex", padding: "0px", paddingTop: "6%" }}>
                                <p className='dinproRegular' style={{ fontSize: "15px", lineHeight: "1.1", height: "50px", display: "-webkit-box", WebkitLineClamp: "3", WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis", marginBottom: "2%", textAlign: "center", fontWeight: "bolder", padding: "0% 4%" }}>{value.description}</p>
                              </div>
                            </a>
                            <div className='col-12' style={{ width: "100%", height:"60%", display: "flex", alignItems: "center" }}>
                              <div className='preciopaginaweb'>
                                <div style={{ width: "40%" }}>
                                  <p className='poppins-medium' style={{ fontSize: "11px", marginBottom: "0px", color: "#0e2f7f", fontWeight: "bolder" }}>Precio Online</p>
                                  <div style={{ display: "flex", justifyContent: "center", textDecoration: "line-through" }}>
                                    <span className='poppins-medium' style={{ fontSize: "15px", display: "flex", alignItems: "end", color: "#0e2f7f" }}>S/ </span>
                                    <p className='poppins-medium' style={{ marginBottom: "0px", marginLeft: "2%", fontSize: "15px", color: "#0e2f7f" }}>{value.precio_web}</p>
                                  </div>
                                  <p className='poppins-medium' style={{ fontSize: "11px", marginBottom: "0px", marginTop: "3%", color: "#0e2f7f", fontWeight: "bolder" }}>Precio Normal</p>
                                  <div style={{ display: "flex", justifyContent: "center", textDecoration: "line-through" }}>
                                    <span className='poppins-medium' style={{ fontSize: "15px", display: "flex", alignItems: "end", color: "#0e2f7f" }}>S/ </span>
                                    <p className='poppins-medium' style={{ marginBottom: "0px", marginLeft: "2%", fontSize: "15px", color: "#0e2f7f" }}>{value.precio}</p>
                                  </div>
                                </div>
                                <div style={{ width: "60%" }}>
                                  <div style={{ marginTop: "-6%" }}>
                                    <div className='poppins-medium' style={{ background: "#0e2f7f", width: "35px", color: "white", fontSize: "13px", borderRadius: "50%", height: "35px", justifyContent: "center", display: "flex", alignItems: "center", marginBottom: "-5%", marginLeft: "68%", marginTop: "5%", fontWeight: "bolder" }}>{Math.round((1-(value.precio_prom / value.precio))*100)}%</div>
                                    <div className='poppins-medium' style={{ background: "#FE6313", width: "80%", borderRadius: "5px", fontSize: "18px", color: "white", padding: "3%", marginTop: "3%", fontWeight: "bolder" }}>
                                      S/. {value.precio_prom}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='preciosparamovil'>
                                {value.precio_activo == 3 &&
                                  <div className='fondo_precios'>
                                    <p className="precio-online"> 
                                      <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_prom / value.precio))*100)}%</span> DSCTO</span>
                                      <span className='poppins-medium oferta-ciento'>S/ {value.precio_prom}</span>
                                    </p>
                                    <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                                      <div className='col-6' style={{ justifyContent: "end", padding: "0" }}>
                                        <p className="precio-normal poppins-regular precio-general">
                                          ONLINE: <span className="rallaparaprecio poppins-regular">S/ {value.precio_web}</span>
                                        </p>
                                      </div>
                                      <div className='col-6' style={{ justifyContent: "start", display: "flex" }}>
                                        <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                          NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {value.precio_activo == 2 &&
                                  <div className='fondo_precios'>
                                    <p className="precio-online"> 
                                      <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_web / value.precio))*100)}%</span> DSCTO</span>
                                      <span className='poppins-medium oferta-ciento'>S/ {value.precio_web}</span>
                                    </p>
                                    <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                                      <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                                      </div>
                                      <div className='col-6' style={{ justifyContent: "start", display: "flex" }}>
                                        <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                          NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12' style={{ background: "white", padding: "2%", borderLeft: "2px solid blue", borderRight: "2px solid blue", borderBottom: "2px solid blue", justifyContent: "center", display: "flex", alignItems: "center" }}>
                          {(value.stock + value.stock_lima) == 0 &&
                          <button type="button" className="btn btn-stock" style={{ width: "auto", fontSize: "13px", marginBottom: "10%" }} >SIN STOCK</button>
                          }
                          {(value.stock + value.stock_lima) != 0 &&
                            <button type="button" className="btn btn-resumen" style={{ width: "auto", fontSize: "13px", marginBottom: "0px" }}  onClick={()=>adicionarCarritoByCantidad(value.id, '1')}>Agregar al carrito</button>
                          }
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
      </div>
      
      <div>
        <div className='caracteristicas-detalle-producto'>
          {loader == true &&
            <center>
              <div className="productos-index" style={{ width: "80%" }}>
                <div className="card card--skeleton">
                  <div className="img-cont" style={{ height: "50px" }}>
                  
                  </div>
                </div>
              </div>
            </center>
          }
          {loader == false &&
            <h3 className='poppins-bold caracteristicas-product-title'>DESCRIPCIÓN DEL PRODUCTO</h3>
          }
          <table>
            <tbody>
              {Caracteristicas.map((value, index) =>{
                return (
                  <tr key={index}>
                    <td className='title-caracteristicas'>{value.caracteristica}</td>
                    <td className='subtitle-description'>{value.descripcion}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <div style={{ marginTop: "4%", textAlign: "center", marginBottom: "1%" }}>
          {loader == true &&
            <center>
              <div className="productos-index" style={{ width: "80%" }}>
                <div className="card card--skeleton">
                  <div className="img-cont" style={{ height: "50px" }}>
                  
                  </div>
                </div>
              </div>
            </center>
          }
          {loader == false &&
            <strong className='poppins-bold' style={{ fontSize: "19px" }}>TAMBIÉN TE PUEDE INTERESAR</strong>
          }
        </div>
        <div className="contenedor-productos-principal" style={{ padding: "0% 6% 1%" }}>
          <Swiper
            slidesPerView={2}
            spaceBetween={1}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            navigation={true}
            loop={true}
            breakpoints={{
              628: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 1,
              },
              1300: {
                slidesPerView: 4,
                spaceBetween: 1,
              },
              1860: {
                slidesPerView: 5,
                spaceBetween: 1,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {ProductoDetalle.map(value =>{
              return (
                <SwiperSlide>
                  {/* <div className="contenedor-producto-principal contenedor_icon"> */}
                  <div className="contenedor-producto-principal contenedor_icon" style={{ border: "1px solid #0e2f7f", cursor: "pointer", borderRadius: "5px" }}>
                    <div className="productos-index">
                      <div className='espacionombreindex'>
                        <span className='avertaRegular contenedornombreindex'>
                          <span className='avertaBold numeronombreindex'>{Math.round((1-(value.precio_prom / value.precio))*100)}</span>
                          <div className='avertaBold' style={{ display: "grid", marginLeft: "5%" }}>
                            <span className='porcentajenombreindex'>%</span>
                            <span className='descuentonombreindex'>Dscto</span>
                          </div>
                        </span>
                      </div>
                      <a href={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black" }}>
                        <LazyLoadImage src={linkServidor + '/' + value.dir_img_pri} alt="logo" effect='opacity'/>
                      </a>
                      <div className="panel">
                        <div className="con-tooltip right">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-heart-fill icon_product" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                          </svg>
                          <div className="tooltip ">
                            <p style={{ margin: "0px", textAlign: "center", color: "white" }}>Favorito</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <a href={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black" }}>
                        <div className="nombre-producto-principal dinproRegular">
                          {value.description}
                        </div>
                      </a>
                      {value.precio_activo == 3 &&
                        <div className='fondo_precios'>
                          <p>
                            <span className="avertaBold" style={{ fontSize: "18px", background: "#001267", padding: "1% 4%", borderRadius: "10px", color: "white"  }}>S/ {value.precio_prom}</span>
                          </p>
                          <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                            ONLINE <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio_web}</span>
                          </p>
                          <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                            NORMAL <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio}</span>
                          </p>
                        </div>
                      }
                      {value.precio_activo == 2 &&
                        <div className='fondo_precios'>
                          <p>
                            <span className="avertaBold" style={{ fontSize: "18px", background: "#001267", padding: "1% 4%", borderRadius: "10px", color: "white"  }}>S/ {value.precio_web}</span>
                          </p>
                          <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                            NORMAL <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio}</span>
                          </p>
                        </div>
                      }
                    </div>
                    <div className='fondo_precios'>
                      {(value.stock + value.stock_lima) == 0 &&
                        <button type="button" className="btn btn-stock" style={{ width: "auto", fontSize: "13px" }} >SIN STOCK</button>
                      }
                      {(value.stock + value.stock_lima) != 0 &&
                        <button type="button" className="btn btn-resumen" style={{ width: "auto", fontSize: "13px" }} onClick={()=>adicionarCarritoByCantidad(value.id, '1')}>Agregar al carrito</button>
                      }
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default DetalleProducto;