import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Autoplay, Pagination, Navigation } from "swiper";
import {Link} from "react-router-dom";
import Navbar from '../tienda/NavbarCorporativo';
import FooterCorporativo from '../tienda/FooterCorporativo';
import location from '../../assets/images/Footer/location.webp';
import { ecoUrl } from '../../services/apirest';

import image from '../../assets/images/corporativo/banner1.webp';
import imagen from '../../assets/images/corporativo/banner2.webp';
import imagenn from '../../assets/images/corporativo/banner3.webp';
import imagennn from '../../assets/images/corporativo/banner4.webp';

import chicarepuesto from '../../assets/images/corporativo/chicarepuesto.webp';
import distribucion from '../../assets/images/Footer/rapidezenaldistribucion.webp';
import productos from '../../assets/images/Footer/garantiaennuestrosproductos.webp';
import gestion from '../../assets/images/Footer/profesionalesengestion.webp';
import estrategios from '../../assets/images/Footer/aliadosestrategios.webp';
import asistencia from '../../assets/images/Footer/sistemadeasistencia.webp';
import integrales from '../../assets/images/Footer/solucionesintegralesti.webp';
import celular from '../../assets/images/corporativo/celular.webp';

import INFRAESTRUCTUAYSERVICIOTI from '../../assets/images/corporativo/01INFRAESTRUCTUAYSERVICIOTI.webp';
import SEGURIDADYVIDEOVIGILANCIA from '../../assets/images/corporativo/02SEGURIDADYVIDEOVIGILANCIA.webp';
import redesytelecomunicaciones from '../../assets/images/corporativo/03redesytelecomunicaciones.webp';
import ARRENDAMIENTOYOUTSORCINGTI from '../../assets/images/corporativo/04ARRENDAMIENTOYOUTSORCINGTI.webp';
import aireacondicionadoyclimatizacion from '../../assets/images/corporativo/05aireacondicionadoyclimatizacion.webp';
import proteccionelectrica from '../../assets/images/corporativo/06proteccionelectrica.webp';
import SOLUCIONESAUDIOVISUALES from '../../assets/images/corporativo/07SOLUCIONESAUDIOVISUALES.webp';
import suministrosypapeleira from '../../assets/images/corporativo/08suministrosypapeleira.webp';
import EQUIPAMIENTODEOFICINAS from '../../assets/images/corporativo/09EQUIPAMIENTODEOFICINAS.webp';

import corporativo1 from '../../assets/images/corporativo/minibannerscorporativo/banner1.webp';
import corporativo2 from '../../assets/images/corporativo/minibannerscorporativo/banner2.webp';
import corporativo3 from '../../assets/images/corporativo/minibannerscorporativo/banner3.webp';
import corporativo4 from '../../assets/images/corporativo/minibannerscorporativo/banner4.webp';
import corporativo5 from '../../assets/images/corporativo/minibannerscorporativo/banner5.webp';
import corporativo6 from '../../assets/images/corporativo/minibannerscorporativo/banner6.webp';

import AGROPECUARIACHIMU from '../../assets/images/corporativo/logoscorporativos/AGROPECUARIACHIMU.webp';
import AGROPECUARIANORAVES from '../../assets/images/corporativo/logoscorporativos/AGROPECUARIANORAVES.webp';
import AGROVISION from '../../assets/images/corporativo/logoscorporativos/AGROVISION.webp';
import CAJADELSANTA from '../../assets/images/corporativo/logoscorporativos/CAJADELSANTA.webp';
import CAJATRUJILLO from '../../assets/images/corporativo/logoscorporativos/CAJATRUJILLO.webp';
import CAMPOSOL from '../../assets/images/corporativo/logoscorporativos/CAMPOSOL.webp';
import CLINICASANANTONIO from '../../assets/images/corporativo/logoscorporativos/CLINICASANANTONIO.webp';
import CLINICASANPABLO from '../../assets/images/corporativo/logoscorporativos/CLINICASANPABLO.webp';
import CLINICASANA from '../../assets/images/corporativo/logoscorporativos/CLINICASANA.webp';
import COOPERATIVALEONXIII from '../../assets/images/corporativo/logoscorporativos/COOPERATIVALEONXIII.webp';
import COOPERATIVASANJOSE from '../../assets/images/corporativo/logoscorporativos/COOPERATIVASANJOSE.webp';
import COOPERATIVASANLORENZO from '../../assets/images/corporativo/logoscorporativos/COOPERATIVASANLORENZO.webp';
import EJERCITOPERUANO from '../../assets/images/corporativo/logoscorporativos/EJERCITOPERUANO.webp';
import ELROCIO from '../../assets/images/corporativo/logoscorporativos/ELROCIO.webp';
import EMTRAFESA from '../../assets/images/corporativo/logoscorporativos/EMTRAFESA.webp';
import EUROTUBO from '../../assets/images/corporativo/logoscorporativos/EUROTUBO.webp';
import GOBIERNOLIMA from '../../assets/images/corporativo/logoscorporativos/GOBIERNOLIMA.webp';
import GRUPOSANTAELENA from '../../assets/images/corporativo/logoscorporativos/GRUPOSANTAELENA.webp';
import HORTIFRUT from '../../assets/images/corporativo/logoscorporativos/HORTIFRUT.webp';
import INKAFERRO from '../../assets/images/corporativo/logoscorporativos/INKAFERRO.webp';
import ITSSA from '../../assets/images/corporativo/logoscorporativos/ITSSA.webp';
import MISSION from '../../assets/images/corporativo/logoscorporativos/MISSION.webp';
import MOLINORTE from '../../assets/images/corporativo/logoscorporativos/MOLINORTE.webp';
import MUNICIPALIDADDISTRITALDELAREDO from '../../assets/images/corporativo/logoscorporativos/MUNICIPALIDADDISTRITALDELAREDO.webp';
import MUNICIPALIDADPROVINCIALDELSANTA from '../../assets/images/corporativo/logoscorporativos/MUNICIPALIDADPROVINCIALDELSANTA.webp';
import MUNICIPALIDADPROVINCIALDECHICLAYO from '../../assets/images/corporativo/logoscorporativos/MUNICIPALIDADPROVINCIALDECHICLAYO.webp';
import TALSA from '../../assets/images/corporativo/logoscorporativos/TALSA.webp';
import TERMINALSALAVERRY from '../../assets/images/corporativo/logoscorporativos/TERMINALSALAVERRY.webp';
import TRANSPESA from '../../assets/images/corporativo/logoscorporativos/TRANSPESA.webp';
import TRANSPORTESRODRIGOCARRANZA from '../../assets/images/corporativo/logoscorporativos/TRANSPORTESRODRIGOCARRANZA.webp';
import UCATOLICA from '../../assets/images/corporativo/logoscorporativos/UCATOLICA.webp';
import UCV from '../../assets/images/corporativo/logoscorporativos/UCV.webp';
import ULADECH from '../../assets/images/corporativo/logoscorporativos/ULADECH.webp';
import UNIVERSIDADDESANTA from '../../assets/images/corporativo/logoscorporativos/UNIVERSIDADDESANTA.webp';
import UNIVERSIDADNACIONALDEINGENIERIAUNI from '../../assets/images/corporativo/logoscorporativos/UNIVERSIDADNACIONALDEINGENIERIAUNI.webp';
import UPAO from '../../assets/images/corporativo/logoscorporativos/UPAO.webp';
import YAPUSOLUTIONS from '../../assets/images/corporativo/logoscorporativos/YAPUSOLUTIONS.webp';
import DANPER from '../../assets/images/corporativo/logoscorporativos/DANPER.webp';


import $ from 'jquery';
import axios from 'axios';

function corporativo() {
  
  const [Nombre, setNombre] = useState('');
  const [Ruc, setRuc] = useState('');
  const [Telefono, setTelefono] = useState('');
  const [Email, setEmail] = useState('');
  const [Solicitud, setSolicitud] = useState('');
  const [Slidder, setSlidder] = useState(0);

  const getScroll = () => {
    window.scrollTo(0, 0);
  }

  const onChangeSlider = (e) => {
    setSlidder(e.activeIndex);
  }
  
  // const Corpo = (e) => {
  //   e.preventDefault();
  //   fetch(`${ecoUrl}/Coti/StoreCorpo`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       nombre : Nombre,
  //       ruc: Ruc,
  //       telefono: Telefono,
  //       correo : Email,
  //       solicitud : Solicitud,
  //     }),
  //   })
  //   .then((response) => response.json())
  //   .then((json)=>{
  //     console.log(json);
  //   })  
  //   .catch((err)=>{
  //     console.log(err);
  //   });
  // }

  const Corpo = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url : `${ecoUrl}/Coti/StoreCorpo`, 
      data: JSON.stringify({
        nombre : Nombre,
        ruc: Ruc,
        telefono: Telefono,
        correo: Email,
        solicitud: Solicitud,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((json)=>{
      console.log(json);
      e.target.reset();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  useEffect(()=>{
    getScroll();
  }, []);
  
  return (
    <div>
      <Navbar/>
      <div className='fondo-corporativo' style={{ width: "100%", height: "auto" }}>
      <Swiper 
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        modules={[Autoplay, Pagination]}
        className="mySwiper">
        <SwiperSlide>
          <LazyLoadImage src={image} style={{ width: "100%" }} effect='opacity'/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src={imagen} style={{ width: "100%" }} effect='opacity'/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src={imagenn} style={{ width: "100%" }} effect='opacity'/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage src={imagennn} style={{ width: "100%" }} effect='opacity'/>
        </SwiperSlide>
      </Swiper>
      </div>
      <div className='col-12' style={{ display: "flex", padding: "0px", margin: "0px", marginTop: "2%" }}>
        <Swiper
          slidesPerView={2}
          spaceBetween={0}
          loop={true}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            }
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <div>
            <div className='col-2' style={{ padding: "0px" }}>
              <SwiperSlide>
                <LazyLoadImage width="100%" src={corporativo1} alt="logo" effect='opacity'/>
              </SwiperSlide>
            </div>
            <div className='col-2' style={{ padding: "0px" }}>
              <SwiperSlide>
                <LazyLoadImage width="100%" src={corporativo2} alt="logo" effect='opacity'/>
              </SwiperSlide>
            </div>
            <div className='col-2' style={{ padding: "0px" }}>
              <SwiperSlide>
                <LazyLoadImage width="100%" src={corporativo3} alt="logo" effect='opacity'/>
              </SwiperSlide>
            </div>
            <div className='col-2' style={{ padding: "0px" }}>
              <SwiperSlide>
                <LazyLoadImage width="100%" src={corporativo4} alt="logo" effect='opacity'/>
              </SwiperSlide>
            </div>
            <div className='col-2' style={{ padding: "0px" }}>
              <SwiperSlide>
                <LazyLoadImage width="100%" src={corporativo5} alt="logo" effect='opacity'/>
              </SwiperSlide>
            </div>
            <div className='col-2' style={{ padding: "0px" }}>
              <SwiperSlide>
                <LazyLoadImage width="100%" src={corporativo6} alt="logo" effect='opacity'/>
              </SwiperSlide>
            </div>
          </div>
        </Swiper>
      </div>
      {/* <div className='fondo-corporativo' style={containerStyle}></div> */}
      
      <div id="scrollspyHeading1">
      <br></br>
      <br></br>
      <br></br>
      </div>

      <div style={{ width: "100%", background: "#0e2f7f", marginBottom: "5%", color: "white" }}>
        <div style={{ display: "flex"}}>
          <div className='col-6' style={{ padding: "0px", display: "flex" }}>
            <div className='cajadetexto_corporativo'>
              COMPUPLAZA CORPORATIVO es la división especializada de COMPUPLAZA<br></br>
              que brinda soluciones y proyectos TI a empresas. Contamos con una<br></br>
              experiencia de más de 18 años en el mercado a nivel nacional.<br></br><br></br>

              Nuestro amplio portafolio de soluciones está respaldado por personal<br></br>
              propio altamente calificado, certificado y experimentado en cada rubro,<br></br>
              además, contamos con la representación y respaldo de las mejores marcas<br></br>
              TI del mercado, permitiéndonos brindarles soluciones de alta calidad a <br></br>
              costos muy competitivos, con un completo servicio de garantía y post venta.<br></br>
            </div>
          </div>
          <div className='col-6' style={{ padding: "0px" }}>
            <img src={chicarepuesto} style={{ width: "80%", float: "right" }}/>
          </div>
        </div>
        <div id="scrollspyHeading4"></div>
      </div>     

      <div style={{ textAlign: "center", marginBottom: "4%" }}>
        <h2 style={{ margin: "0px", fontSize: "17px", color: "#ed571d" }}>Nuestros</h2>
        <h1 style={{ fontSize: "20px", color: "#001267" }}>SERVICIOS</h1>
        <div className='container' style={{ width: "100%", margin: "auto" }}> 
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            centeredSlides={true}
            // pagination={{clickable: true}}
            // onSlideChange={() => console.log('slide change')}
            autoplay={{ delay: 3500, disableOnInteraction: false }}
            modules={[Autoplay]}
            onSlideChange={(e) => { onChangeSlider(e) }}
            className="mySwiper"
          >
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={INFRAESTRUCTUAYSERVICIOTI} effect='opacity'/>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={SEGURIDADYVIDEOVIGILANCIA} effect='opacity'/>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={redesytelecomunicaciones} effect='opacity'/>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={ARRENDAMIENTOYOUTSORCINGTI} effect='opacity'/>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={aireacondicionadoyclimatizacion} effect='opacity'/>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={proteccionelectrica} effect='opacity'/>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={SOLUCIONESAUDIOVISUALES} effect='opacity'/>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={suministrosypapeleira} effect='opacity'/>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ background: "transparent", display: "flex", alignItems: "self-end" }}>
              <div className='contenedor_corporativo'>
                <LazyLoadImage src={EQUIPAMIENTODEOFICINAS} effect='opacity'/>
              </div>
            </SwiperSlide>
          </Swiper>

          <div style={{ background: "#ececec", width: "90%", margin: "auto", borderRadius: "15px" }}>
            {Slidder == 0 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}>INFRAESTRUCTURA Y SERVICIO TI.</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-md-12 col-lg-6 col-xl-6'>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        PC’s, Notebooks y Workstations.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Servidores y Data Centers.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Equipos de Puntos de Venta.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Equipos de Impresión.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Proyectores Multimedia.
                      </p>
                    </div>
                    <div className='col-md-12 col-lg-6 col-xl-6'>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Equipos Biométricos y Accesos.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Equipos de Protección Eléctrica.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Proyectos y Servicios de TI.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Servicios Cloud y Hosting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Slidder == 1 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}>SEGURIDAD Y VIDEOVIGILANCIA</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-md-12 col-lg-6 col-xl-6'>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Sistemas de Videovigilancia.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Sistemas de Alarma.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Sistemas de Control y Accesos.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Cercos Eléctricos.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Sistemas Contra Incendios.
                      </p>
                    </div>
                    <div className='col-md-12 col-lg-6 col-xl-6'>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Televisores de 24x7.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Slidder == 2 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}> REDES Y TELECOMUNICACIONES</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-lg-6 col-md-8 col-sm-12' style={{ margin: "auto" }}>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Cableado Estructurado y Certificado.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Radioenlace y Telecomunicaciones.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Redes de Fibra Óptica.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Centrales y Telefonía IP.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Slidder == 3 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}> ARRENDAMIENTO Y OUTSORCING TI</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-lg-6 col-md-8 col-sm-12' style={{ margin: "auto" }}>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Arrendamiento Equipos de TI.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Arrendamiento de Impresoras.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Outsourcing de Soporte TI.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Alquiler Audiovisuales para Eventos.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Slidder == 4 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}> AIRE ACONDICIONADO Y CLIMATIZACIÓN</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-lg-6 col-md-8 col-sm-12' style={{ margin: "auto" }}>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Aire Acondicionado de Oficina.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Aire Acondicionado Industriales.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Proyectos de Climatización y Ventilación.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Mantenimiento de Aires Acondicionados.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Slidder == 5 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}>PROTECCIÓN ELÉCTRICA</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-lg-6 col-md-8 col-sm-12' style={{ margin: "auto" }}>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Instalaciones Eléctricas.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Protección Eléctrica.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Pozos a Tierra.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Grupos Electrógenos.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Mantenimiento de Redes Eléctricas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Slidder == 6 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}>SOLUCIONES AUDIOVISUALES</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-md-12 col-lg-6 col-xl-6'>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Pantallas Interactivas.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Sistemas de Videoconferencia.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Smart TV’s.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Cámaras Profesionales y de Movimiento.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Drones y Estabilizadores.
                      </p>
                    </div>
                    <div className='col-md-12 col-lg-6 col-xl-6'>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Tablet’s y Equipos Celulares.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Slidder == 7 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}> SUMINISTROS DE PAPELERÍA</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-lg-6 col-md-8 col-sm-12' style={{ margin: "auto" }}>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Suministros de Impresión.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Papelería de Oficina.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Papel Térmico.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Slidder == 8 &&
              <div>
                <div className='col-12'>
                  <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                    <p className='titulo_corporativo' style={{ background: "#ed571d", padding: "0px 1%", borderRadius: "10px" }}> EQUIPAMIENTO DE OFICINAS</p>
                  </div>
                  <div className='slider_corporativo'>
                    <div className='col-lg-6 col-md-8 col-sm-12' style={{ margin: "auto" }}>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Sistema Drywall.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Cielo Raso.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Mobiliario y Sillas de Oficina.
                      </p>
                      <p className='contexto_info'>
                        <svg xmlns="http://www.w3.org/2000/svg" color='#ed571d' fill="currentColor" className="bi bi-dot punto_info" viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        </svg>
                        Accesorios de Oficina.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "3%" }}>
        <Swiper
          slidesPerView={2}
          slidesPerGroup={1}
          spaceBetween={0}
          loopFillGroupWithBlank={true}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          loop={true}
          modules={[Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 6,
            },
            1024: {
              slidesPerView: 8,
            }
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={AGROPECUARIACHIMU}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "50%", margin: "auto", cursor: "pointer" }}>
              <img src={AGROPECUARIANORAVES}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={AGROVISION}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={CAJADELSANTA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={CAJATRUJILLO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={CAMPOSOL}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={CLINICASANANTONIO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={CLINICASANPABLO}/>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={CLINICASANA}/>
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={COOPERATIVALEONXIII}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={COOPERATIVASANJOSE}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={COOPERATIVASANLORENZO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "30%", margin: "auto", cursor: "pointer" }}>
              <img src={EJERCITOPERUANO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={ELROCIO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={EMTRAFESA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={EUROTUBO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={GOBIERNOLIMA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={GRUPOSANTAELENA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={HORTIFRUT}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={INKAFERRO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={ITSSA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={MISSION}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={MOLINORTE}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={MUNICIPALIDADDISTRITALDELAREDO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={MUNICIPALIDADPROVINCIALDELSANTA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={MUNICIPALIDADPROVINCIALDECHICLAYO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={TALSA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={TERMINALSALAVERRY}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={TRANSPESA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={TRANSPORTESRODRIGOCARRANZA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={UCATOLICA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={UCV}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={ULADECH}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={UNIVERSIDADDESANTA}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={UNIVERSIDADNACIONALDEINGENIERIAUNI}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={UPAO}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={YAPUSOLUTIONS}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <img src={DANPER}/>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div style={{ textAlign: "center" }}>
        <h2 style={{ margin: "0px", fontSize: "17px", color: "#ed571d" }}>Nuestros</h2>
        <h1 style={{ fontSize: "20px", color: "#001267" }}>BENEFICIOS</h1>
        <div className='contenedor_beneficios'>
          <div className='container' style={{ padding: "0px" }}>
            <div className='col-12' style={{ display: "flex", flexFlow: "wrap", padding: "3% 0%" }}>
              <div className='col-lg-4 col-md-6 col-sm-6 col-6' style={{ padding: "0% 0% 2%" }}>
                <img style={{ width: "14%", marginTop: "2%" }} src={integrales} alt="logo"/>
                <p className="texto-tira-beneficios">
                  <span className="subtexto-tira-index" style={{ fontWeight: "700" }}><strong>Más de 10 años</strong> ofreciendo <br></br> soluciones integrales</span>  
                </p>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-6' style={{ padding: "0% 0% 2%" }}>
                <img style={{ width: "17%", marginTop: "5%" }} src={estrategios} alt="logo"/>
                <p className="texto-tira-beneficios">
                  <span className="subtexto-tira-index" style={{ fontWeight: "700" }}><strong>Aliados estratégicos</strong> de <br></br> marcas lideres en tecnología</span>  
                </p>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-6' style={{ padding: "0% 0% 2%" }}>
                <img style={{ width: "13%", marginTop: "0%" }} src={productos} alt="logo"/>
                <p className="texto-tira-beneficios">
                  <span className="subtexto-tira-index" style={{ fontWeight: "700" }}><strong>Garantía</strong> en todos <br></br> nuestros productos</span>
                </p>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-6' style={{ padding: "0% 0% 2%" }}>
                <img style={{ width: "13%", marginTop: "3%" }} src={asistencia} alt="logo"/>
                <p className="texto-tira-beneficios">
                  <span className="subtexto-tira-index" style={{ fontWeight: "700" }}>Sistema de <strong>asistencia y </strong><br></br><strong> soporte</strong> de post venta</span>  
                </p>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-6' style={{ padding: "0% 0% 2%" }}>
                <img style={{ width: "16%", marginTop: "5%" }} src={distribucion} alt="logo"/>
                <p className="texto-tira-beneficios">
                  <span className="subtexto-tira-index" style={{ fontWeight: "700" }}><strong>Rapidez</strong> en la distribución <br></br> de productos informáticos</span>  
                </p>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-6' style={{ padding: "0% 0% 2%" }}>
                <img style={{ width: "13%", marginTop: "2%" }} src={gestion} alt="logo"/>
                <p className="texto-tira-beneficios">
                  <span className="subtexto-tira-index" style={{ fontWeight: "700" }}><strong>Profesionales</strong> en la gestión y <br></br> ejecución de proyectos TI</span>  
                </p>
              </div>
            </div>
          </div>
          <div id="scrollspyHeading2"></div>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        {/* <h1 style={{ fontSize: "20px", color: "#001267" }}>CONTÁCTANOS</h1> */}
        <div className='container contorno_contacto'>
          <div className='col-4' style={{ height: "100%", display: "flex", justifyContent: "end", alignItems: "end", zIndex: "1" }}>
            <img className='telefono_contacto' src={celular} alt="logo"/>
          </div>
          <div className='col-6 contacto_bloque'>
            <div className='contenedor_contacto'>
              <form onSubmit={(e) => {Corpo(e)}}>
                <div className='container input_contacto'>
                  <input type="text" className="form-control formulario_corporativo" id="exampleFormControlInput1" placeholder="NOMBRE:" onChange={(e) => { setNombre(e.target.value) }} />
                </div>
                <div className='container input_contacto'>
                  <input type="text" className="form-control formulario_corporativo" id="exampleFormControlInput1" placeholder="RUC:" onChange={(e) => { setRuc(e.target.value) }} />
                </div>
                <div className='container input_contacto'>
                  <input type="text" className="form-control formulario_corporativo" id="exampleFormControlInput1" placeholder="TELÉFONO:" onChange={(e) => { setTelefono(e.target.value) }} />
                </div>
                <div className='container input_contacto'>
                  <input type="email" className="form-control formulario_corporativo" id="exampleFormControlInput1" placeholder="CORREO:" onChange={(e) => { setEmail(e.target.value) }} />
                </div>
                <div className='container input_contacto'>
                  <textarea type="text" className="form-control formulario_corporativo" id="exampleFormControlInput1" rows="4" placeholder="SOLICITUD:" onChange={(e) => { setSolicitud(e.target.value) }} />
                </div>
                <div className='container button_contacto'>
                  <button type="submit" className="btn btn-light boton_de_enviar">ENVIAR</button>
                </div>
              </form>
            </div>  
          </div>
        </div>
        <div id="scrollspyHeading3"></div>
      </div>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: "20px", color: "#ed571d" }}>CONTÁCTANOS</h1>
        <div style={{ display: "inline-block" }}>
          <div style={{ display: "inline-flex", alignItems: "center", marginBottom: "3%" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ color: "#ed571d", marginLeft: "-9%", marginRight: "2%" }} className="bi bi-phone" viewBox="0 0 16 16">
              <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg>
            <span style={{ fontWeight: "700", color: "#001267" }}>920463851</span>
            <hr id='linea_corporativa'></hr>
            <span style={{ fontWeight: "700", color: "#001267" }}>922682564</span>
          </div>

          <div style={{ marginBottom: "15%" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "#ed571d", marginLeft: "-9%", marginRight: "2%" }} className="bi bi-envelope" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
            </svg>
            <span style={{ fontWeight: "700", color: "#001267" }}>ventas@compuplaza.net.pe</span>
          </div>
        </div>
        <div className='maps_corporativos'>
          <div style={{ width: "100%" }}>
            <h2 style={{ margin: "0px", fontSize: "17px", color: "#001267", fontWeight: "700", marginBottom: "1%" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" style={{ marginTop: "-0.5%", marginRight: "0.3%", color: "#ed571d" }} fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
              </svg>
              Trujillo
            </h2>
            <h3 style={{ margin: "0px", fontSize: "15px", color: "#001267", marginBottom: "2%" }}>Mza. C Lote 12B Urb. San Jose de California</h3>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1974.8319334957534!2d-79.0407063109986!3d-8.13566092107036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ad3d6afd1f7209%3A0x451a41afbf0d29fc!2sPrimer%20Parque%20San%20Jose%20De%20California!5e0!3m2!1ses-419!2spe!4v1700662332853!5m2!1ses-419!2spe" width="100%" height="400" className='maps_corporativo_trujillo' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div style={{ width: "100%" }}>
            <h2 style={{ margin: "0px", fontSize: "17px", color: "#001267", fontWeight: "700", marginBottom: "1%" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" style={{ marginTop: "-0.5%", marginRight: "0.3%", color: "#ed571d" }} fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
              </svg>
              Lima
            </h2>
            <h3 style={{ margin: "0px", fontSize: "15px", color: "#001267", marginBottom: "2%" }}>Av. Petit Thouars Nro. 1498 Of. 1707 Urb. Santa Beatriz</h3>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.4786933868495!2d-77.03685488830794!3d-12.079346588110575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c8f34513ad29%3A0x27bccb47eb8e789e!2sOf.%201707%2C%20Av.%20Petit%20Thouars%201498%2C%20Lima%2015046!5e0!3m2!1ses-419!2spe!4v1700662596529!5m2!1ses-419!2spe" width="100%" height="400" className='maps_corporativo_lima' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>

        {/* <h2 style={{ margin: "0px", fontSize: "17px", color: "#001267", fontWeight: "700", marginTop: "1%", marginBottom: "0.5%" }}>Lima</h2>
        <h3 style={{ margin: "0px", fontSize: "15px", color: "#001267", marginBottom: "2%" }}><a style={{ color: "#001267", textDecoration: "none" }} href='https://maps.app.goo.gl/NKAfU173tpet2bHt7' target="_blank">Av. Petit Thouars Nro. 1498 Of. 1707 Urb. Santa Beatriz</a></h3> */}

      </div>
      <FooterCorporativo/>
    </div>
  )
}

export default corporativo