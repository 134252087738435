import React from 'react'
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import compra from '../../assets/images/COMPRAENCOMPUPLAZA.png';
import numeros05 from '../../assets/images/Numeros/NUMEROS05.svg';
import numeros06 from '../../assets/images/Numeros/NUMEROS06.svg';
import numeros07 from '../../assets/images/Numeros/NUMEROS07.svg';
import numeros08 from '../../assets/images/Numeros/NUMEROS08.svg';
import numeros09 from '../../assets/images/Numeros/NUMEROS09.svg';

function Manual() {
  
  window.scrollTo(0, 0);

  return (
    <div>
      <Navbar/>
      <div className="container">
        <div className="row abajo">
          <div className="col-12">
            <img width="100%" style={{ marginTop: "3%" }} src={compra} alt="" />
          </div>
          <div className="col-12 col-md-12 col-lg-6 caja-manual-pasos">
            <div className="contenedor-total shadow">
              <p className="title-manual">
                <img width="7%" src={numeros05} alt=""/> Regístrate e inicia sesión
              </p>
              <p className="text-manual">
                Llena todos los datos en el formulario de registro y luego inicia sesión para proceder con tu compra.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 caja-manual-pasos">
            <div className="contenedor-total shadow">
              <p className="title-manual">
                <img width="7%" src={numeros06} alt="" /> Busca tu producto y agrégalo al carrito
              </p>
              <p className="text-manual">
                Agrega todos los productos que quieras e inicia el proceso de compra.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 caja-manual-pasos">
            <div className="contenedor-total shadow">
              <p className="title-manual">
                <img width="7%" src={numeros07} alt="" /> Llena todos los datos
              </p>
              <p className="text-manual">
                Llena todos los campos obligatorios y selecciona tu lugar de envío.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 caja-manual-pasos">
            <div className="contenedor-total shadow">
              <p className="title-manual">
                <img width="7%" src={numeros08} alt="" /> Elige tu medio de pago
              </p>
              <p className="text-manual">
                Pago con tarjeta, Transferencia, Pago en efectivo ó Pago Contraentrega unicamente en nuestra tienda.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 caja-manual-pasos">
            <div className="contenedor-total shadow">
              <p className="title-manual">
                <img width="7%" src={numeros09} alt="" /> Resumen y pago de compra 
              </p>
              <p className="text-manual">
                Revisa que la información ingresada esté correcta y finaliza el proceso de compra.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 caja-manual-pasos">
            <div className="contenedor-total shadow">
              <p className="title-manual" style={{ padding: "5% 2%", textAlign: "center", color: "#FE6313"}}>
                Y finalmente disfruta de la experiencia tecnologia con COMPUPLAZA.NET.PE
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Manual