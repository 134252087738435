import React, { useState, useEffect }  from 'react';
import { ecoUrl, linkServidor } from '../../services/apirest';
import axios from 'axios';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';

function DetalledeCompra() {
  
  const [PedidoID, setPedidoID] = useState('');
  const [Nombre, setNombre] = useState('');
  const [Apellido, setApellido] = useState('');
  const [Fecha, setFecha] = useState('');
  const [Monto, setMonto] = useState('');
  const [Estatus, setEstatus] = useState('');
  const [TipoMoneda, setTipoMoneda] = useState('');
  const [Tarjeta, setTarjeta] = useState('');
  const [MarcaTarjeta, setMarcaTarjeta] = useState('');
  const [ActionDescription, setActionDescription] = useState('');
  const [AllCart, setAllCart] = useState([]);

  let ID = JSON.parse(sessionStorage.getItem('ID'))?JSON.parse(sessionStorage.getItem('ID')):0;

  const getScroll = () => {
    window.scrollTo(0, 0);
  }

  const ObtenerpagoLink = () => {
    axios.get(`${ecoUrl}/Pedido/PagoConsultaNiubiz/${ID}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response);
      setPedidoID(response.data.externalId);
      setMonto(response.data.amount);
      setEstatus(response.data.status);
      setTipoMoneda(response.data.currencyId);
      setTarjeta(response.data.data.card);
      setMarcaTarjeta(response.data.data.brand);
      setActionDescription(response.data.data.actionDescription);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const ObtenerProductosComprados = () => {
    axios.get(`${ecoUrl}/Pedido/DetalledeCompra/${ID}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response);
      setNombre(response.data.data.pedido.nombre)
      setApellido(response.data.data.pedido.apellido);
      setFecha(response.data.data.pedido.created_at);
      setAllCart(response.data.data.websaledetail);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  useEffect(()=>{
    ObtenerpagoLink();
    ObtenerProductosComprados();
    getScroll();
  }, []);

  return (
    <div>
      <Navbar/>
      <div className="container">
        <h2 style={{ fontWeight: "700", fontSize: "40px", color: "#fe6313", marginTop: "4%", textAlign: "center"}}>DETALLE DE COMPRA</h2>
        <div className="col-lg-12 col-xl-6" style={{ width: "100%", marginTop: "2%" }}>
        {Estatus == "COMPLETED" &&
          <div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Número de Pedido: </label>
              <span style={{ fontSize: "20px"}}> {PedidoID}</span>
            </div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Nombres y Apellidos: </label>
              <span style={{ fontSize: "20px"}}> {Nombre} {Apellido}</span>
            </div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Fecha y Hora: </label>
              <span style={{ fontSize: "20px"}}> {Fecha}</span>
            </div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Importe de la Transacción: </label>
              <span style={{ fontSize: "20px"}}> {Monto}</span>
            </div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Tipo de Moneda: </label>
              <span style={{ fontSize: "20px"}}> {TipoMoneda}</span>
            </div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Número de Tarjeta: </label>
              <span style={{ fontSize: "20px"}}> {Tarjeta}</span>
            </div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Tarjeta: </label>
              <span style={{ fontSize: "20px"}}> {MarcaTarjeta}</span>
            </div>
          </div>
        }
        {Estatus == "FAILED" &&
          <div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Número de Pedido: </label>
              <span style={{ fontSize: "20px"}}> {PedidoID}</span>
            </div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Fecha y Hora: </label>
              <span style={{ fontSize: "20px"}}> {Fecha}</span>
            </div>
            <div>
              <label className='title-name-detalle' style={{ fontWeight: "bolder" }}>Descripción de la Denegación: </label>
              <span style={{ fontSize: "20px"}}> {ActionDescription}</span>
            </div>
          </div>
        }
        </div>
        <div className="col-12" style={{ width: "100%", height: "300px", marginTop: "2%", marginBottom: "4%" }}>
          <div className="table-responsive">
            <table className="table" style={{ marginBottom:"0px" }}>
              <thead>
                <tr>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Imagen</th>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Descripción</th>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Cantidad</th>
                  <th scope="col" className='titulo-carrito' style={{ border: "transparent", fontSize: '19px' }}>Total</th>
                </tr>
              </thead>
            </table>
            <table class = "gfg">
              <tbody>
                {AllCart.map((value, index) =>{
                  return (
                    <tr key={index}>
                      <td className='estilos-carrito' style={{ borderLeft: "1px solid #fe6313", borderTopLeftRadius: "14px", borderBottomLeftRadius: "14px" }}>
                        <img src={linkServidor + '/' + value.dir_img_pri} alt="logo" style={{ width: "50%" }} />
                      </td>
                      <td className='estilos-carrito' style={{ color: "black" }}>{value.descripcion}</td>
                      <td className='estilos-carrito' style={{ color: "black" }}>{value.cantidad}</td>
                      <td className='estilos-carrito' style={{ borderRight: "1px solid #fe6313", borderTopRightRadius: "14px", borderBottomRightRadius: "14px", color: "black" }}>S/ {value.precio_venta}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>  
      <Footer/>  
    </div>
  )
}

export default DetalledeCompra