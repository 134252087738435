import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { baseUrl } from '../services/apirest';

export const ProtecteRoute =  ({children, redirectTo = "/"}) =>{
  const [Token, setToken] = useState('');
  let token = localStorage.getItem("token");
  
  if(Token != token ){
    setToken(token)
    axios.get(`${baseUrl}/getUsuario`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data.data);
    })
    .catch((err)=>{
      return <Navigate to={redirectTo}></Navigate>
    });
  }

  if(token == null ){
    return <Navigate to={redirectTo}></Navigate>
  }else{
    return children
  }
  
}

export default ProtecteRoute;