import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../assets/images/COMPUPLAZA_FINAL.svg';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';

function Tienda() {
  
  window.scrollTo(0, 0);

  return (
    <div>
      <Navbar/>
      <div className="container">
        <div className="row"> 
          <div className="titulo-tiendas col-12">
            <Link to={`/`}>
              <img className="logo-tiendas" src={logo} alt="logo de compuplaza" />
            </Link>
          </div>
          <div style={{ display: "flex", marginTop: "1%" }} >
            <div className="maps-tiendas col-md-6 col-12">
              <div className="titulo-tiendas">
                <h4 style={{ color: "black", fontSize: "18px" }}>LIMA</h4>
                <h5 style={{ color: "black", fontSize: "18px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ marginRight: "0.5%" }} fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  </svg>
                  Av. Petit Thouars 1498 - Santa Beatriz
                </h5>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.4786001238385!2d-77.03683028984177!3d-12.079352988110609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c8f34513ad29%3A0x4473b362b7c5c42e!2sAv.%20Petit%20Thouars%201498%2C%20Lima%2015046!5e0!3m2!1ses-419!2spe!4v1685651732760!5m2!1ses-419!2spe" width="100%" height="450" style={{ border: "0px",  padding: "0% 8%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="maps-tiendas col-md-6 col-12">
              <div className="titulo-tiendas">
                <h4 style={{ color: "black", fontSize: "18px" }}>TRUJILLO</h4>
                <h5 style={{ color: "black", fontSize: "18px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ marginRight: "0.5%" }} fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  </svg>
                  Jirón Junin 699 (Esquina Junin y Ayacucho)
                </h5>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.908242929785!2d-79.02630468461476!3d-8.110825183398159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ad3d814dcec311%3A0x4b5efb2e3cd8e7d3!2sCOMPUPLAZA!5e0!3m2!1ses!2spe!4v1618926059193!5m2!1ses!2spe" width="100%" height="450" style={{ border: "0px",  padding: "0% 8%" }} allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Tienda