import React, { useEffect, useState } from 'react';
import { baseUrl, linkServidor } from '../../services/apirest';
import axios from 'axios';
import Swal from 'sweetalert2';

function Propaganda(){

  const [Posicion, setPosicion] = useState('');
  const [Version, setVersion] = useState('');
  const [Descripcion, setDescripcion] = useState('');
  const [Link, setLink] = useState('');
  const [Imagen, setImagen] = useState('');

  const pro = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("dir_img",  Imagen);
    formData.append("posicion",  Posicion);
    formData.append("version",  Version);
    formData.append("descripcion",  Descripcion);
    formData.append("link",  Link);
    let token = localStorage.getItem("token");
    axios({ 
    method: 'post',
      url : `${baseUrl}/propaganda`, 
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      })
      .then((response) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardado con éxito',
          showConfirmButton: false,
          timer: 1500
        });
        e.target.reset();
        console.log(response);
      })
    .catch((err)=>{
      console.log(err);
    });
  };

  return(
    <div className="admin espacio-admin">
      <main className="page-content">
        <h2 className="titulo-admin">MODULO DE PROPAGANDA</h2>
        <div className="tabla-admin">
          <div className="container">
            <form onSubmit={(e) => {pro(e)}}>
              <div class="input-group" style={{ width: "50%", marginBottom: "2%" }}>
                <span class="input-group-text" style={{ background: "transparent" }}>Posición</span>
                <input type="number" aria-label="First name" class="form-control" name="posicion"  onChange={(e) => { setPosicion(e.target.value) }}/>
              </div>
              <div class="input-group" style={{ width: "50%", marginBottom: "2%" }}>
                <span class="input-group-text" style={{ background: "transparent" }}>Versión</span>
                <input type="text" aria-label="First name" class="form-control" name="version"  onChange={(e) => { setVersion(e.target.value) }}/>
              </div>
              <div class="input-group" style={{ width: "50%", marginBottom: "2%" }}>
                <span class="input-group-text" style={{ background: "transparent" }}>Descripción</span>
                <input type="text" aria-label="First name" class="form-control" name="descripcion"  onChange={(e) => { setDescripcion(e.target.value) }}/>
              </div>
              <div class="input-group" style={{ width: "50%", marginBottom: "2%" }}>
                  <span class="input-group-text" style={{ background: "transparent" }}>Link</span>
                <input type="text" aria-label="First name" class="form-control" name="link"  onChange={(e) => { setLink(e.target.value) }}/>
              </div>
              <div class="input-group" style={{ width: "50%", marginBottom: "2%" }}>
                <input type="file" accept="image/*" aria-label="First name" class="form-control" name="dir_img" onChange={(e) => { setImagen(e.target.files[0]) }}/>
              </div>
              <button type="submit" class="btn btn-success">Enviar</button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Propaganda;