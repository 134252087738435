import React, { useState, useEffect } from 'react';
import { baseUrl, linkServidor } from '../../services/apirest';
import axios from 'axios';
import '../../assets/css/sidebar.css';
import '../../assets/css/admin.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Swal from 'sweetalert2';


function Modulo() {
  const [Modulo, setModulo] = useState([]);

  const [IDModulo, setIDModulo] = useState('');
  const [Nombre, setNombre] = useState('');
  const [Descripcion, setDescripcion] = useState('');
  const [Estados, setEstados] = useState(false);

  const getModulo = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/modulo`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setModulo(response.data.data);
      console.log(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const addModulo = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    axios({ 
      method: 'POST',
      url : `${baseUrl}/modulo`, 
      data: JSON.stringify({
        nombre : Nombre,
        descripcion : Descripcion,
        estado : Estados,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getModulo();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const clickModulo = (edit) =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/modulo/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data);
      setIDModulo(response.data.data.id);
      setNombre(response.data.data.nombre);
      setDescripcion(response.data.data.descripcion);
      if(response.data.data.estado == 1){
        setEstados(true);
      }else{
        setEstados(false);
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const editModulo = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    axios({ 
      method: 'PUT',
      url : `${baseUrl}/modulo/${IDModulo}`, 
      data: JSON.stringify({
        nombre : Nombre,
        descripcion : Descripcion,
        estado : Estados,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getModulo();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const componentDidMount = () => {
    $(document).ready(function() {
      $('#example').DataTable();
    });
  }

  useEffect(()=>{
    getModulo();
    componentDidMount();
  }, []);

  return (
    <div style={{ display: "contents" }}>
      <div className="admin espacio-admin">
        <main className="page-content">
          <h2 className="titulo-admin">MODULO DE MODULOS</h2>
          <div className="col-12 button-categoria">
            <button type="button" className="btn btn-inverse-success btn-fw" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Agregar Modulo
            </button>
          </div>
          <div className="tabla-admin">
            <table id="example" datatable className="display">
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ID</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>NOMBRE</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>DESCRIPCIÓN</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ESTADO</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ display: "none" }}> 
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                </tr>
                {Modulo.map((value, index) =>{
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{value.id}</td>
                      <td style={{ textAlign: "center" }}>{value.nombre}</td>
                      <td style={{ textAlign: "center" }}>{value.descripcion}</td>
                      <td style={{ textAlign: "center" }}>{value.estado}</td>
                      <td onClick={() => {clickModulo(value.id)}} style={{ textAlign: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" data-bs-toggle="modal" data-bs-target="#exampleModale" height="21" fill="currentColor" className="bi bi-pencil-square edit-admin" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </main>

        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ borderRadius: "10px" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Agregar Modulo</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form onSubmit={(e) => {addModulo(e)}}>
                <div className="modal-body">
                  <div className="form__group field">
                    <input required="" placeholder="descripcion" className="form__field" type="input" onChange={(e) => { setNombre(e.target.value) }} />
                    <label className="form__label" for="descripcion">Nombre</label>
                  </div>
                  <div className="form__group field">
                    <input required="" placeholder="descripcion" className="form__field" type="input" onChange={(e) => { setDescripcion(e.target.value) }} />
                    <label className="form__label" for="descripcion">Descripción</label>
                  </div>
                  <div className="form__group" style={{ paddingTop: "2%" }}>
                    <input type="checkbox" id="box-1" name="estado" value={Estados} onChange={(e) => { setEstados(e.target.checked) }} />
                    <label className="checkbox-custom-label" for="box-1">Estado</label>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-inverse-success btn-fw">
                    Guardar Cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleModale" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ borderRadius: "10px" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Editar Rol</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form onSubmit={(e) => {editModulo(e)}}>
                <div className="modal-body">
                  <div className="form__group field">
                    <input required="" placeholder="descripcion" className="form__field" type="input" value={Nombre} onChange={(e) => { setNombre(e.target.value) }} />
                    <label className="form__label" for="descripcion">Nombre</label>
                  </div>
                  <div className="form__group field">
                    <input required="" placeholder="descripcion" className="form__field" type="input" value={Descripcion} onChange={(e) => { setDescripcion(e.target.value) }} />
                    <label className="form__label" for="descripcion">Descripción</label>
                  </div>
                  {Estados == true &&
                  <div className="form__group" style={{ paddingTop: "2%" }}>
                    <input type="checkbox" id="box-1" name="estado" value={Estados} checked onChange={(e) => { setEstados(e.target.checked) }}/>
                    <label className="checkbox-custom-label" for="box-1">Estado</label>
                  </div>
                  }
                  {Estados == false &&
                  <div className="form__group" style={{ paddingTop: "2%" }}>
                    <input type="checkbox" id="box-1" name="estado" value={Estados} onChange={(e) => { setEstados(e.target.checked) }} />
                    <label className="checkbox-custom-label" for="box-1">Estado</label>
                  </div>
                  }
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-inverse-success btn-fw">
                    Guardar Cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Modulo