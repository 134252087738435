import React, { useState, useEffect } from 'react';
import { baseUrl } from '../../services/apirest';
import axios from 'axios';
import $ from 'jquery'; 

function Promocion() {

  const [ProductosPromo, setProductosPromo] = useState([]);
  const [IDElegido, setItem] = useState([]);
  const [Identificado, setIdentificadores] = useState([]);
  const [ProductosPromociones, setProductosPromociones] = useState([]);
  const [Date, setDate] = useState('');

  let Elegido = JSON.parse(sessionStorage.getItem('promo'))?JSON.parse(sessionStorage.getItem('promo')):[];
  let Identificador = JSON.parse(sessionStorage.getItem('idpromo'))?JSON.parse(sessionStorage.getItem('idpromo')):[];

  const GetPromo = () =>{
    setItem(Elegido);
    setIdentificadores(Identificador);
  }

  const getPromocionesFlash = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/Promocion`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response.data.data);
      setProductosPromociones(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const onElegirProductos = (e) => {
    const productos = e.target.value;
    if(productos == ''){

    }else{
      let token = localStorage.getItem("token");
      fetch(`${baseUrl}/Promocion/buscadorProductos`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({
          term : productos,
        }),
      })
      .then((response) => response.json())
      .then((json)=> {
        console.log(json.data);
        setProductosPromo(json.data);
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  };

  const unique = (id) => {
    const resultado = Elegido.find( fruta => fruta.ident == id );
    return resultado;
  }

  const agregarProductos = (id, sku) => {
    GetPromo();
    if(!unique(id)){
      Elegido.push({ident: id, skuu : sku });
      Identificador.push(id);
      console.log(Elegido);
      console.log(Identificador);
    }
    sessionStorage.setItem("promo", JSON.stringify(Elegido));
    sessionStorage.setItem("idpromo", JSON.stringify(Identificador));
  }

  const eliminarProducto = (e) =>{
    console.log(e);
    Elegido = Elegido.filter(usuario => usuario.ident != e);
    Identificador = Identificador.filter(usuario => usuario != e);
    GetPromo();
    sessionStorage.setItem("promo", JSON.stringify(Elegido));
    sessionStorage.setItem("idpromo", JSON.stringify(Identificador));
  }

  const SavePromo = (e) => {
    e.preventDefault();

    let token = localStorage.getItem("token");
    fetch(`${baseUrl}/Promocion`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}` 
      },
      body: JSON.stringify({
        fecha : Date,
        productos : Identificador,
      }),
    })
    .then((response) => response.json())
    .then((json)=> {
      console.log(json);
      getPromocionesFlash();
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const componentDidMount = () => {
    $(document).ready(function() {
      $('#example').DataTable();
    });
  }

  useEffect(()=>{
    componentDidMount();
    getPromocionesFlash();
  }, []);

  return (
    <div style={{ display: "contents" }}>
      <div className="admin espacio-admin">
        <main className="page-content">
          <h2 className="titulo-admin">MODULO DE PROMOCIONES</h2>
          <div className="col-12 button-categoria">
            <button type="button" className="btn btn-inverse-success btn-fw" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Agregar Promoción
            </button>
          </div>
          <div className="tabla-admin">
            <table id="example" datatable className="display">
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>SKU</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>NOMBRE</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>FECHA DE EXPIRACION</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ESTADO</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ display: "none" }}> 
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                </tr>
                {ProductosPromociones.map((value, index) =>{
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{value.producto_id}</td>
                      <td style={{ textAlign: "center" }}>{value.descripcion }</td>
                      <td style={{ textAlign: "center" }}>{value.fecha_expiracion}</td>
                      <td style={{ textAlign: "center" }}>{value.estado}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </main>

        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="exampleModalLabel" style={{ margin: "auto", fontSize: "20px", fontWeight: "500" }}>Agregar Promoción</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form onSubmit={(e) => {SavePromo(e)}}>
                <div className="modal-body">
                  <div className='col-12' style={{ display: "flex" }}>
                    <div className='col-6'>
                      <label>Fecha</label>
                      <input className="form-control" required="" name="fecha" type="datetime-local" onChange={(e) => { setDate(e.target.value) }}/>
                    </div>
                  </div>
                  <div className='col-12' style={{ display: "flex", marginTop: "4%" }}>
                    <div className='col-6'>
                      <label>Seleccione</label>
                      <input className="form-control" required="" type="text" placeholder='Buscar productos ...' onChange={(e) => { onElegirProductos(e) }}/>
                      <div>
                        {ProductosPromo.map((value, index) =>{
                          return (
                            <div key={index} className="btn btn-ligth" onClick={()=>agregarProductos(value.id, value.sku)}>
                              <span className="buscador-nombre">{value.sku} </span> 
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className='col-6'>
                      <label>Productos</label>
                      <div id="usuarios" className="form-control" style={{ height: "auto", minHeight: "37px" }}>
                        {Elegido.map((value, index) =>{
                          return (
                            <div className="btn btn-ligth col-6">
                              <span className="buscador-nombre">{value.skuu}
                                <i className="fas fa-times-circle" style={{ marginLeft: "8%" }} onClick={()=>eliminarProducto(value.ident)}></i>
                              </span> 
                            </div> 
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-inverse-success btn-fw" style={{ margin: "0px 2rem" }}>
                    Guardar Cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Promocion