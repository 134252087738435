import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { ecoUrl, linkServidor, linkCuentas } from '../../services/apirest';
import '../../assets/css/App.css';
import Swal from 'sweetalert2';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import bannercategoria from '../../assets/images/general.webp';
import $ from 'jquery'; 

function Busqueda() {

  let { dato } = useParams();
  const [productosApi , setProductosApi] = useState([])
  const [productos , setProductos] = useState([])
  const [filtrosApi , setFiltrosApi] = useState([])
  const [resp_dato , setResp_dato] = useState('')
  const [filtrosSelect , setFiltrosSelect] = useState([])
  const [rango, setRango] = useState(0);
  const [rangoMax, setRangoMax] = useState(0);
  const [ImagendeBusqueda , setImagendeBusqueda] = useState('');
  const [GetallCart, setItem] = useState([]);
  const [Cantidades, setCantidades] = useState();
  const [isActive, setIsActive] = useState(false);

  const [loader, setLoader] = useState(false);
  let item = JSON.parse(sessionStorage.getItem('carrito'))?JSON.parse(sessionStorage.getItem('carrito')):[];
  let cantidad = JSON.parse(sessionStorage.getItem('cantidad'))?JSON.parse(sessionStorage.getItem('cantidad')):0;

  const GetCart = () =>{
    setItem(item);
    setCantidades(cantidad);
  }

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
  };

  const search = () => {
    setLoader(true)
    axios.get(`${ecoUrl}/Store/search/${dato}`)
    .then((response) => {
      console.log(response.data.data);
      setLoader(false);
      setImagendeBusqueda(response.data.data.dat.dir_img);
      setResp_dato(dato);
      setProductosApi(response.data.data.productos);
      setProductos(response.data.data.productos);
      let filtros = response.data.data.fil;
      setFiltrosApi(Object.entries(filtros));
      setRango(response.data.data.filp[0]);
      setRangoMax(response.data.data.filp[0]);
      window.scrollTo(0, 0);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  let filtrosSelects = filtrosSelect;
  const selectFiltro = (check , value) => {
    if(check){
      filtrosSelects.push(value);
    }else{
      filtrosSelects = filtrosSelects.filter((item) => item !== value)
    }
    setFiltrosSelect(filtrosSelects)
    filtrarProds(rango);
  }

  const selectFiltroPrecio = (value) => {
    setRango(value)
    filtrarProds(value);
  }

  const adicionarCarrito = (producto) =>{
    let IDencontrado = false;

    item.map((value, index)=>{
      if(value.id == producto.id){
        IDencontrado = true;
        if (value.cantidad_pd < (producto.stock + producto.stock_lima)) {
          parseInt(cantidad++);
          value.cantidad_pd++;
          GetCart();
          Swal.fire({
            title: 'Producto agregado',
            icon: 'success',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonColor: '#ed571d',
            confirmButtonText: 'Ir al carrito',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonColor: '#0e2f7f',
            cancelButtonText: 'Seguir comprando',
            cancelButtonAriaLabel: 'Thumbs down'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace(`${linkCuentas}/carrito`);
            }
          })
        } else {
          // console.log('Sin stock');
        }
      }
    });

    if(!IDencontrado){
      cantidad = parseInt(cantidad+1);
      // console.log(cantidad);
      const product = { ...producto, cantidad_pd: 1, precio_venta_dp: getPriceProduct(producto)};
      GetCart();
      item.push(product);
      Swal.fire({
        title: 'Producto agregado',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonColor: '#ed571d',
        confirmButtonText: 'Ir al carrito',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonColor: '#0e2f7f',
        cancelButtonText: 'Seguir comprando',
        cancelButtonAriaLabel: 'Thumbs down'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.replace(`${linkCuentas}/carrito`);
        }
      })
    }

    sessionStorage.setItem("carrito", JSON.stringify(item));
    sessionStorage.setItem('cantidad', JSON.stringify(cantidad));
  }

  const getPriceProduct = (producto) =>{
    let price = 0;
    if (producto.precio_activo == 1) {
      price = producto.precio;
    } else if(producto.precio_activo == 2) {
      price = producto.precio_web;
    }else{
      price = producto.precio_prom;
    }
    return price;
  }

  const filtrarProds = (value) => {
    let resultProds = [];
    if(filtrosSelects.length > 0 && value > 0){

    productosApi.forEach(producto => {
      switch (producto.precio_activo) {
        case 1:
          filtrosSelects.forEach(filtro => {
            if(producto.caractericticas.indexOf(filtro) > -1 && parseFloat(producto.precio) <= parseFloat(value) ){
              resultProds.push(producto);
            }
          });
          break;
        case 2:
          filtrosSelects.forEach(filtro => {
            if(producto.caractericticas.indexOf(filtro) > -1 && parseFloat(producto.precio_web) <= parseFloat(value) ){
              resultProds.push(producto);
            }
          });
          break;
        case 3:
          filtrosSelects.forEach(filtro => {
            if(producto.caractericticas.indexOf(filtro) > -1 && parseFloat(producto.precio_prom) <= parseFloat(value) ){
              resultProds.push(producto);
            }
          });
          break;
        case 4:
          filtrosSelects.forEach(filtro => {
            if(producto.caractericticas.indexOf(filtro) > -1 && parseFloat(producto.precio_transf) <= parseFloat(value) ){
              resultProds.push(producto);
            }
          });
          break;
      }
    }); 
    let hash = {};
    resultProds = resultProds.filter(o => hash[o.id] ? false : hash[o.id] = true);
    setProductos(resultProds);
    }else{
      productosApi.forEach(producto => {
        switch (producto.precio_activo) {
          case 1:
            if( parseFloat(producto.precio) <= parseFloat(value) ){
              resultProds.push(producto);
            }
            break;
          case 2:
            if( parseFloat(producto.precio_web) <= parseFloat(value) ){
              resultProds.push(producto);
            }
            break;
          case 3:
            if( parseFloat(producto.precio_prom) <= parseFloat(value) ){
              resultProds.push(producto);
            }
            break;
          case 4:
            if( parseFloat(producto.precio_transf) <= parseFloat(value) ){
              resultProds.push(producto);
            }
            break;
        }
      }); 
      let hash = {};
      resultProds = resultProds.filter(o => hash[o.id] ? false : hash[o.id] = true);
      setProductos(resultProds);
    }
  }
  
  const componentDidMount = () => {
    $('.ir-arriba').click(function(){
      $('body, html').animate({
        scrollTop: '0px'
      }, 0);
    });

    $(window).scroll(function(){
      if( $(this).scrollTop() > 0 ){
        $('.ir-arriba').slideDown(600);
      } else {
        $('.ir-arriba').slideUp(600);
      }
    });
  }

  useEffect(()=>{
    search();
    componentDidMount();
  },[dato]);

  return (
    <div>
      <Navbar/>
      <a className="ir-arriba"  href="#" title="Volver arriba">
        <span className="fa-stack">
          <i className="fa fa-circle fa-stack-2x"></i>
          <i className="fa fa-angle-double-up fa-stack-1x fa-inverse" style={{ fontSize: "30px" }}></i>
        </span>
      </a>
      <div style={{ margin: "0% 5%", padding: "2% 0% 0% 0%" }}>
        {loader == true &&
          <div className="productos-index">
            <div className="card card--skeleton">
              <div className="img-cont" style={{ height: "13rem" }}>
              
              </div>
            </div>
          </div>
        }
        {loader == false &&
          <div>
            {ImagendeBusqueda != undefined &&
              <LazyLoadImage src={linkServidor + '/' + ImagendeBusqueda} width="100%" effect='opacity'/>
            }
            {ImagendeBusqueda == undefined &&
              <LazyLoadImage src={bannercategoria} width="100%" effect='opacity'/>
            }
          </div>
        }
      </div>
      <div>
        {/* <div className='button-filtrar'>
          {filtrosApi.length == 0 ? 
            <p></p> 
            :filtrosApi.map( filtro => { 
              return (
                <div className="col-2" style={{ paddingRight: "1%" }}>
                  <div className="btn-group" style={{ width: "100%" }}>
                    <button type="button" className="btn btn-danger poppins-bold title-filtro">{filtro[0]}</button>
                    <button type="button" className="btn btn-danger dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: "white", borderLeft: "transparent", color: "#ed571d" }}>
                      <span className="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <ul className="dropdown-menu" style={{ padding: '8%' }}>
                      {Object.entries(filtro[1]).map( descFiltro => { return ( 
                        <div>
                          <input type="checkbox" id={`custom-checkbox-${descFiltro[0]}`} name={descFiltro[0]} value={descFiltro[0]} onChange={(e) => { selectFiltro(e.target.checked,e.target.value) }} />
                          <label label className="checkbox-custom-label" style={{ color: "black" }} for={`custom-checkbox-${descFiltro[0]}`}> {descFiltro[0]}</label>
                        </div>
                      )})}
                    </ul>
                  </div>

                  https://codepen.io/Markshall/pen/PoZJRve?editors=1100
                  {JSON.stringify(filtro[1])}
                  {JSON.stringify(Object.entries(filtro[1]))} 
                </div>
              )
            })
          }
          <div className="dropdown col-2">
            <button className="btn btn-secondary dropdown-toggle poppins-bold texto-rango-precio" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Rango de Precios {rango}
            </button>
            {rango >= 0 ? 
              <ul className="dropdown-menu">
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", padding: "10% 10%" }}>
                <input type="range" min="0" max={rangoMax} step="10" value={rango} onChange={(ev) => selectFiltroPrecio(ev.target.value)}// className="rango"
                /> 
                </div>
              </ul>
              :
              <p>No hay filtros F </p> 
            }
          </div>
        </div>  */}

        {/* ESTE ES EL VERDADERO */}
        <div className='filtro-movil'>
          <button className="btn buton-filtro position-fixed" style={{ margin: "2% 0% 0% 1%", zIndex: "1" }} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
            Filtros
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ marginLeft: "5%" }} fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
              <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
            </svg>
          </button>
          <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" style={{ zIndex: "1111" }}>
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ justifyContent: "center", display: "flex", width: "100%", marginLeft: "0px", fontSize: "15px", color: "black" }}>Filtrar por:</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {filtrosApi.length == 0 ? 
                  <p></p> 
                :filtrosApi.map( filtro => { 
                  return (    
                    <div className="accordion">
                      <input type="checkbox" className='busqueda-inpput' id={`flush-${filtro[0]}`} />
                      <label for={`flush-${filtro[0]}`} className="acc-label" style={{ justifyContent: "space-between", display: "flex", paddingLeft: "6%", background: "#f5f5f5" }}>{filtro[0]}</label>
                      <div className="acc-content">
                        {Object.entries(filtro[1]).map( descFiltro => { return ( 
                          <div>
                            <input type="checkbox" id={`check-${descFiltro[0]}`} name={descFiltro[0]} value={descFiltro[0]} onChange={(e) => { selectFiltro(e.target.checked,e.target.value) }} />
                            <label className="checkbox-custom-label" style={{ color: "black", marginBottom: "2%" }} for={`check-${descFiltro[0]}`}> {descFiltro[0]}</label>
                          </div>
                        )})} 
                      </div>
                    </div>
                  )
                  })
                }
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Rango de Precios {rango}
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      {rango >= 0 ? 
                        <div>
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                          <input type="range" min="0" max={rangoMax} step="10" value={rango} onChange={(ev) => selectFiltroPrecio(ev.target.value)}// className="rango"
                          /> 
                          </div>
                        </div>
                        :
                        <p>No hay filtros F </p> 
                      }
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}> 
          <div className='button-filtrar col-2' style={{ width: "100%", padding: "4% 1%" }}>
            {filtrosApi.length == 0 ? 
              <p></p> 
              :filtrosApi.map( filtro => { 
                return (    
                  <div className="accordion">
                    <input type="checkbox" className='busqueda-inpput' id={`flus-${filtro[0]}`} />
                    <label for={`flus-${filtro[0]}`} className="acc-label" style={{ justifyContent: "space-between", display: "flex", paddingLeft: "6%" }}>{filtro[0]}</label>
                    <div className="acc-content">
                      {Object.entries(filtro[1]).map( descFiltro => { return ( 
                        <div>
                          <input type="checkbox" id={`check-${descFiltro[0]}0`} name={descFiltro[0]} value={descFiltro[0]} onChange={(e) => { selectFiltro(e.target.checked,e.target.value) }} />
                          <label className="checkbox-custom-label" style={{ color: "black", marginBottom: "2%" }} for={`check-${descFiltro[0]}0`}> {descFiltro[0]}</label>
                        </div>
                      )})} 
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="col-10 contenedor-productos-principal marco-container">
            <div className='row' style={{ padding: "0px", margin: "0px" }}>
              {productos.length == 0 ? 
              <div style={{ textAlign: "center" }}></div> 
              :productos.map( producto => { return (
                <div className='col-6 col-sm-6 col-md-4 col-lg-3' style={{ marginBottom: "2%" }}>
                  {producto.precio_prom == 1.00 &&
                  <div></div>
                  }
                  {producto.precio_prom != 1.00 &&
                    <div className="contenedor-producto-principal contenedor_icon">
                      <div className="productos-index">
                        <div className='espacionombreindex'>
                          <span className='avertaRegular contenedornombreindexdetalle'>
                            <span className='avertaBold numeronombreindex'>{Math.round((1-(producto.precio_prom / producto.precio))*100)}</span>
                            <div className='avertaBold' style={{ display: "grid", marginLeft: "5%" }}>
                              <span className='porcentajenombreindex'>%</span>
                              <span className='descuentonombreindex'>Dscto</span>
                            </div>
                          </span>
                        </div>
                        <Link to={`/${producto.description.split(" ").join("-")}/${producto.id}/p`}>
                          <LazyLoadImage src={linkServidor + '/' + producto.dir_img_pri} alt="logo" style={{ justifyContent: "center", display: "flex" }} effect='opacity'/>
                        </Link>
                        <div className="panel">
                          <div className="con-tooltip right" style={{marginTop: "10%"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-heart-fill icon_product" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                            </svg>
                            <div className="tooltip ">
                              <p style={{ margin: "0px", textAlign: "center", color: "white" }}>Favorito</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Link to={`/${producto.description.split(" ").join("-")}/${producto.id}/p`} style={{ textDecoration: "none", color: "black" }}>
                          <div className="nombre-producto-principal dinproRegular">
                            {producto.description}
                          </div>
                        </Link>
                        {producto.precio_activo == 3 &&
                          <center>
                            <div className='fondo_precios'>
                              <p>
                                <span className="avertaBold" style={{ fontSize: "18px", background: "#001267", padding: "1% 4%", borderRadius: "10px", color: "white"  }}>S/ {producto.precio_prom}</span>
                              </p>
                              <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                                ONLINE <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {producto.precio_web}</span>
                              </p>
                              <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                                NORMAL <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {producto.precio}</span>
                              </p>
                            </div>
                          </center>
                        }
                        {producto.precio_activo == 2 &&
                          <center>
                            <div className='fondo_precios'>
                              <p>
                                <span className="avertaBold" style={{ fontSize: "18px", background: "#001267", padding: "1% 4%", borderRadius: "10px", color: "white"  }}>S/ {producto.precio_web}</span>
                              </p>
                              <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                                NORMAL <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {producto.precio}</span>
                              </p>
                            </div>
                          </center>
                        }
                        <center>
                          <div className='fondo_precios'>
                            {(producto.stock + producto.stock_lima) == 0 &&
                              <button type="button" className="btn btn-stock" style={{ width: "auto", fontSize: "13px" }} >SIN STOCK</button>
                            }
                            {(producto.stock + producto.stock_lima) != 0 &&
                              <button type="button" className="btn btn-resumen" style={{ width: "auto", fontSize: "13px" }} onClick={()=>adicionarCarrito(producto)}>Agregar al carrito</button>
                            }
                          </div>
                        </center>
                      </div>
                    </div>
                  }
                </div> 
              )})} 
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Busqueda
