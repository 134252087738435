import React from 'react';
import '../../assets/css/agregadosfooter.css';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';

function Privacidad() {

  window.scrollTo(0, 0);
  
  return (
    <div>
      <Navbar/>
        <div className='container'>
          <h2 className="titulo-terminos">Politicas de Privacidad</h2>
          <h5 style={{ marginTop: "3%" }}>Clientes Registrados</h5>
          <p className="concepto-termino">
            Los clientes registrados en Compuplaza Computadoras E.I.R.L., han comunicado voluntariamente sus datos, los cuales se asumen reales 
            y actualizados. Un cliente de Compuplaza Computadoras E.I.R.L. puede dejar de serlo por determinación de Compuplaza Computadoras E.I.R.L. 
            y sin requerir una expresión de causa. Los clientes de Compuplaza Computadoras E.I.R.L. aceptan las presente políticas comerciales con el 
            solo hecho de comprar y/o recibir una factura o boleta de venta de Compuplaza Computadoras E.I.R.L.
          </p>
          <h5>Modificación de Términos y Condiciones</h5>
          <p className="concepto-termino">
            Compuplaza Computadoras E.I.R.L se reserva expresamente el derecho a modificar, actualizar o ampliar en cualquier momento los presentes 
            Términos y Condiciones. Cualquier modificación, actualización o ampliación producida en los presentes Términos y Condiciones será publicada 
            en nuestra página web, siendo entera y exclusiva responsabilidad del Cliente revisar los Términos y Condiciones vigentes. 
            Los cambios serán obligatorios para todos los clientes y rigen desde la fecha de publicación de la nueva versión en la página web 
            <a className="urls" href="https://compuplaza.net.pe/terminosycondiciones">https://compuplaza.net.pe/terminosycondiciones</a>
          </p>
          <h5>Seguridad</h5>
          <p className="concepto-termino">
            Compuplaza Computadoras E.I.R.L. está comprometido en asegurar la seguridad de su información. Para prevenir acceso a la misma o mantener 
            la confiabilidad de los datos y controlar el uso apropiado de la información, hemos puesto en su lugar los procedimientos físicos y 
            electrónicos apropiados para resguardar y salvaguardar la información que recibimos en línea de nuestros clientes.
          </p>
          <h5>¿Para qué solicitamos información?</h5>
          <p className="concepto-termino">
            Solicitamos información personal para poder procesar sus pedidos, a la vez, para que realice una experiencia de compras mejorada, 
            poder informarle de ofertas y descuentos especiales.
          </p>
          <h5>¿Qué información recogemos?</h5>
          <p className="concepto-termino">
            Cuando se realiza un pedido desde nuestro sitio web, obtenemos la siguiente información: nombre, e-mail, número de teléfono, 
            información para facturación (número de tarjeta de crédito o débito) y el nombre, dirección y teléfono del destinatario.
          </p>
          <h5>¿Cómo usamos la información que obtenemos?</h5>
          <p className="concepto-terminos">
            La usamos para procesar sus pedidos, brindar una experiencia de compras mejorada e informarle de ofertas, sorteos  y descuentos especiales 
            a través de correo directo o campañas de email y marketing. 
          </p>
          <p className="concepto-termino bottom" style={{ marginBottom: "6%" }}>
            Su inscripción en nuestra página Web <a className="urls" href="https://compuplaza.net.pe">https://compuplaza.net.pe/</a> y 
            la previa aceptación de nuestras políticas nos autoriza a enviarle correos electrónicos únicamente para actuar según lo detallado en 
            esta sección. No ponemos su información a disposición de terceros (excepto subsidiarias, subcontratistas o agencias actuando en 
            representación nuestra y con respeto a esta política de privacidad).
          </p>
        </div>
      <Footer/>
    </div>
  )
}

export default Privacidad