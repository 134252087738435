import React, { useEffect } from 'react';
import bcp from '../../assets/images/bcp.webp';
import ban from '../../assets/images/ban.webp';
import ya from '../../assets/images/YAPE.webp';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';

function Banco() {

  const getScroll = () => {
    window.scrollTo(0, 0);
  }

  useEffect(()=>{
    getScroll();
  }, []);

  return (
    <div>
      <Navbar/>
      <div className="container">
        <div className="row" style={{ justifyContent: "center"}} >
          <div className="col-12" style={{ fontSize: "35px" }}>
            <p className='titulo-transferencia'>Cuentas Bancarias</p>
          </div>
          <div className="row fila-trans">
            <div className="col-12 col-md-3">
              <div className="border-img-trans">
                <img width="100%" src={bcp} alt="" />
              </div>
              <p className="texto-cuenta-nunmeros">
                <strong className="texto-prueba">BCP SOLES:</strong>
                <strong className="texto-prueba">570-9293323-0-22</strong>
              </p>
              <p className="texto-cuenta-nunmeros">
                <strong className="texto-prueba">CCI:</strong>
                <strong className="texto-prueba">002-570009293323022-06</strong>
              </p>
            </div>
            <div className="col-12 col-md-3">
              <div className="border-img-trans">
                <img width="100%" src={bcp} alt="" />
              </div>
              <p className="texto-cuenta-nunmeros">
                <strong className="texto-prueba">BCP DÓLARES:</strong>
                <strong className="texto-prueba">570-8757113-1-70</strong>
              </p>
              <p className="texto-cuenta-nunmeros">
                <strong className="texto-prueba">CCI:</strong>
                <strong className="texto-prueba">002-570008757113170-02</strong>
              </p>
            </div>
            <div className="col-12 col-md-3">
              <div className="border-img-trans">
                <img width="100%" src={ban} alt="" />
              </div>
              <p className="texto-cuenta-nunmeros">
                <strong className="texto-prueba">BBVA SOLES:</strong>
                <strong className="texto-prueba">0011-0254-0602005456-66</strong>
              </p>
              <p className="texto-cuenta-nunmeros">
                <strong className="texto-prueba">CCI:</strong>
                <strong className="texto-prueba">011-254-000200545666-06</strong>
              </p>
            </div> 
            <div className="col-12 col-md-3">
              <div className="border-img-trans">
                <img width="100%" src={ya} alt="" />
              </div>
              <p className="texto-cuenta-nunmeros">
                <strong className="texto-prueba">YAPE</strong>
              </p>
            </div> 
          </div>   
        </div>
    
        <div className="col-12 subtitulo-transferencia cuentas-bancarias-view">
          <div className="texto-view">
            <p><strong>Si deseas una compra en linea</strong> guiada,</p>
            <p style={{ marginTop: "-5%" }}>comunícate con nuestro asesor.</p>
          </div>
          <div className="whatsapp-view">
            <a href="https://bit.ly/WHATSAPPCOMPUPLAZANEW" target="_blank"><i className="fab fa-whatsapp-square whatsapp-number"></i></a>
          </div>
        </div>
      </div>  
      <Footer/>  
    </div>
  )
}

export default Banco