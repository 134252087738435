import React from 'react';
import '../../assets/css/agregadosfooter.css';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';

function Condiciones() {
  
  window.scrollTo(0, 0);
  
  return (
    <div>
      <Navbar/>
      <div className='container'>
        <h2 className="titulo-terminos">Términos y Condiciones</h2>
        <p className="concepto-terminos">
          En el presente documento se describe los Términos y Condiciones generales aplicables al acceso, uso de los servicios y productos ofrecidos 
          por GRUPO COMPUPLAZA E.I.R.L. en sus locales de venta, así como dentro del sitio 
          <a className="urls" href="https://compuplaza.net.pe">https://compuplaza.net.pe/</a>, sus subdominios y/u otros dominios (urls) relacionados. 
          Cualquier persona que desee adquirir productos o servicios podrá hacerlo sujetándose a los presentes Términos y Condiciones Generales 
          junto con todas las demás políticas y principios que rigen al presente directamente o por referencia. En consecuencia, todas las visitas, 
          todos los contratos y transacciones que se realicen, así como sus efectos jurídicos, quedarán regidos por estas reglas y sometidos a la 
          legislación aplicable en el Perú.
        </p>
        <p className="concepto-termino">
          El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones de GRUPO COMPUPLAZA, 
          previo a su registro como usuario de y/o a la adquisición de productos, quedando sujetos a lo señalado y dispuesto en los presentes 
          Términos y Condiciones.
        </p>
        <h5>Capacidad Legal</h5>
        <p className="concepto-termino">
          Los Servicios sólo están disponibles para personas que tengan capacidad legal para contratar. No podrán utilizar los servicios las personas 
          que no tengan esa capacidad, entre estos los menores de edad o Usuarios que hayan sido suspendidos temporalmente o inhabilitados 
          definitivamente. Los actos que los menores realicen serán responsabilidad de sus padres, tutores o encargados, y por tanto se considerarán 
          realizados por éstos en ejercicio de la representación legal con la que cuentan. Las empresas o entidades y quien registre un Usuario como 
          empresa, deberá tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos y condiciones de este Acuerdo.
        </p>
        <h5>Clientes Registrados</h5>
        <p className="concepto-termino">
          Los clientes registrados en GRUPO COMPUPLAZA E.I.R.L., han comunicado voluntariamente sus datos, los cuales se asumen reales y actualizados. 
          Un cliente de GRUPO COMPUPLAZA E.I.R.L. puede dejar de serlo por determinación de GRUPO COMPUPLAZA E.I.R.L. y sin requerir una expresión de 
          causa. Los clientes de GRUPO COMPUPLAZA E.I.R.L. aceptan las presente políticas comerciales con el solo hecho de comprar y/o recibir una 
          factura o boleta de venta de GRUPO COMPUPLAZA E.I.R.L.
        </p>
        <h5>Modificación de Términos y Condiciones</h5>
        <p className="concepto-termino">
          GRUPO COMPUPLAZA E.I.R.L se reserva expresamente el derecho a modificar, actualizar o ampliar en cualquier momento los presentes 
          Términos y Condiciones. Cualquier modificación, actualización o ampliación producida en los presentes Términos y Condiciones será publicada 
          en nuestra página web, siendo entera y exclusiva responsabilidad del Cliente revisar los Términos y Condiciones vigentes. 
          Los cambios serán obligatorios para todos los clientes y rigen desde la fecha de publicación de la nueva versión en la página web 
          <a className="urls" href="https://compuplaza.net.pe/terminosycondiciones">https://compuplaza.net.pe/terminosycondiciones</a>
        </p>
        <h5>Seguridad</h5>
        <p className="concepto-termino">
          GRUPO COMPUPLAZA E.I.R.L. está comprometido en asegurar la seguridad de su información. Para prevenir acceso a la misma o mantener 
          la confiabilidad de los datos y controlar el uso apropiado de la información, hemos puesto en su lugar los procedimientos físicos y 
          electrónicos apropiados para resguardar y salvaguardar la información que recibimos en línea de nuestros clientes.
        </p>
        <h5>Productos y Precios</h5>
        <p className="termino">
          Los precios y productos ofrecidos en GRUPO COMPUPLAZA E.I.R.L. no son necesariamente los mismos que ofrezcan otros canales de venta, 
          utilizados por GRUPO COMPUPLAZA E.I.R.L., tales como tienda, web, venta a Empresas, publicidad televisada (TV), catálogos u otros, a 
          menos que se señale expresamente en este sitio o en la publicidad que realice GRUPO COMPUPLAZA E.I.R.L. para cada promoción, hasta el 
          momento de recibir una solicitud u orden de compra, la cual solo obligará a GRUPO COMPUPLAZA E.I.R.L. a partir del momento en el que se 
          ha verificado el cumplimiento de las condiciones de validación, es decir haya formado el consentimiento entre las partes de una determinada 
          transacción (conformidad de pago).
        </p>
        <p className="termino">
          Los precios de los productos ofrecidos están expresados en moneda nacional (sol) de manera predeterminada salvo se indique lo contrario. 
          Los precios ofrecidos corresponden exclusivamente al valor del producto ofrecido (Inc. IGV) y no incluyen gastos de transporte, manejo, 
          envío, accesorios que no se describan expresamente, ni ningún otro ítem adicional.
        </p>
        <p className="termino">
          Los productos, colores, marcas y modelos están sujetos a disponibilidad de stock en la tienda o en el canal de venta correspondiente, 
          no habrá lugar a reclamos por una elección errónea del color de un producto. Es responsabilidad del cliente validar las configuraciones de 
          los productos que compra y asegurarse que el desempeño funcional de los mismos cumpla con los requerimientos específicos de cada caso.
        </p>
        <p className="concepto-termino">
          Los precios presentados no incluyen la instalación y/o configuración de los equipos y componentes ofertados y/o ningún servicio adicional y 
          están sujetos a cambios sin previo aviso. Los precios no se pueden combinar con ninguna otra promoción o beneficio adicional. 
          Cualquier modificación en la configuración de los productos ocasiona un cambio de precio. La Configuración del equipo puede variar de 
          acuerdo a los cambios en el mercado tecnológico. El precio no aplica para el equipo que lo sustituya. Los precios y demás condiciones 
          son ofertados bajo el entendimiento mutuo de que el Cliente usará los Productos para su uso propio, domicilio o negocio y que no tiene 
          la intención de comercializarlos. Cada parte será responsable del pago de sus impuestos correspondientes.
        </p>
        <h5>Stock</h5>
        <p className="termino">
          GRUPO COMPUPLAZA E.I.R.L. se encarga de la actualización y la revisión constante de los productos y/o accesorios, no obstante, 
          pueden modificar y descontinuar los productos y/o servicios en cualquier momento sin contraer ninguna responsabilidad frente al cliente.
        </p>
        <p className="concepto-termino">
          Conforme a ello, GRUPO COMPUPLAZA E.I.R.L. se reserva el derecho de no aprobar la solicitud, pedido u orden de compra y en consecuencia 
          de no despachar un producto si este no cuenta con el stock solicitado sin ninguna responsabilidad frente al cliente. 
        </p>
        <h5>Políticas de entrega</h5>
        <p className="concepto-termino">
          Realizada la compra, nuestros plazos de entrega serán en promedio 3 día hábiles en la ciudad de Trujillo (salvo se indique lo contrario) 
          una vez efectuada la cancelación de su pedido. Y en relación de nuestros envíos a otros departamentos el tiempo será como máximo 7 días 
          hábiles hasta la entrega en la agencia de transporte que usted haya seleccionado, cabe precisar que estos plazos están sujetos a la 
          disponibilidad y responsabilidad de las empresas de transporte y/o Courier logístico que utiliza GRUPO COMPUPLAZA E.I.R.L. y por el cual 
          no se tiene responsabilidad alguna. 
        </p>
        <h5>Limitaciones de envío</h5>
        <p className="concepto-termino">
          Cuando se realiza un pedido, se enviará a una dirección designada por el comprador siempre y cuando esa dirección de envío cumpla con las 
          restricciones de envío definidas en el sitio web <a className="urls" href="https://compuplaza.net.pe">https://compuplaza.net.pe/</a> y solicitud 
          u orden de compra. Cuando el envío no se realiza a través de Compuplaza y se realiza mediante un transportista, el riesgo de daño o pérdida 
          de los productos comprados no es responsabilidad de GRUPO COMPUPLAZA E.I.R.L, esta responsabilidad se transfiere a usted al momento de la 
          entrega de los artículos al transportista. Usted es responsable de presentar cualquier reclamo ante transportistas por envíos dañados o 
          perdidos.
        </p>
        <h5>Políticas de Cambios y/o Devoluciones</h5>
        <ul className="politicas-cambios-devoluciones">
          <li className="concepto-terminos">
            ●	GRUPO COMPUPLAZA E.I.R.L. solo acepta devoluciones dentro de las 72 horas posterior a la recepción del producto, previa evaluación y 
            diagnóstico de Servicio Técnico.
          </li>
          <li className="concepto-terminos">
            ●	La solicitud se deberá hacer de manera presencial, se verificará el estado del producto que deberá estar completamente sin uso y en 
            óptimas condiciones, incluyendo manuales y empaque original. Los equipos deberán traer sus cajas, tecnopor, accesorios, en perfectas 
            condiciones como los adquirió, sin ningún daño físico ocasionado por el cliente, en caso de las impresoras NO deberán estar instaladas 
            las tintas, toners o cintas; en caso de los suministros, NO deberán ser abiertos de su empaque original, si no cumplen con estas 
            condiciones, no se acepta la devolución.
          </li>
          <li className="concepto-terminos">
            ●	Se le recomienda al cliente que, al recibir su pedido, verifique que el producto se encuentra en buenas condiciones y con los accesorios 
            listados.
          </li>
          <li className="concepto-terminos">
            ●	GRUPO COMPUPLAZA E.I.R.L. no se responsabiliza por el software u otros datos almacenados en discos duros internos y externos, USB y otros 
            dispositivos de almacenamiento. Se recomienda hacer Backups o copias de seguridad previas a la entrega a nuestro centro de servicio.
          </li>
        </ul>
        <h5>Términos y Condiciones de la Garantía</h5>
        <p className="termino">
          Agradecemos su confianza al adquirir este producto en GRUPO COMPUPLAZA E.I.R.L. y esperamos que esté satisfecho con su compra. 
          En caso su compra presente inconvenientes o fallas, tenga en cuenta lo siguiente:
        </p>
        <ul className="politicas-cambios-devoluciones">
          <li className="concepto-terminos">
            ●	La garantía que brinda GRUPO COMPUPLAZA E.I.R.L cubre sólo los desperfectos originados por  fallas o defectos de fabricación 
            y se aplica solo al cliente de GRUPO COMPUPLAZA E.I.R.L. y no se extiende a terceros, solo se presta en nuestras instalaciones de 
            servicio técnico. Cada producto tiene Garantía, la cual varía de acuerdo con la marca y el fabricante, según información impresa en los 
            documentos de venta que acompañan al producto y/o en los presentes términos y condiciones.
          </li>
          <li className="concepto-terminos">
            ●	La garantía de los equipos de la marca: HP, Lenovo, Asus, Acer, HP, Epson, Canon, Brother, Samsung, LG, Teros, lo cubre el mismo 
            fabricante a través de los CENTROS AUTORIZADOS DE SERVICIO bajo sus propios términos de garantía (Para más información sírvase a 
            ingresar a la página web del fabricante y ponerse en contacto con su área encargada), GRUPO COMPUPLAZA E.I.R.L no tiene responsabilidad 
            ni injerencia alguna en la garantía de estos equipos.
          </li>
          <li className="concepto-terminos">
            ●	La garantía de los equipos o productos que no cuenten con un CENTRO AUTORIZADO DE SERVICIO será cubierto por GRUPO COMPUPLAZA E.I.R.L, 
            para tal fin los productos que estén dentro del periodo de garantía, deben ser entregados con su respectiva fotocopia de la Boleta o 
            Factura de venta emitido GRUPO COMPUPLAZA E.I.R.L.
          </li>
          ●	El Periodo de garantía son:
            <ul className="politicas-cambios">
              <li className="concepto-terminos">
                - Para los productos que cubre la garantía GRUPO COMPUPLAZA EIRL (mano de obra, repuestos o cambio de producto) será de 01 año 
                (12 meses) a partir de la fecha de compra.
              </li>
              <li className="concepto-terminos">
                - Para los productos que cubre la garantía el fabricante a través de sus CENTRO AUTORIZADO DE SERVICIO será hasta 1 año (12 meses) 
                adicional, dependiendo el producto adquirido, a partir del término de la garantía brindada por el fabricante y cubrirá solo el costo 
                de mano de obra.
              </li>
            </ul>   
          ● La garantía tiene las siguientes excepciones y será rechazada cuando:
            <ul className="politicas-cambios">
              <li className="concepto-terminos">
                - El producto presente etiquetas del Fabricante o de GRUPO COMPUPLAZA adulteradas o removida.
              </li>
              <li className="concepto-terminos">
                - El producto muestre evidencia de intento de manipulación, reparación o modificación.
              </li>
              <li className="concepto-terminos">
                - El producto presente daño físico alguno o señales de uso inapropiado.
              </li>
              <li className="concepto-terminos">
                - El producto presente daños causados por fallas eléctricas (integrados quemados o derretidos), sobrecargas, mala instalación, 
                falta de mantenimiento o la presencia de cualquier elemento extraño (ácidos, líquidos, químicos e insectos) así como la corrosión 
                y/o óxido. 
              </li>
            </ul>  
          ● La garantía no cubre:
            <ul className="politicas-cambios">
              <li className="concepto-terminos">
                - Accesorios, conectores, puertos de entrada y salida (paralelo, serial, USB, HDMI etc.), o Cabezales de Impresoras. 
                y así mismo no cubre consumibles o componentes, que se desgasten por el uso del mismo (tintas, cintas, toners, etc.)
              </li>
              <li className="concepto-terminos">
                - Ningún tipo de software instalados en los equipos como computadoras, laptops, tablets, discos duros, usb, etc.
              </li>
              <li className="concepto-terminos">
                - Daños producidos por transporte o mala manipulación de terceros.
              </li>
            </ul>  
          <li>
            ● El proceso de garantía será en un plazo mínimo de 72 horas y un máximo de 30 días calendarios días según la disponibilidad 
            de stock de las partes y piezas, así como la respuesta del fabricante. En caso se necesite exceder el tiempo máximo de atención, 
            GRUPO COMPUPLAZA enviará una comunicación al cliente, sin previa consulta, detallando el motivo del retraso y el plazo adicional 
            para la atención definitiva.
          </li>
          <li className="bottom">
            ● La garantía es aplicable para cambio de producto o remanufacturación (sujeto a validación), en caso no se contase con stock se otorgará 
            un cambio alterno del mismo valor o similar, o se asignará una nota de crédito.
          </li>
        </ul>
      </div>
      <Footer/>
    </div>
  )
}

export default Condiciones