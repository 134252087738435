import React, { useState, useEffect } from 'react';
import { baseUrl, ecoUrl } from '../../services/apirest';
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import IconUser from '../../assets/images/Avatars/iconuser.svg';
import AvatarEstudiantes from '../../assets/images/Avatars/avatarstu.png';
import AvatarProfesional from '../../assets/images/Avatars/avatarpro.png';
import AvatarNormal from '../../assets/images/Avatars/avatarnor.png';
import AvatarGamer from '../../assets/images/Avatars/avatarga.png';
import $ from 'jquery';

function Usuario() {

  const [Identificador, setIdentificador] = useState('');
  const [Usuario, setUsuario] = useState('');
  const [Correo, setCorreo] = useState('');
  const [Apellido, setApellido] = useState('');
  const [Telefono, setTelefono] = useState('');
  const [Direccion, setDireccion] = useState('');
  const [Avatars, setAvatars] = useState('');
  const [Avatar, setAvatar] = useState('');
  
  window.scrollTo(0, 0);

  const getUsuario = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/getUsuario`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      //console.log(response.data.data.usuario.id);
      setIdentificador(response.data.data.usuario.id);
      setUsuario(response.data.data.usuario.name);
      setCorreo(response.data.data.usuario.email);
      setTelefono(response.data.data.usuario.phone);
      setApellido(response.data.data.usuario.lastname);
      setDireccion(response.data.data.usuario.direccion);
      setAvatars(response.data.data.usuario.avatar);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const postUsuario = (e) =>{
    e.preventDefault();
    let token = localStorage.getItem("token");
    fetch(`${baseUrl}/update/${Identificador}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        name : Usuario,
        lastname : Apellido,
        phone : Telefono,
        direccion : Direccion,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      //console.log(json);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const demo = (e) =>{
    const img = e;
    //console.log(img.replace("/static/media/", ""));
    // setAvatar(img.replace("/static/media/", ""));
    // $("#img_cuenta").val(img.replace("/static/media/", ""));
    // console.log($("#img_cuenta").val());
  }

  const addAvatar = (e) =>{
    e.preventDefault();
    // const formData = new FormData();
    // formData.append("avatar", Avatar);
    // let token = localStorage.getItem("token");
    // axios({ 
    //   method: 'post',
    //   url : `${baseUrl}/postAvatar`, 
    //   data: formData,
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     'Authorization': `Bearer ${token}`,
    //   },
    //   })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((err)=>{
    //     console.log(err);
    // });
  }

  const componentDidMount = () => {
    $(".margen_circle").click(function() {
      $(".margen_circle").removeClass("seleccionado");
      $(this).addClass("seleccionado");
    });
  }

  useEffect(()=>{
    getUsuario();
    componentDidMount();
  }, []);

  return (
    <div>
      <Navbar/>
        <div className='container'>
          <div className="jumbotron" style={{ marginTop: "2%" }}>
            <div className="row" style={{ margin: "auto", position: "relative" }}>
              <div id="perfil" className="col-6 col-md-2 foto-cuenta">
                <img className="img-cuenta rounded-circle" src={IconUser}/>
                <div className="lapiz_edit">
                  <button type="button" className="btn_button" data-toggle="modal" data-target="#exampleAvatar">
                    <i className="fas fa-pencil-alt"></i>
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-7 info-user-cuenta">
                <span>HOLA</span>
                <br/>
                <span className="text-uppercase">{Usuario}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className="d-flex align-items-start" style={{ padding: "2% 3% 5%" }}>
            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ width: "15%", textAlign: "center" }}>
              <button className="nav-link active" style={{ margin: "10% 0%" }} id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Cuenta</button>
              <Link to="/pedidos" className="nav-link" style={{ marginBottom: "10%" }} type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Mis Pedidos</Link>
              <button className="nav-link" style={{ marginBottom: "10%" }} id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Cerrar sesión</button>
            </div>
            <div className="tab-content" id="v-pills-tabContent" style={{ width: "100%" }}>
              <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
                <h5 style={{ textAlign:"center" }}>Datos personales</h5>
                <form onSubmit={(e) => {postUsuario(e)}}>
                  <div className='row' style={{ padding: "0% 5%" }}>
                    <div className="col-6 mt-3">
                      <label className='title-name-detalle'>Nombre</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ color: "white" }} fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                          </svg>
                        </span>
                        <input type="text" className="form-control input-detalle" value={Usuario} onChange={(e) => { setUsuario(e.target.value) }}/>
                      </div>
                    </div>
                    <div className="col-6 mt-3">
                      <label className='title-name-detalle'>Apellido</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ color: "white" }} fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                          </svg>
                        </span>
                        <input type="text" className="form-control input-detalle" value={Apellido} onChange={(e) => { setApellido(e.target.value) }}/>
                      </div>
                    </div>
                    <div className="col-6" style={{ marginTop: "3%" }}>
                      <label className='title-name-detalle'>Teléfono</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                            <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                          </svg>
                        </span>
                        <input type="text" className="form-control input-detalle" value={Telefono} onChange={(e) => { setTelefono(e.target.value) }}/>
                      </div>
                    </div>
                    <div className="col-6" style={{ marginTop: "3%" }}>
                      <label className='title-name-detalle'>Correo</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                          </svg>
                        </span>
                        <input type="text" className="form-control input-detalle" value={Correo} readonly/>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <label className='title-name-detalle'>Dirección</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ color: "white" }} fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                          </svg>
                        </span>
                        <input type="text" className="form-control input-detalle" value={Direccion} onChange={(e) => { setDireccion(e.target.value) }}/>
                      </div>
                    </div>
                    <div className='col-12' style={{ marginTop: "3%", justifyContent: "end", alignItems: "center", display: "flex" }}>
                      <button type="submit" className="btn btn-inverse-success btn-fw">Guardar cambios</button>
                    </div>
                  </div>
                </form>

                {/* <hr style={{ margin: "3% 5%" }}/> */}

                {/* <h5 style={{ textAlign:"center" }}>Cambio de contraseña</h5> */}
                {/* <form>
                  <div className='row' style={{ padding: "0% 5%" }}>
                    <div className="col-12 mt-3">
                      <label className='title-name-detalle'>Contraseña actual</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                          </svg>
                        </span>
                        <input type="text" className="form-control input-detalle"/>
                      </div>
                    </div>
                    <div className="col-6" style={{ marginTop: "3%" }}>
                      <label className='title-name-detalle'>Nueva contraseña</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                          </svg>
                        </span>
                        <input type="text" className="form-control input-detalle"/>
                      </div>
                    </div>
                    <div className="col-6" style={{ marginTop: "3%" }}>
                      <label className='title-name-detalle'>Confirmar contraseña</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                          </svg>
                        </span>
                        <input type="text" className="form-control input-detalle"/>
                      </div>
                    </div>
                    <div className='col-12' style={{ marginTop: "3%", justifyContent: "end", alignItems: "center", display: "flex" }}>
                      <button type="button" className="btn btn-inverse-success btn-fw">Cambiar contraseña</button>
                    </div>
                  </div>
                </form> */}
              </div>
              <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabindex="0">
                It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleAvatar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ border: "transparent" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Seleccionar Avatar</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={(e) => {addAvatar(e)}}>
                <div className="modal-body">
                  <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "4% 0% 0%" }} >
                    <input type="image" name='avatar' id='img_cuenta' src='' hidden/>
                    <div className="col-6 col-md-4" style={{ paddingBottom: "2%" }}>
                      <input type="image" className='img-cuenta margen_circle' src={AvatarGamer} value={AvatarNormal} style={{ width: "100%" }} onClick={(e) => { demo(e.target.value) }}/>
                    </div>
                    <div className="col-6 col-md-4" style={{ paddingBottom: "2%" }}>
                      <input type="image" className='img-cuenta margen_circle' src={AvatarNormal} value={AvatarNormal} style={{ width: "100%" }} onClick={(e) => { demo(e.target.value) }}/>
                    </div>
                    <div className="col-6 col-md-4" style={{ paddingBottom: "2%" }}>
                      <input type="image" className='img-cuenta margen_circle' src={AvatarProfesional} value={AvatarProfesional} style={{ width: "100%" }} onClick={(e) => { demo(e.target.value) }}/>       
                    </div>
                    <div className="col-6 col-md-4" style={{ paddingBottom: "2%" }}>
                      <input type="image" className='img-cuenta margen_circle' src={AvatarEstudiantes} value={AvatarEstudiantes} style={{ width: "100%" }} onClick={(e) => { demo(e.target.value) }}/>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-inverse-success btn-fw" style={{ margin: "3% 20% 5% 0%" }}>
                    Actualizar Avatar
                  </button>
                  <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}

export default Usuario