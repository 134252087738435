import React from 'react';
import {Link} from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import bolsa from '../../assets/images/Footer/bolsa.webp';
import entrega from '../../assets/images/Footer/entrega.webp';
import medalla from '../../assets/images/Footer/medalla.webp';
import tienda from '../../assets/images/Footer/tienda.webp';
import telefono from '../../assets/images/Footer/telefono.webp';
import location from '../../assets/images/Footer/location.webp';
import correo from '../../assets/images/Footer/correo.webp';
import whatsapp from '../../assets/images/Footer/whatsapp.webp';
import logoblanco from '../../assets/images/Footer/logoblanco.webp';
import redinsta from '../../assets/images/Footer/redinsta.webp';
import redfb from '../../assets/images/Footer/redfb.webp';
import redwsp from '../../assets/images/Footer/redwsp.webp';
import metodo from '../../assets/images/Footer/metodosdepago.webp';
import youtube from '../../assets/images/Footer/youtube.webp';
import tiktok from '../../assets/images/Footer/tiktok.webp';

import comprayretira from '../../assets/images/footer2/comprayretira.webp';
import comprafacilrapido from '../../assets/images/footer2/comprafacilrapido.webp';
import webp from '../../assets/images/footer2/webp.webp';
import merjoecnologia from '../../assets/images/footer2/merjoecnologia.webp';

function Footer(){
  return(
    <div>
      <div>
        <div className="servicios-index">
          <div className="col-lg-6 col-md-12 servicios-footer-index">
            <div className="col-6">
              <LazyLoadImage width="100%" src={comprayretira} alt="logo" effect='opacity'/>
            </div>
            <div className="col-6">
              <LazyLoadImage width="100%" src={comprafacilrapido} alt="logo" effect='opacity'/>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 servicios-footer-index">
            <div className="col-6">
              <LazyLoadImage width="100%" src={merjoecnologia} alt="logo" effect='opacity'/>
            </div>
            <div className="col-6">
              <LazyLoadImage width="100%" src={webp} alt="logo" effect='opacity'/>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="col-12" style={{ display: "flex", marginBottom: "3%" }}>
        <div className='col-3' style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
          <img src={footerimg} alt="logo" width="90%"/>
        </div>
        <div className='col-3'>
          <img src={footerimg} alt="logo" width="90%"/>
        </div>
        <div className='col-3'>
          <img src={footerimg} alt="logo" width="90%"/>
        </div>
        <div className='col-3'>
          <img src={footerimg} alt="logo" width="90%"/>
        </div>
      </div> */}

      {/* <div className="contenedor-footer">
        <div className='row footer-tira'>
          <div className="col-md-12 col-lg-3 tirafooter-index espacio-arriba">
            <img src={bolsa} alt="logo"/>
            <p className="texto-tira-index dinproRegular">
              <strong>Compra fácil, rápido y seguro</strong> en 
              <span className="subtexto-tira-index dinproRegular"> solo 4 simples pasos.</span>  
            </p>
          </div>
          <div className="col-md-12 col-lg-3 tirafooter-index espacio-arriba">
            <img src={tienda} alt="logo"/>
            <p className="texto-tira-index dinproRegular">
              <strong>Retiro en Tienda.</strong>
              <span className="subtexto-tira-index dinproRegular"> Separa online tu producto y retira en tienda.</span>  
            </p>
          </div>
        </div>
        <div className='row footer-tira'>
          <div className="col-md-12 col-lg-3 tirafooter-index">
            <img src={medalla} alt="logo"/>
            <p className="texto-tira-index dinproRegular">
              <strong>Brindamos hasta 3 años </strong>
              <span className="subtexto-tira-index dinproRegular"> de garantía para proteger tus compras.</span>
            </p>
          </div>
          <div className="col-md-12 col-lg-3 tirafooter-index">
            <img src={entrega} alt="logo"/>
            <p className="texto-tira-index dinproRegular" style={{ marginBottom: "3%" }}>
              <strong>Lo mejor en tecnología,</strong>
              <span className="subtexto-tira-index dinproRegular"> al mejor precio, hasta la puerta de tu casa.</span>
            </p>
          </div>
        </div>
      </div> */}


      <div className="footer">
        <div className="col-lg-3 costado">
          <h6 className="horatencion-footer-politica dinpro">Contáctanos</h6>
          <div style={{ display: "flex", marginLeft: "5%", marginBottom: "3%" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" style={{ marginTop: "2%", color: "white" }} fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
            </svg>
            <div style={{ display: "grid", marginLeft: "2%", lineHeight: "1.4", marginTop: "1%", color: "white" }}>
              <span style={{ fontWeight: "bolder" }}>Chatea con nosotros</span>
              <span>Atendemos las 24 Horas al 946 348 625</span>
            </div>
          </div>

          <div style={{ display: "flex", marginLeft: "5%", marginBottom: "3%" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style={{ marginTop: "2%", color: "white" }} fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
            </svg>
            <div style={{ display: "grid", marginLeft: "2%", lineHeight: "1.4", marginTop: "1%", color: "white" }}>
              <span style={{ fontWeight: "bolder", marginBottom: "2%" }}>Escríbenos</span>
              <div style={{ display: "grid", lineHeight: "1" }}>
                <span>Estamos para ayudarte</span>
                <span>ventasonline@compuplaza.net.pe</span>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", marginLeft: "5%", marginBottom: "3%" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" style={{ marginTop: "2%", color: "white" }} fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
            </svg>
            <div style={{ display: "grid", marginLeft: "2%", lineHeight: "1.4", marginTop: "1%", color: "white" }}>
              <span style={{ fontWeight: "bolder", marginBottom: "2%" }}>Llámanos al (044) 243081</span>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <h6 className="horatencion-footer-politica dinpro">Ubícanos</h6>
          <div style={{ display: "flex", marginLeft: "5%", marginBottom: "3%" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" style={{ marginTop: "2%", color: "white" }} fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
              <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
            </svg>
            <div style={{ display: "grid", marginLeft: "2%", lineHeight: "1.4", marginTop: "1%", color: "white" }}>
              <span style={{ fontWeight: "bolder", marginBottom: "2%" }}>Ventas por Whatsapp</span>
              <div style={{ display: "grid", lineHeight: "1" }}>
                <span><a href='https://bit.ly/compuplaza1' target="_blank" className='telefonos-web'>946 348 625</a> / <a href='https://bit.ly/compuplaza2' target="_blank" className='telefonos-web'>947 830 370</a></span>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", marginLeft: "5%", marginBottom: "3%" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" style={{ marginTop: "2%", color: "white" }} fill="currentColor" class="bi bi-shop-window" viewBox="0 0 16 16">
              <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5m2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5"/>
            </svg>
            <div style={{ display: "grid", marginLeft: "2%", lineHeight: "1.4", marginTop: "1%", color: "white" }}>
              <span style={{ fontWeight: "bolder", marginBottom: "2%" }}>Visítanos</span>
              <div style={{ display: "grid", lineHeight: "1.5" }}>
                <p style={{ margin: "0px" }}><span>Lima:</span> Av. Petit Thouars 1498 - Santa Beatriz - Lima</p>
                <p style={{ margin: "0px" }}><span>Trujillo:</span> Jirón Junin 699 - Centro</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <h6 className="horatencion-footer-politica dinpro">Compra con Confianza</h6>
          <div className='espaciofooter'>
            <Link to="/nosotros" className="paginas-footer dinproRegular">
              <span> &#9679; Acerca de Nosotros</span>
            </Link>
            <Link to="/cotizaciones" className="paginas-footer dinproRegular">
              <span> &#9679; Cotizaciones</span>
            </Link>
            <Link to="/tienda" className="paginas-footer dinproRegular">
              <span> &#9679; Nuestra Tienda</span>
            </Link>
            <Link to="/cuentas" className="paginas-footer dinproRegular">
              <span> &#9679; Cuentas Bancarias</span>
            </Link>
            <Link to="/manual" className="paginas-footer dinproRegular">
              <span> &#9679; Manual del Comprador</span>
            </Link>
            <Link to="/preguntasfrecuentes" className="paginas-footer dinproRegular">
              <span> &#9679; Preguntas Frecuentes</span>
            </Link>
            <Link to="/privacidad" className="paginas-footer dinproRegular">
              <span> &#9679; Políticas de Privacidad</span>
            </Link>
            <Link to="/devoluciones" className="paginas-footer dinproRegular">
              <span> &#9679; Políticas de Devoluciones</span>
            </Link>
            <Link to="/terminosycondiciones" className="paginas-footer dinproRegular">
              <span> &#9679; Términos y Condiciones</span>
            </Link>
          </div>
        </div>

        <div className="col-lg-3">
          <h6 className="horatencion-footer-politica dinpro">Síguenos</h6>
          <div className="iconitos-footer">
            <a href="https://www.instagram.com/compuplazaperu/" target="_blank" style={{ marginRight: "3%" }}>
              <img src={redinsta} alt="logo" />
            </a>
            <a href="https://www.facebook.com/CompuplazaPeru" target="_blank" style={{ marginRight: "3%" }}>
              <img src={redfb} alt="logo" />
            </a>
            <a href='https://bit.ly/WHATSAPPCOMPUPLAZANEW' target="_blank" style={{ marginRight: "3%" }}>
              <img src={redwsp} alt="logo" />
            </a>
            <a href='https://www.youtube.com/@compuplazaperu2885' target="_blank" style={{ marginRight: "3%" }}>
              <img src={youtube} alt="logo" />
            </a>
            <a href='https://www.tiktok.com/@compuplazaperu' target="_blank" style={{ marginRight: "3%" }}>
              <img src={tiktok} alt="logo" />
            </a>
          </div>

          <div className="metododepago-footer">
            <img src={metodo} alt="logo" />
          </div>

          <div className="trabaja-footer">
            {/* <Link to="/trabajo" style={{ textDecoration: "none" }}>
              <p className='poppins-bold dinproRegular'>¡TRABAJA CON NOSOTROS!</p>
            </Link> */}
            <a style={{ textDecoration: "none" }}>
              <p className='poppins-bold dinproRegular'>¡TRABAJA CON NOSOTROS!</p>
            </a>
          </div>
        </div>

        {/* <div className="col-md-12 col-lg-6 footer-contacto">
          <div className="row container-telefonos">
            <div className="col-sm-6 col-md-6 container1-footer-index">
              <img className='icono-footer-info' src={telefono} alt="logo" width='10%'/>
              <p className="texto-footer-index dinproLigth">Central Telefónica<br></br>
                <span className="subtexto-footer-index dinpro">(044) 243081</span>
              </p>
            </div>
            <div className="col-sm-6 col-md-6 container3-footer-index">
              <img src={whatsapp} alt="logo" />
              <p className="texto-footer-index dinproLigth">Ventas por Whatsapp<br></br>
                <span className="subtexto-footer-index dinpro">946 348 625 / 947 830 370</span>
              </p>
            </div>
          </div>
          <div className="row container-telefonos">
            <div className="col-sm-6 col-md-6 container2-footer-index">
              <img className='icono-footer-info' src={correo} alt="logo" width='10%'/>
              <p className="texto-footer-index dinproLigth">Ventas Online<br></br>
                <span className="subtexto-footer-index dinpro">ventasonline@compuplaza.net.pe</span>
              </p>
            </div>
            <div className="col-sm-6 col-md-6 container4-footer-index">
              <img className='icono-footer-info' src={location} alt="logo" width='7%'/>
              <div className="direcciones-footer-index">
                <p className="texto-footer-index dinproLigth"><span className='dinpro'>Lima:</span> Av. Petit Thouars 1498 - Santa Beatriz - Lima</p>
                <p className="texto-footer-index dinproLigth"><span className='dinpro'>Trujillo:</span> Jirón Junin 699 - Centro</p>
              </div>
            </div>
          </div>
          <div className="row footer-logo-index">
            <div className="col-sm-6 col-md-6 loguito-footer">
              <img src={logoblanco} alt="logo" />
            </div>
            <div className="col-sm-6 col-md-6 icon-footer">
              <p className="sigueno-footer dinproRegular">Síguenos en:</p>
              <div className="iconitos-footer">
                <a href="https://www.instagram.com/compuplazaperu/" target="_blank" style={{ marginRight: "3%" }}>
                  <img src={redinsta} alt="logo" />
                </a>
                <a href="https://www.facebook.com/CompuplazaPeru" target="_blank" style={{ marginRight: "3%" }}>
                  <img src={redfb} alt="logo" />
                </a>
                <a href='https://bit.ly/WHATSAPPCOMPUPLAZANEW' target="_blank" style={{ marginRight: "3%" }}>
                  <img src={redwsp} alt="logo" />
                </a>
                <a href='https://www.youtube.com/@compuplazaperu2885' target="_blank" style={{ marginRight: "3%" }}>
                  <img src={youtube} alt="logo" />
                </a>
                <a href='https://www.tiktok.com/@compuplazaperu' target="_blank" style={{ marginRight: "3%" }}>
                  <img src={tiktok} alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-md-12 col-lg-6 footer-enlaces">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3 acerca-nosotros">
              <Link to="/nosotros" className="paginas-footer dinproRegular">
                <span> &#9679; Acerca de Nosotros</span>
              </Link>
              <Link to="/cotizaciones" className="paginas-footer dinproRegular">
                <span> &#9679; Cotizaciones</span>
              </Link>
              <Link to="/tienda" className="paginas-footer dinproRegular">
                <span> &#9679; Nuestra Tienda</span>
              </Link>
              <Link to="/cuentas" className="paginas-footer dinproRegular">
                <span> &#9679; Cuentas Bancarias</span>
              </Link>
              <Link to="/manual" className="paginas-footer dinproRegular">
                <span> &#9679; Manual del Comprador</span>
              </Link>
              <Link to="/preguntasfrecuentes" className="paginas-footer dinproRegular">
                <span> &#9679; Preguntas Frecuentes</span>
              </Link>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 compra-confianza">
              <h6 className="title-footer-politica poppins-bold">COMPRA CON CONFIANZA</h6>
              <Link to="/privacidad" className="poli-footer dinproRegular">
                <span> &#9679; Políticas de Privacidad</span>
              </Link>
              <Link to="/devoluciones" className="poli-footer dinproRegular">
                <span> &#9679; Políticas de Devoluciones</span>
              </Link>
              <Link to="/terminosycondiciones" className="poli-footer dinproRegular">
                <span> &#9679; Términos y Condiciones</span>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6 atencionfooter-index">
              <h6 className="horatencion-footer-politica dinpro">CONTÁCTANOS</h6>
              <div className="hora-atencion-footer">
                <span className="numeroatencion-footer dinproRegular">Atendemos <br></br> las 24 horas al <br></br> <a href='https://bit.ly/compuplaza1' target="_blank" style={{ marginLeft: "2%", color: "white", display: "contents" }}>946 348 625</a></span>
                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 metododepago-footer">
              <img src={metodo} alt="logo" />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 trabaja-footer">
              <Link to="/trabajo" style={{ textDecoration: "none" }}>
                <p className='poppins-bold dinproRegular'>¡TRABAJA CON NOSOTROS!</p>
              </Link>
              <a style={{ textDecoration: "none" }}>
                <p className='poppins-bold dinproRegular'>¡TRABAJA CON NOSOTROS!</p>
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;