import React from 'react';
import '../../assets/css/agregadosfooter.css';
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import nosotros from '../../assets/images/BannerNosotros.png';

function Nosotros() {

  window.scrollTo(0, 0);
  
  return(
    <div>
      <Navbar/>
      <img width='100%' src={nosotros}/>
      <div className='container nosotros'>
        <h2 className='title-nosotros'>Bienvenidos a Compuplaza</h2>
        <p className='text-content'>
          En COMPUPLAZA podrás comprar en línea de forma fácil y segura la mayor variedad de productos tecnológicos 
          dentro de las categorías: Computación, Gamer, Oficina, Hogar, Periféricos, Tablets, Suministros y mucho más.
        </p>
        <p className='text-content'>
          Somos una empresa 100% peruana que desde hace más de 18 años brindamos soluciones de alta calidad, 
          a costos muy competitivos, con un completo servicio de garantía de compra y en post venta.
        </p>
        <p className='text-content'>
          Nuestro amplio portafolio de soluciones tecnológicas está respaldado por personal propio altamente calificado, 
          certificado y experimentado en cada rubro, además, contamos con la representación y respaldo de las mejores marcas TI del mercado.
        </p>
        <hr/>
        <div className='nosotros-contacto'>
          <a style={{ textDecoration: "none" }} href="https://compuplaza.net.pe/contacto">
            <button className="btn-subir">Contáctanos</button>
          </a>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Nosotros;