import React from 'react';
import telefono from '../../assets/images/Footer/telefono.webp';
import location from '../../assets/images/Footer/location.webp';
import correo from '../../assets/images/Footer/correo.webp';
import whatsapp from '../../assets/images/Footer/whatsapp.webp';
import logoblanco from '../../assets/images/Footer/logoblanco.webp';
import redinsta from '../../assets/images/Footer/redinsta.webp';
import redfb from '../../assets/images/Footer/redfb.webp';
import redwsp from '../../assets/images/Footer/redwsp.webp';
import youtube from '../../assets/images/Footer/youtube.webp';
import tiktok from '../../assets/images/Footer/tiktok.webp';

function Footer(){
  return(
    <div className="footer">
      <div className="col-md-12 col-lg-6 footer-contacto">
        {/*  */}
        <div className="row footer-logo-index">
          <div className="col-sm-6 col-md-6 loguito-footer">
            <img src={logoblanco} alt="logo" />
          </div>
          <div className="col-sm-6 col-md-6 icon-footer">
            <p className="sigueno-footer dinproRegular">Síguenos en:</p>
            <div className="iconitos-footer" style={{ display: "contents" }}>
              <a href="https://www.instagram.com/compuplazaperu/" target="_blank" style={{ marginRight: "3%" }}>
                <img src={redinsta} alt="logo" />
              </a>
              <a href="https://www.facebook.com/CompuplazaPeru" target="_blank" style={{ marginRight: "3%" }}>
                <img src={redfb} alt="logo" />
              </a>
              <a href='https://bit.ly/WHATSAPPCOMPUPLAZANEW' target="_blank" style={{ marginRight: "3%" }}>
                <img src={redwsp} alt="logo" />
              </a>
              <a href='https://www.youtube.com/@compuplazaperu2885' target="_blank" style={{ marginRight: "3%" }}>
                <img src={youtube} alt="logo" />
              </a>
              <a href='https://www.tiktok.com/@compuplazaperu' target="_blank" style={{ marginRight: "3%" }}>
                <img src={tiktok} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-lg-6 footer-enlaces">
        <div className="row container-telefonos container-corporativo">
          <div className="col-sm-12 col-md-6 container2-footer-index container2-footer-corporativo">
            <img className='icono-footer-info-corporativo' src={correo} alt="logo"/>
            <p className="texto-footer-index dinproLigth" style={{ display: "grid" }}>Ventas Online<br></br>
              <span className="subtexto-footer-index dinpro">ventas@compuplaza.net.pe</span>
              <span className="subtexto-footer-index dinpro">ventascorporativas@compuplaza.net.pe</span>
              <span className="subtexto-footer-index dinpro">ventascorporativas3@compuplaza.net.pe</span>
              <span className="subtexto-footer-index dinpro">ventascorporativas4@compuplaza.net.pe</span>
              <p className="texto-footer-index dinproLigth" style={{ display: "grid", marginLeft: "0px", marginTop: "2%" }}>Jefatura de ventas<br></br>
                <span className="subtexto-footer-index dinpro">ventascorporativas2@compuplaza.net.pe</span>
              </p>
            </p>
          </div>
          <div className="col-sm-12 col-md-6" style={{ padding: "0px" }}>
            <div className="container3-footer-index container1-footer-index-corporativo" style={{ width: "100%" }}>
              <img className='icono-footer-info-corporativo' src={whatsapp} alt="logo" />
              <p className="texto-footer-index dinproLigth" style={{ display: "grid" }}>Ventas por Whatsapp<br></br>
                <span className="subtexto-footer-index dinpro">960 560 731 / 966 580 600</span>
                <span className="subtexto-footer-index dinpro">922 682 564 / 920 463 851</span>
              </p>
            </div>
            <div className='container4-footer-index container4-footer-corporativo'>
              <img className='icono-footer-info-corporativo' src={location} alt="logo"/>
              <div className="direcciones-footer-index">
                <p className="texto-footer-index dinproLigth"><span className='dinpro'>Lima:</span> Av. Petit Thouars 1498 - Santa Beatriz</p>
                <p className="texto-footer-index dinproLigth"><span className='dinpro'>Trujillo:</span> Mza. C Lote 12B Urb. San Jose de California</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row footer-logo-index">
          <div className="col-sm-12 col-md-6 col-lg-6 metododepago-footer">
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 trabaja-footer">
            <a style={{ textDecoration: "none" }}>
              <p className='poppins-bold dinproRegular'>¡TRABAJA CON NOSOTROS!</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;