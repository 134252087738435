import React, { useEffect } from 'react';
import logo from '../../assets/images/Logos/prin-logo.webp';
import logo2 from '../../assets/images/corporativo/LOGOCP3.webp';
import { Link } from "react-router-dom";
import $ from 'jquery';

function NavbarCorporativo(){

  const componentDidMount = () => {
    $( "#registromodal" ).click(function() { 
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    })
    
    window.addEventListener("click", function(e){
      const detectarclass = e.target.classList.contains('offcanvas-backdrop');
      if(detectarclass == true){
        $("#close").click();
      }
      if(detectarclass == true){
        $("#closemovil").click();
      }

      const clases = e.target.classList.contains('modal');
      if(clases == true){
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      }
    })
  }

  useEffect(()=>{
    componentDidMount();
  }, []);
  
  return(
    <div className="header-principal">
      <div className="card-header" style={{ height: "100px" }}>
        <ul className="nav nav-tabs card-header-tabs" id="bologna-list" role="tablist">
          <li className="nav-item">
            <Link to={`/`} className="nav-link dinproRegular" style={{ background: "#0e2f7f", color: "white" }}>Tienda online</Link>
          </li>
        </ul>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className='container navbar_corporativo_flotante'>
          <div className='col-3 contorno_logo_corporativo'>
            <img src={logo2} className='logo_corporativo'></img>
          </div>
          
          <div className='col-8 menu_corporativo_movil'>
            <div class="dropdown">
              <a class="btn btn-corporativo-movil" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: "white" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                </svg>
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item navbar_corporativo" href="#scrollspyHeading1">Nosotros</a></li>
                <li><a class="dropdown-item navbar_corporativo" href="#scrollspyHeading4">Servicios</a></li>
                <li><a class="dropdown-item navbar_corporativo" href="#scrollspyHeading2">Formulario</a></li>
                <li><a class="dropdown-item navbar_corporativo" href="#scrollspyHeading3">Contáctanos</a></li>
              </ul>
            </div>
          </div>

          <div className='col-8 menu_corporativo'>
            <div className='col-3' style={{ justifyContent: "center", display: "flex", alignItems: "center", fontSize: "17px", color: "white" }}>
              <a href="#scrollspyHeading1" className='navbar_corporativo'>Nosotros</a>
            </div>
            <div className='col-3' style={{ justifyContent: "center", display: "flex", alignItems: "center", fontSize: "17px", color: "white" }}>
              <a href="#scrollspyHeading4" className='navbar_corporativo'>Servicios</a>
            </div>
            <div className='col-3' style={{ justifyContent: "center", display: "flex", alignItems: "center", fontSize: "17px", color: "white" }}>
              <a href="#scrollspyHeading2" className='navbar_corporativo'>Formulario</a>
            </div>
            <div className='col-3' style={{ justifyContent: "center", display: "flex", alignItems: "center", fontSize: "17px", color: "white" }}>
              <a href="#scrollspyHeading3" className='navbar_corporativo'>Contáctanos</a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="navbar-logo activo-navbar" style={{ height: "70px" }}>
        <div className="col-3 cajon-logo">
          <Link to={`/`} style={{ display: "flex"}}>
            <img src={logo} className="logo" alt="logo" width='100%' />
          </Link>
          <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" className='barramenu'>
            <div className="menu-icon menu-icon-3">
              <div className="bar bar-1"></div>
              <div className="bar bar-2"></div>
              <div className="bar bar-3"></div>
            </div>
            <span className='stylomenu dinpro'>Menú</span>
          </a>
        </div>
        <div className="col-6 caja-buscador-header">
        </div>
        <div className="col-3 enlaces-navbar">
        </div>
      </div> */}

      {/* <div className="navbar-logo activo-navbar-secun">
        <div className="col-5 cajon-logo">
          <Link to={`/`} style={{ display: "flex"}}>
            <img src={logo} className="logo" alt="logo" width='100%' />
            <img src={logo2} className="logo2" alt="logo" width='100%' />
          </Link>
          <a data-bs-toggle="offcanvas" href="#ExampleMovil" role="button" aria-controls="ExampleMovil" className='barramenumovil'>
            <div className="menu-icon menu-icon-3">
              <div className="bar bar-1"></div>
              <div className="bar bar-2"></div>
              <div className="bar bar-3"></div>
            </div>
            <span className='stylomenu dinpro'>Menú</span>
          </a>
          <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" className='barramenu'>
            <div className="menu-icon menu-icon-3">
              <div className="bar bar-1"></div>
              <div className="bar bar-2"></div>
              <div className="bar bar-3"></div>
            </div>
            <span className='stylomenu dinpro'>Menú</span>
          </a>
        </div>
        <div className="col-7 caja-buscador-header">
        </div>
      </div> */}

      {/* SIDEBAR DE LAS CATEGORIAS */}
      {/* <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">¡Hola!</h5>
          <button type="button" className="btn-close" id='close' data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="menu">
            <p>Nosotros</p>
            <p>Formulario</p>
            <p>Contactanos</p>
          </ul>          
        </div>
      </div>

      <div className="offcanvas offcanvas-start" tabindex="-1" id="ExampleMovil" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">¡Hola!</h5>
          <button type="button" className="btn-close" id='closemovil' data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <p>Nosotros</p>
          <p>Formulario</p>
          <p>Contactanos</p>
        </div>
      </div> */}

    </div>
  );
}

export default NavbarCorporativo;