import React from 'react';
import '../src/assets/css/App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtecteRoute';
import ProtecteRoute from './components/ProtecteRoute';
import Inicio from './components/tienda/Inicio';
import Nosotros from './components/tienda/Nosotros';
import PreguntasFrecuentes from './components/tienda/PreguntasFrecuentes';
import Privacidad from './components/tienda/Privacidad';
import Devoluciones from './components/tienda/Devoluciones';
import Condiciones from './components/tienda/Condiciones';
import DetalleProducto from './components/tienda/DetalleProducto';
import Carrito from './components/tienda/Carrito';
import DetalleVentas from './components/tienda/DetalleVentas';
import Busqueda from './components/tienda/Busqueda';
import Usuario from './components/tienda/Usuario';
import Pedidos from './components/tienda/Pedidos';
import Cotizador from './components/tienda/Cotizador';
import Tienda from './components/tienda/Tienda';
import Banco from './components/tienda/Banco';
import Manual from './components/tienda/Manual';
import DetalledeCompra from './components/tienda/DetalledeCompra';
import Reclamaciones from './components/tienda/Reclamaciones';
// import Trabajo from './components/tienda/trabajo';
import Corporativo from './components/tienda/corporativo';
import Contacto from './components/tienda/Contacto';

import Home from './components/admin/Home';
import Dashboard from './components/admin/Dashboard';
import Categorias from './components/admin/Categorias';
import Subcategoria from './components/admin/Subcategoria';
import Etiqueta from './components/admin/Etiqueta';
import Marca from './components/admin/Marca';
import Producto from './components/admin/Productos';
import Banner from './components/admin/Banner';
import Pedido from './components/admin/Pedido';
import Cupones from './components/admin/Cupones';
import Rol from './components/admin/Rol';
import Modulo from './components/admin/Modulo';
import Promocion from './components/admin/Promocion';
import Blanco from './components/admin/Blanco';
import Propaganda from './components/admin/Propaganda';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Inicio/>}></Route>
        <Route path="/nosotros" element={<Nosotros/>}></Route>
        <Route path="/preguntasfrecuentes" element={<PreguntasFrecuentes/>}></Route>
        <Route path="/manual" element={<Manual/>}></Route>
        <Route path="/privacidad" element={<Privacidad/>}></Route>
        <Route path="/devoluciones" element={<Devoluciones/>}></Route>
        <Route path="/terminosycondiciones" element={<Condiciones/>}></Route>
        <Route path="/:detalleName/:detalleId/p" element={<DetalleProducto/>}></Route>
        <Route path="/search/:dato" element={<Busqueda/>}></Route>
        <Route path="/carrito" element={<Carrito/>}></Route>
        <Route path="/venta" element={<DetalleVentas/>}></Route>
        <Route path="/cotizaciones" element={<Cotizador/>}></Route>
        <Route path="/tienda" element={<Tienda/>}></Route>
        <Route path="/cuentas" element={<Banco/>}></Route>
        <Route path="/detalledecompra" element={<DetalledeCompra/>}></Route>
        <Route path="/reclamo" element={<Reclamaciones/>}></Route>
        {/* <Route path="/trabajo" element={<Trabajo/>}></Route> */}
        <Route path="/corporativo" element={<Corporativo/>}></Route>
        <Route path="/contacto" element={<Contacto/>}></Route>
        <Route path="/perfil" element={
          <ProtecteRoute>
            <Usuario/>
          </ProtecteRoute>
        }></Route>
        <Route path="/pedidos" element={
          <ProtecteRoute>  
            <Pedidos/>
          </ProtecteRoute>
        }></Route>
      </Routes>
      <Routes> 
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <Dashboard/>
          </ProtectedRoute>
          // <Dashboard/>
        }>
          <Route path="/dashboard" element={<Home/>}></Route>
          <Route path="/dashboard/categorias" element={<Categorias/>}></Route>
          <Route path="/dashboard/subcategorias" element={<Subcategoria/>}></Route>
          <Route path="/dashboard/etiquetas" element={<Etiqueta/>}></Route>
          <Route path="/dashboard/marcas" element={<Marca/>}></Route>
          <Route path="/dashboard/productos" element={<Producto/>}></Route>
          <Route path="/dashboard/banners" element={<Banner/>}></Route>
          <Route path="/dashboard/pedidos" element={<Pedido/>}></Route>
          <Route path="/dashboard/cupones" element={<Cupones/>}></Route>
          <Route path="/dashboard/roles" element={<Rol/>}></Route>
          <Route path="/dashboard/modulos" element={<Modulo/>}></Route>
          <Route path="/dashboard/promocion" element={<Promocion/>}></Route>
          <Route path="/dashboard/propaganda" element={<Propaganda/>}></Route>
          <Route path="/dashboard/blanco" element={<Blanco/>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;