import React, { useEffect } from 'react';
import {Outlet} from "react-router-dom";
import '../../assets/css/dashboard.css';
import Sidebar from './Sidebar';
import Navbar from './navbar';
import $ from 'jquery'; 

function Dashboard(){

  const componentDidMount = () => {
    $(document).ready(function() {
      $( "#menu-btn" ).click(function() {
        $('#aside').addClass('ver');
        $('#aside').removeClass('ocultar');
      });
    
      $( "#close-btn" ).click(function() {
        $('#aside').addClass('ocultar');
        $('#aside').removeClass('ver');
      });
    });
  }

  useEffect(()=>{
    componentDidMount();
  }, []);

  return(
    <div className='containeres'>
      <Sidebar></Sidebar>
      <main>
        <Outlet />
      </main>
      <Navbar></Navbar>
    </div>
  );
}

export default Dashboard;