import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import { ecoUrl, linkCuentas, linkServidor, baseUrl } from '../../services/apirest';
import axios from 'axios';
import tarjeta from '../../assets/images/productos/tarjetas.webp';
import transferencia from '../../assets/images/PAGOTRANSFERENCIA.webp';
import contraentrega from '../../assets/images/PAGOCONTRAENTREGA.webp';
import whatsap from '../../assets/images/productos/whatsapp.webp';
import $, { holdReady } from 'jquery'; 
import Navbar from '../tienda/Navbar';
import Footer from '../tienda/Footer';
import logoniubiz  from '../../assets/images/logoniubiz.png';

import entregadosdias  from '../../assets/images/Recojo/48horas.webp';
import entregatresdias  from '../../assets/images/Recojo/72horas.webp';
import diasDEPENDIENDO  from '../../assets/images/Recojo/diasDEPENDIENDO.webp';
import dias  from '../../assets/images/Recojo/dias.webp';
import recojodosdias  from '../../assets/images/Recojo/R24horas.webp';
import recojotresdias  from '../../assets/images/Recojo/R72horas.webp';

function DetalleVentas() {

  const [AllDepartments, setAllDepartments] = useState([]);
  const [AllProvinces, setAllProvinces] = useState([]);
  const [AllDistricts, setAllDistricts] = useState([]);

  const [Cupon, setCupon] = useState('');
  const [CuponTof, setCuponTof] = useState(0);
  const [TipoCupon, setTipoCupon] = useState('');
  const [MontoCupon, setMontoCupon] = useState('');
  const [DescuentoCupon, setDescuentoCupon] = useState('');
  const [GetallProduct, setGetallProduct] = useState([]);
  
  const [SubTotal, setSubTotal] = useState(0);
  const [Ahorro, setAhorro] = useState(0);
  const [Total, setTotal] = useState(JSON.parse(sessionStorage.getItem('total'))?JSON.parse(sessionStorage.getItem('total')):0);
  const [CantidadTotal , setCantidadTotal] = useState(0);

  const [Name, setName] = useState('');
  const [Apellido, setApellido] = useState('');
  const [Tipo, setTipo] = useState('');
  const [DNI, setDNI] = useState('');
  const [Correo, setCorreo] = useState('');
  const [Telefono, setTelefono] = useState('');
  const [Empresa, setEmpresa] = useState('');
  const [Entrega, setEntrega] = useState('');
  const [CostoEnvio, setCostoEnvio] = useState(0);
  const [Pago, setPago] = useState('');
  const [isCondiciones, setCondiciones] = useState(false);
  const [Departments, setDepartments] = useState('');
  const [Provinces, setProvinces] = useState('');
  const [Districts, setDistricts] = useState('');
  const [Direccion, setDireccion] = useState('');
  const [InfoAdi, setInfoAdi] = useState('');
  const [Mensaje, setMensaje] = useState('');
  const [ExternalId, setExternalId] = useState('');
  const [Ahorrado, setAhorrado] = useState('');
  const [PedidoID, setPedidoID] = useState('');
  const [codigopago, setcodigopago] = useState('');

  const [LimaEntrega, setLimaEntrega] = useState(1);
  const [Lugar, setLugar] = useState('');
  const [VerLima, setVerLima] = useState(0);

  let ID = JSON.parse(sessionStorage.getItem('ID'))?JSON.parse(sessionStorage.getItem('ID')):0;

  let result = parseFloat(Total) + parseFloat(CostoEnvio);

  console.log(LimaEntrega);

  const getScroll = () => {
    window.scrollTo(0, 0);
  }

  const getUsuario = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/getUsuario`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      setName(response.data.data.usuario.name);
      setCorreo(response.data.data.usuario.email);
      setTelefono(response.data.data.usuario.phone);
      setApellido(response.data.data.usuario.lastname);
      setDireccion(response.data.data.usuario.direccion);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getProduct = () =>{
    let item = JSON.parse(sessionStorage.getItem('carrito'))?JSON.parse(sessionStorage.getItem('carrito')):null;
    //console.log(item);
    setGetallProduct(item);
    let preciowebtotal = 0;
    let stocklima = 0;
    item.map((value)=>{
      preciowebtotal += value.precio * value.cantidad_pd;
      stocklima = value.stock_lima >= 1;

      switch (stocklima) {
        case false:
          console.log('no hay');
          setLimaEntrega(0);
          break;
      }

      while (stocklima) {
        if (stocklima == true){
          console.log('si hay');
          setVerLima(1);
          break;
        }
      }
      // console.log(stocklima);

    });
    setAhorrado((preciowebtotal).toFixed(2));
  }

  const venta = (e) => {
    e.preventDefault();
    let item = JSON.parse(sessionStorage.getItem('carrito'))?JSON.parse(sessionStorage.getItem('carrito')):null;

    fetch(`${ecoUrl}/Pedido/savePedido`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tipo_docuemento : Tipo,
        dni : DNI,
        ruc : DNI,
        nombre_empresa : Empresa,
        nombre : Name,
        apellido : Apellido,
        email : Correo,
        telefono : Telefono,
        tipo_envio : Entrega,
        costo_envio : CostoEnvio,
        departamento : Departments,
        provincia : Provinces,
        distrito : Districts,
        direccion : Direccion,
        lugar : Lugar,
        info_adicional : InfoAdi,
        sub_total : SubTotal,
        ahorro : Ahorro,
        cupon : Cupon,
        tipo_cupon : TipoCupon, 
        monto_cupon : MontoCupon, 
        descuento_cupon : DescuentoCupon,
        total : result,
        cantidad_total : CantidadTotal,
        tipo_pago : Pago,
        politicas : isCondiciones,
        codigo_pago : codigopago,
        carrito : item,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      console.log(json);
      const Identificador = json.data.pedido.id;
      setPedidoID(json.data.pedido.id)
      sessionStorage.setItem('ID', JSON.stringify(Identificador));

      if(json.msg != 'Tarjeta'){
        console.log("paso por aqui");
        window.location.replace(`${linkCuentas}/cuentas`);
        sessionStorage.removeItem("carrito");
        sessionStorage.removeItem("cantidad");
        sessionStorage.removeItem("total");
      }

      if(json.msg == 'Tarjeta'){
        $("#modalpago").click();
        axios.get(`${ecoUrl}/Pedido/pagoNiubiz/${Identificador}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        })
        .then((response) => {
          console.log(response.data);
          setExternalId(response.data.externalId);
          window.open(response.data.link);
          $("#modalito").click();
          $("#cerrardireccion").click();
        })
        .catch((err)=>{
          console.log(err);
        });

        let token = localStorage.getItem("token");
        fetch(`${ecoUrl}/Checkout/CheckCoupon/${Cupon}/1`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}` 
          },
          body: JSON.stringify({
            total : Total,
            producto : item
          }),
        })
        .then((response) => response.json())
        .then((json)=> {
          //console.log(json);
        })
        .catch((err)=>{
          console.log(err);
        });
        sessionStorage.removeItem("carrito");
        sessionStorage.removeItem("cantidad");
        sessionStorage.removeItem("total");
      }
    })  
    .catch((err)=>{
      console.log(err);
    });
  }

  const getDepartments = () =>{
    axios.get(`${ecoUrl}/Checkout/getDepartments`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      setAllDepartments(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const onCambio = (e) => {
    const Department = e.target.value;
    //console.log(Department);
    setDepartments(Department);
    axios.get(`${ecoUrl}/Checkout/getProvinces/${Department}`)
    .then((responseDepartment) => {
      //console.log(responseDepartment.data.data);
      setAllProvinces(responseDepartment.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const onCambioProvinces = (e) => {
    const Province = e.target.value;
    //console.log(Province);
    let IDDistritos = 0;
    setProvinces(Province);
    axios.get(`${ecoUrl}/Checkout/getDistricts/${Province}`)
    .then((responseProvince) => {
      setAllDistricts(responseProvince.data.data);
      //console.log(responseProvince.data.data);
      //console.log(responseProvince.data.data[0].id);
      IDDistritos = responseProvince.data.data[0].id;
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const onCambioDistrict = (e) =>{
    let item = JSON.parse(sessionStorage.getItem('carrito'))?JSON.parse(sessionStorage.getItem('carrito')):null;
    const Distrito = e.target.value;
    //console.log(Distrito);
    setDistricts(Distrito);
    //console.log(Total);
    if(Total >= 500){
      if (VerLima == 1) {
        if (Distrito == 1251) { 
          setCostoEnvio(0);
        }else{
          fetch(`${ecoUrl}/Checkout/getShippingCost`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              distrito : Distrito,
              productos : item,
            }),
          })
          .then((response) => response.json())
          .then((json)=> {
            //console.log(json);
            setCostoEnvio(json); 
          })
          .catch((err)=>{
            console.log(err);
          });
        }
      } else {
        if(Distrito == 1129){
          setCostoEnvio(0);
        }else{
          fetch(`${ecoUrl}/Checkout/getShippingCost`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              distrito : Distrito,
              productos : item,
            }),
          })
          .then((response) => response.json())
          .then((json)=> {
            //console.log(json);
            setCostoEnvio(json);
          })
          .catch((err)=>{
            console.log(err);
          });
        }
      }
    }else{
      fetch(`${ecoUrl}/Checkout/getShippingCost`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          distrito : Distrito,
          productos : item,
        }),
      })
      .then((response) => response.json())
      .then((json)=> {
        //console.log(json);
        setCostoEnvio(json);
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  }

  const cuponcito = (e) => {
    const Cupo = e.target.value;
    let item = JSON.parse(sessionStorage.getItem('carrito'))?JSON.parse(sessionStorage.getItem('carrito')):null;
    setCupon(Cupo);
    let token = localStorage.getItem("token");
    fetch(`${ecoUrl}/Checkout/CheckCoupon/${Cupo}/${CuponTof}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        total : Total,
        producto : item
      }),
    })
    .then((response) => response.json())
    .then((json)=> {
      console.log(json);
      if(json.estado == 'success'){
        setMontoCupon(json.rstint);
        setTotal(Total - json.rstint);
        setMensaje('CUPÓN APLICADO');
        // if(json.rstint == 150){
        //   setMontoCupon(json.rstint);
        //   setTotal(Total - json.rstint);
        //   setMensaje('CUPÓN APLICADO');
        // }
        // else if(json.rstint == 15){
        //   setMontoCupon(json.rstint);
        //   setTotal( Total - (Total * 15 / 100));
        //   setMensaje('CUPÓN APLICADO DEL 15%');
        // }else if(json.rstint == 5){
        //   setMontoCupon(json.rstint);
        //   setTotal( Total - (Total * 5 / 100));
        //   setMensaje('CUPÓN APLICADO DEL 5%');
        // }else if(json.rstint == 10){
        //   setMontoCupon(json.rstint);
        //   setTotal( Total - (Total * 10 / 100));
        //   setMensaje('CUPÓN APLICADO DEL 10%');
        // }
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const ConsultaPago = (e) => {
    e.preventDefault();
    axios.get(`${ecoUrl}/Pedido/PagoConsultaNiubiz/${ExternalId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response.data);
      if(response.data.status == "COMPLETED"){
        window.location.replace(`${linkCuentas}/detalledecompra`);
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const componentDidMount = () => {
    $('.ir-arriba').click(function(){
      $('body, html').animate({
        scrollTop: '0px'
      }, 0);
    });

    $(window).scroll(function(){
      if( $(this).scrollTop() > 0 ){
        $('.ir-arriba').slideDown(600);
      } else {
        $('.ir-arriba').slideUp(600);
      }
    });
  }
  
  useEffect(()=>{
    getDepartments();
    getScroll();
    getProduct();
    getUsuario();
    componentDidMount();
  }, []);

  return (
    <div>
      <Navbar/>
      <a class="ir-arriba"  href="#" title="Volver arriba">
        <span class="fa-stack">
          <i class="fa fa-circle fa-stack-2x"></i>
          <i class="fa fa-angle-double-up fa-stack-1x fa-inverse" style={{ fontSize: "30px" }}></i>
        </span>
      </a>
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
      <a href="https://bit.ly/WHATSAPPCOMPUPLAZANEW" class="float" target="_blank">
        <i class="fa fa-whatsapp my-float" style={{ marginTop: "5%" }}></i>
      </a>
      <div className='container'>
        <form onSubmit={(e) => {venta(e)}}>
          <h2 style={{ fontWeight: "700", fontSize: "40px", color: "#fe6313", marginTop: "7%" }} className="poppins-bold">
            DETALLES DE FACTURACIÓN
          </h2>
          <div className='contenedor-detalleventa'>
            <div className='col-lg-6' style={{ padding: "0px" }}>
              <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                <label className='title-name-detalle'>Nombre</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ color: "white" }} fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                    </svg>
                  </span>
                  <input type="text" className="form-control input-detalle" value={Name} onChange={(e) => { setName(e.target.value) }}/>
                </div>
              </div>
              <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                <label className='title-name-detalle'>Apellido</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ color: "white" }} fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                    </svg>
                  </span>
                  <input type="text" className="form-control input-detalle" value={Apellido} onChange={(e) => { setApellido(e.target.value) }}/>
                </div>
              </div>
              <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                <label className='title-name-detalle'>Tipo de identificación</label>
                <select className="form-select input-detalle" aria-label="Default select example" onChange={(e) => { setTipo(e.target.value) }}>
                  <option selected>Seleccionar</option>
                  <option value="1">DNI</option>
                  <option value="2">RUC</option>
                </select>
              </div>
              <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                <label className='title-name-detalle'>DNI o RUC</label>
                <input type="text" className="form-control input-detalle" onChange={(e) => { setDNI(e.target.value) }}/>
              </div>
              <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                <label className='title-name-detalle'>Correo electrónico</label>
                <input type="text" className="form-control input-detalle" value={Correo} onChange={(e) => { setCorreo(e.target.value) }}/>
              </div>
              <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                <label className='title-name-detalle'>Teléfono</label>
                <input type="text" className="form-control input-detalle" value={Telefono} onChange={(e) => { setTelefono(e.target.value) }}/>
              </div>
              {Tipo == 2 &&
                <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                  <label className='title-name-detalle'>Nombre de la empresa</label>
                  <input type="text" className="form-control input-detalle" onChange={(e) => { setEmpresa(e.target.value) }}/>
                </div>
              }

              <div className='row' style={{ marginBottom: "3%" }}>
                <div className='col-12 metododepago'>
                  <h2 className='titulopago poppins-bold' style={{ fontSize: "35px" }}>MÉTODO DE PAGO</h2>
                </div>
                <div className='col-lg-12'>
                  <div className='checkbox-detalle'>
                    <div className="form-check check-detalle">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="1" onChange={(e) => { setPago(e.target.value) }}/>
                      <label className="form-check-label pagocontarjeta" for="flexRadioDefault1">
                        Pago con Tarjeta
                      </label>
                      <img className='tarjetitas-venta-img' src={tarjeta} alt="logo" />
                    </div>
                  </div>
                  {Pago == 1 &&
                    <div style={{ padding: "4%", border: "1px solid #ed571d", margin: "3%", borderRadius: "15px" }}>
                      <h3 style={{ fontSize: "16px", alignItems: "center", display: "flex", paddingBottom: "3%", fontWeight: "700", color: "#ed571d" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" style={{ marginRight: "2%" }} class="bi bi-credit-card" viewBox="0 0 16 16">
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
                          <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
                        </svg>
                        Información acerca de tu pago
                      </h3>
                      <span style={{ fontSize: "14px" }}>Hemos habilitado un espacio seguro para que realices tu pago con tarjeta <strong>visa y mastercard</strong> de forma rápida y sencilla.</span>
                    </div>
                  }

                  <div className='checkbox-detalle'>
                    <div className="form-check check-detalle">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="3" onChange={(e) => { setPago(e.target.value) }}/>
                      <label className="form-check-label pagocontarjeta" for="flexRadioDefault1">
                        Pago con Transferencia
                      </label>
                      <img className='tarjeta-venta-img' src={transferencia} alt="logo"/>
                    </div>
                  </div>
                  {Pago == 3 &&
                    <div style={{ padding: "4%", border: "1px solid #ed571d", margin: "3%", borderRadius: "15px" }}>
                      <h3 style={{ fontSize: "16px", alignItems: "center", display: "flex", paddingBottom: "3%", fontWeight: "700", color: "#ed571d" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" style={{ marginRight: "2%" }} class="bi bi-credit-card" viewBox="0 0 16 16">
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
                          <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
                        </svg>
                        Información acerca de tu pago
                      </h3>
                      <span style={{ fontSize: "14px" }}>Realiza tu pago dentro de las 24 horas después de realizar el pedido, de lo contrario tu compre quedará anulada.</span>
                    </div>
                  }

                  <div className='checkbox-detalle'>
                    <div className="form-check check-detalle" style={{ padding: "1% 6%", marginBottom: "-2%" }}>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="4" onChange={(e) => { setPago(e.target.value) }}/>
                      <label className="form-check-label pagocontarjeta" for="flexRadioDefault1">
                        Pago Contraentrega
                      </label>
                      <img className='tienda-venta-img' src={contraentrega} alt="logo"/>
                    </div>
                    <span className='subtitutlo-venta'>(Solo para recojo en tienda)</span>
                  </div>
                  {Pago == 4 &&
                    <div style={{ padding: "4%", border: "1px solid #ed571d", margin: "3%", borderRadius: "15px" }}>
                      <h3 style={{ fontSize: "16px", alignItems: "center", display: "flex", paddingBottom: "3%", fontWeight: "700", color: "#ed571d" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" style={{ marginRight: "2%" }} class="bi bi-credit-card" viewBox="0 0 16 16">
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
                          <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
                        </svg>
                        Información acerca de tu pago
                      </h3>
                      <span style={{ fontSize: "14px" }}>Nuestro asesor se comunicará contigo en un <strong>plazo máximo de 24 horas</strong> para coordinar el día y hora del recojo de tu pedido.</span>
                      <p style={{ marginTop: "2%", fontWeight: "bolder", marginBottom: "1%", color: "#001267" }}>Puntos de recojo:</p>
                      <div style={{ marginLeft: "2%" }}>
                        <div style={{ alignItems: "center", display: "flex", marginBottom: "2%" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ color: "#ed571d" }} class="bi bi-shop" viewBox="0 0 16 16">
                            <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>
                          </svg>
                          <span style={{ fontSize: "14px", marginLeft: "1%", marginTop: "1%" }}><strong style={{ color: "#001267", fontWeight: "800" }}>Trujillo:</strong> Jirón Junin 699 - Centro</span>
                        </div>
                        <div style={{ alignItems: "center", display: "flex" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ color: "#ed571d" }} class="bi bi-shop" viewBox="0 0 16 16">
                            <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>
                          </svg>
                          <span style={{ fontSize: "14px", marginLeft: "1%", marginTop: "2%" }}><strong style={{ color: "#001267", fontWeight: "800" }}>Lima:</strong> Av. Petit Thouars N° 1498 int 1707- Santa Beatriz</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              {Pago == 1 &&
                <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                  <h2 className='titulopago poppins-bold' style={{ fontSize: "35px" }}>Tipo de entrega</h2>
                  <select className="form-select input-detalle" aria-label="Default select example" onChange={(e) => { setEntrega(e.target.value) }}>
                    <option selected>Seleccionar</option>
                    <option value="1">Recojo en tienda</option>
                    <option value="2">Envio a domicilio</option>
                  </select>
                </div>
              }
              {Pago == 3 &&
                <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                  <h2 className='titulopago poppins-bold' style={{ fontSize: "35px" }}>Tipo de entrega</h2>
                  <select className="form-select input-detalle" aria-label="Default select example" onChange={(e) => { setEntrega(e.target.value) }}>
                    <option selected>Seleccionar</option>
                    <option value="1">Recojo en tienda</option>
                    <option value="2">Envio a domicilio</option>
                  </select>
                </div>
              }
              {Pago == 4 &&
                <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                  <h2 className='titulopago poppins-bold' style={{ fontSize: "35px" }}>Tipo de entrega</h2>
                  <select className="form-select input-detalle" aria-label="Default select example" onChange={(e) => { setEntrega(e.target.value) }}>
                    <option selected>Seleccionar</option>
                    <option value="1">Recojo en tienda</option>
                  </select>
                </div>
              }
              {/* <div className="col-lg-12 mt-3" style={{ padding: "0px" }}>
                <label className='titulo-cupon poppins-bold' style={{ fontSize: "25px" }}>
                  CUPONES Y DESCUENTOS 
                </label>
                <div className="input-group">
                  <input type="text" className="form-control input-cupon btn-cupon" placeholder="Añadir cupón de descuento" value={null} onChange={(e) => { cuponcito(e) }}/>
                </div>
                <span style={{ color: "#25D366", marginLeft: "1%", fontWeight: "bold" }}>{Mensaje}</span>
              </div> */}
              { Entrega == 1 &&
                <div className="col-lg-12" style={{ marginTop: "3%" }}>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="lugar" id="flexRadioDefault1" value="Trujillo" onChange={(e) => { setLugar(e.target.value) }}/>
                    <label class="form-check-label" for="flexRadioDefault1" style={{ cursor: "pointer" }}>
                      Jirón Junin 699 (Esquina Junin y Ayacucho) - Trujillo
                    </label>
                  </div>
                  { Lugar == 'Trujillo' &&
                    <div style={{ padding: "3% 0%" }}>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.908746832321!2d-79.0262674499428!3d-8.110773894132373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ad3d814dcec311%3A0x4b5efb2e3cd8e7d3!2sCOMPUPLAZA!5e0!3m2!1ses-419!2spe!4v1663777646679!5m2!1ses-419!2spe" 
                      width="100%" height="450px" style={{ border:"0", padding: "0% 8%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      <h4 style={{ color: "black", textAlign: "center", marginBottom: "0%", paddingTop: "2%" }}>TRUJILLO</h4>
                      <h5 style={{ color: "black", fontSize: "18px", textAlign: "center" }}> Jirón Junin 699 (Esquina Junin y Ayacucho)</h5>
                    </div>
                  }


                  { LimaEntrega == 1 &&
                    <div class="form-check">
                      { Total >= 300 &&
                      <div>
                        <input class="form-check-input" type="radio" name="lugar" id="flexRadioDefault2" value="Lima" onChange={(e) => { setLugar(e.target.value) }}/>
                        <label class="form-check-label" for="flexRadioDefault2" style={{ cursor: "pointer" }}>
                          Av. Petit Thouars N° 1498 int 1707- Santa Beatriz - Lima
                        </label>
                      </div>
                      }
                    </div>
                  }

                  { LimaEntrega != 1 &&
                    <div class="form-check">
                      { Total >= 300 &&
                      <div>
                        <input class="form-check-input" type="radio" name="lugar" id="flexRadioDefault2" value="Lima" onChange={(e) => { setLugar(e.target.value) }}/>
                        <label class="form-check-label" for="flexRadioDefault2" style={{ cursor: "pointer" }}>
                          Av. Petit Thouars N° 1498 int 1707- Santa Beatriz - Lima
                        </label>
                      </div>
                      }
                    </div>
                  }


                  { Lugar == 'Lima' &&
                    <div style={{ padding: "3% 0%" }}>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.4786001238385!2d-77.03683028984177!3d-12.079352988110609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c8f34513ad29%3A0x4473b362b7c5c42e!2sAv.%20Petit%20Thouars%201498%2C%20Lima%2015046!5e0!3m2!1ses-419!2spe!4v1682691761536!5m2!1ses-419!2spe" width="100%" height="450px" style={{ border:"0", padding: "0% 8%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      <h4 style={{ color: "black", textAlign: "center", marginBottom: "0%", paddingTop: "2%" }}>Lima</h4>
                      <h5 style={{ color: "black", fontSize: "18px", textAlign: "center" }}> Av. Petit Thouars N° 1498 int 1707- Santa Beatriz </h5>
                    </div>
                  }
                </div>
              } 
              { Entrega == 2 &&
                <div className='row' style={{ display: "block", marginTop: "1%" }}>
                  <div className="col-lg-12 mt-3">
                    <label className='title-name-detalle'>Departamento</label>
                    <select className="form-select input-detalle" aria-label="Default select example" id='departament' onChange={(e) => { onCambio(e) }}>
                      <option selected value="0">Seleccionar</option>
                      {AllDepartments.map((value, index) =>{
                        return (
                          <option key={index} value={value.id}>{value.descripcion}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <label className='title-name-detalle'>Provincia</label>
                    <select className="form-select input-detalle" aria-label="Default select example" id='provincias' onChange={(e) => { onCambioProvinces(e) }}>
                      <option selected value="0">Seleccionar</option>
                      {AllProvinces.map((value, index) =>{
                        return (
                          <option key={index} value={value.id}>{value.descripcion}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <label className='title-name-detalle'>Distrito</label>
                    <select className="form-select input-detalle" aria-label="Default select example" id='distritos' onChange={(e) => { onCambioDistrict(e) }}>
                      <option selected value="0">Seleccionar</option>
                      {AllDistricts.map((value, index) =>{
                        return (
                          <option key={index} value={value.id}>{value.descripcion}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <label className='title-name-detalle'>Dirección</label>
                    <input type="text" className="form-control input-detalle" value={Direccion} onChange={(e) => { setDireccion(e.target.value) }}/>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <label className='title-name-detalle'>Notas del pedido</label>
                    <textarea className="form-control" style={{ height: "140px", borderRadius: "14px" }} onChange={(e) => { setInfoAdi(e.target.value) }} placeholder='Notas sobre tu pedido, por ejemplo, notas especiales para la entrega'></textarea>
                  </div>
                </div>
              }
            </div>
            <div className='col-lg-6' style={{ padding: "3% 3%" }}>
              <div className='p-3 bg-body-tertiary rounded' style={{ width: "80%", background: "#fafafa", margin: "auto" }}>
                <h3 style={{ fontSize: "16px", fontWeight: "700", marginTop: "3%", marginLeft: "3%" }}>Resumen de tu compra</h3>
                <div style={{ background: "#f1f1f1", margin: "3%", padding: "5% 4%", paddingBottom: "1%", marginTop: "5%" }}>
                {GetallProduct.map((value) =>{
                  return (
                    <div style={{ display: "flex", marginBottom: "6%" }}>
                      <div className='col-4' style={{ justifyContent: "center", display: "flex", padding: "0%" }}>
                        <img src={linkServidor + '/' + value.dir_img_pri} style={{ width: "70%" }}></img>
                        <span style={{ width: "20px", height: "20px", background: "#dff0d8", borderRadius: "50%", position: "absolute", left: "10%", marginTop: "-6%", textAlign: "center" }}>{value.cantidad_pd}</span>
                      </div>
                      <div className='col-8' style={{ padding: "0px" }}>
                        <div className='col-12' style={{ height: "50%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", paddingTop: "4%", paddingLeft: "0px" }}>
                          <span>{value.description}</span>
                        </div>
                        <div className='col-12' style={{ display: "grid", justifyContent: "end" }}>
                          <span style={{ fontSize: "15px", fontWeight: "800", color: "#001683" }}>Normal: <span>S/</span> {value.precio}</span>
                          <span style={{ fontSize: "15px", fontWeight: "800", color: "#ed571d", display: "flex", justifyContent: "space-evenly" }}>Online: <span>S/</span> {value.precio_venta_dp}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
                </div>
                <div className='col-12' style={{ display: "flex", margin: "5% 0% 2%" }}>
                  <div className='col-6' style={{ paddingLeft: "10%" }}>
                    <span>Subtotal</span>
                  </div>
                  <div className='col-6' style={{ justifyContent: "flex-end", display: "flex", paddingRight: "10%" }}>
                    <span><span>S/</span> {Total}</span>
                  </div>
                </div>
                <div className='col-12' style={{ display: "flex", margin: "2% 0%" }}>
                  <div className='col-6' style={{ paddingLeft: "10%" }}>
                    <span>Costo de Envío</span>
                  </div>
                  <div className='col-6' style={{ justifyContent: "flex-end", display: "flex", paddingRight: "10%" }}>
                    <span><span>S/</span> {CostoEnvio}</span>
                  </div>
                </div>
                <div className="col-lg-12 mt-3" style={{ padding: "3% 3%" }}>
                  <label className='titulo-cupon poppins-bold' style={{ fontSize: "15px" }}>
                    Cupón de descuento
                  </label>
                  <div className="input-group">
                    <input type="text" className="form-control input-cupon btn-cupon" placeholder="Añadir código" value={null} onChange={(e) => { cuponcito(e) }}/>
                    {/* <button className="btn btn-cupon" type="submit">Aplicar</button> */}
                  </div>
                  <span style={{ color: "#25D366", marginLeft: "1%", fontWeight: "bold" }}>{Mensaje}</span>
                </div>
                <div className='col-12' style={{ display: "flex", margin: "2% 0% 0%" }}>
                  <div className='col-6' style={{ paddingLeft: "10%" }}>
                    <span style={{ fontWeight: "800", fontSize: "22px", color: "#001683" }}>Total</span>
                  </div>
                  <div className='col-6' style={{ justifyContent: "flex-end", display: "flex", paddingRight: "10%", fontWeight: "800" }}>
                    <span style={{ fontWeight: "800", fontSize: "22px", color: "#001683" }}><span>S/</span> {(result)}</span>
                  </div>
                </div>
                <div className='col-12' style={{ display: "flex", margin: "2% 0%" }}>
                  <div className='col-6' style={{ paddingLeft: "10%" }}>
                    <span style={{ color: "#ed571d", fontWeight: "800", fontSize: "20px" }}>Ahorraste</span>
                  </div>
                  <div className='col-6' style={{ justifyContent: "flex-end", display: "flex", paddingRight: "10%", color: "#ed571d", fontWeight: "800", fontSize: "20px" }}>
                    <span><span>S/</span> {(Ahorrado - Total).toFixed(2)}</span>
                  </div>
                </div>
              </div>
              { Entrega == 1 &&
                <div>
                  { Lugar == 'Lima' &&
                    <div>
                      { LimaEntrega == 1 &&
                        <div style={{ justifyContent: "center", display: "flex", marginTop: "3%" }}>
                          <img src={recojodosdias} alt="logo" style={{ width: "50%" }}/>
                        </div>
                      }
                      { LimaEntrega == 0 &&
                        <div style={{ justifyContent: "center", display: "flex", marginTop: "3%" }}>
                          <img src={recojotresdias} alt="logo" style={{ width: "50%" }}/>
                        </div>
                      }
                    </div>
                  }
                  { Lugar == 'Trujillo' &&
                    <div style={{ justifyContent: "center", display: "flex", marginTop: "3%" }}>
                      <img src={recojodosdias} alt="logo" style={{ width: "50%" }}/>
                    </div>
                  }
                </div>
              } 
              { Entrega == 2 &&
                <div>
                  { Districts == 1251 &&
                    <div>
                      { LimaEntrega == 1 &&
                        <div style={{ justifyContent: "center", display: "flex", marginTop: "3%" }}>
                          <img src={entregatresdias} alt="logo" style={{ width: "50%" }}/>
                        </div>
                      }
                      { LimaEntrega == 0 &&
                        <div style={{ justifyContent: "center", display: "flex", marginTop: "3%" }}>
                          <img src={dias} alt="logo" style={{ width: "50%" }}/>
                        </div>
                      }
                    </div>
                  }
                  { Districts == 1129 &&
                    <div style={{ justifyContent: "center", display: "flex", marginTop: "3%" }}>
                      <img src={entregadosdias} alt="logo" style={{ width: "50%" }}/>
                    </div>
                  }
                  { Districts != 1129 && Districts != 1251 &&
                    <div style={{ justifyContent: "center", display: "flex", marginTop: "3%" }}>
                      <img src={diasDEPENDIENDO} alt="logo" style={{ width: "50%" }}/>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
          <div className='row' style={{ marginBottom: "3%" }}>
            <div className='col-sm-12 col-xl-7 col-lg-12'>
              <div className='datos-venta-texto'>
                <span>Sus datos personales se utilizarán para procesar el pedido, 
                  respaldar su experiencia en este sitio web y para otros fines
                  descritos en nuestra politica de privacidad.
                </span>
              </div>
              <div>
                <div className="form-check" style={{ margin: "0% 3%", color: "black" }}>
                  <input className="form-check-input" type="checkbox" id="flexCheckDefault" value={isCondiciones} onChange={(e) => { setCondiciones(e.target.checked) }}  />
                  <label className="form-check-label terminos-condiciones-venta" for="flexCheckDefault">
                    He leído y acepto todos los 
                    <Link to="/terminosycondiciones" style={{ textDecoration: "none", color: "#ed571d" }}>
                      <span> términos condiciones*</span>
                    </Link>
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", padding: "0%", paddingLeft: "6%", marginTop: "2%" }}>
                <div className='col-sm-8 col-lg-6' style={{ border: "1px solid #25D366", borderRight: "transparent", padding: "1% 2%", borderTopLeftRadius: "14px", borderBottomLeftRadius: "14px" }}>
                  <span>
                    Comunícate con nuestros asesores para coordinar
                    el envío de tu producto a la agencia más cercana 
                  </span>
                </div>
                <div className='col-1' style={{ margin: "0px", padding: "1% 0%", border: "1px solid #25D366", borderLeft: "transparent", borderTopRightRadius: "14px", borderBottomRightRadius: "14px" }}>
                  <img src={whatsap} alt="logo" style={{ width: "70%" }}/>
                </div>
              </div>
              <div style={{ padding: "0% 0% 0% 6%", marginTop: "2%" }}>
                {isCondiciones == false &&
                  <button type="submit" className="btn btn-pedido" disabled>Realizar Pedido</button>
                }
                {isCondiciones != false &&
                  <button type="submit" className="btn btn-pedido">Realizar Pedido</button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>

      <button type="button" class="btn btn-primary" id='modalito' data-bs-toggle="modal" data-bs-target="#modalitopago" style={{ display: "none" }}>
        pagar
      </button>

      <button type="button" class="btn btn-primary" id='modalpago' data-bs-toggle="modal" data-bs-target="#MetododePago" style={{ display: "none" }}>
        redireccion
      </button>

      <div class="modal fade" id="modalitopago" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" id='cerrarpago' aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form onSubmit={(e) => {ConsultaPago(e)}}>
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex", marginBottom: "3%" }}>
                  <button type="submit" className="btn btn-pedido">Pago Realizado</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="MetododePago" tabindex="-1" aria-labelledby="MetododePago" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" id='cerrardireccion' aria-label="Close"></button>
            </div>
            <div class="modal-body" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "3%" }}>
              <span style={{ marginRight: "2%", fontSize: "19px" }}>Redireccionando a</span>
              <img src={logoniubiz} style={{ width: "21%" }}/>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default DetalleVentas