import React, { useState, useEffect } from 'react';
import { baseUrl } from '../../services/apirest';
import axios from 'axios';
import '../../assets/css/sidebar.css';
import '../../assets/css/admin.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Swal from 'sweetalert2';

function Cupones() {

  const [Categorias, setCategorias] = useState([]);
  const [Usuarios, setUsuarios] = useState([]);
  const [ProductosAll, setProductosAll] = useState([]);
  const [Cupon, setCupon] = useState([]);

  const [IDCupon, setIDCupon] = useState('');
  const [NombreCupon, setNombreCupon] = useState('');
  const [CantidadCupon, setCantidadCupon] = useState('');
  const [MontoCupon, setMontoCupon] = useState('');
  const [FechaCupon, setFechaCupon] = useState('');
  const [CategoriaCupon, setCategoriaCupon] = useState('');
  const [TipoCupon, setTipoCupon] = useState('');
  const [PrecioCupon, setPrecioCupon] = useState('');
  const [Selection, setSelection] = useState(false);
  const [ProductoCupon, setProductoCupon] = useState('');
  const [UsuarioCupon, setUsuarioCupon] = useState('');

  const getCupon = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/Cupon`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setCupon(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
    
    axios.get(`${baseUrl}/categoria`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setCategorias(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/usuario`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setUsuarios(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });

    axios.get(`${baseUrl}/producto`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setProductosAll(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const addCupon = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    axios({
      method: "POST",
      url : `${baseUrl}/Cupon`, 
      data: JSON.stringify({
        descripcion : NombreCupon,
        cantidad : CantidadCupon,
        monto : MontoCupon,
        fecha_expiracion : FechaCupon,
        restriccion_categoria : CategoriaCupon,
        tipo : TipoCupon,
        restriccion_precio : PrecioCupon,
        seleccion_producto : Selection,
        restriccion_producto : ProductoCupon,
        restriccion_usuario : UsuarioCupon,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response)=>{
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getCupon();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const clickCupon = (edit) =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/Cupon/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setIDCupon(response.data.data.id)
      setNombreCupon(response.data.data.descripcion)
      setCantidadCupon(response.data.data.cantidad)
      setMontoCupon(response.data.data.monto)
      setFechaCupon(response.data.data.fecha_expiracion)
      setCategoriaCupon(response.data.data.restriccion_categoria)
      setTipoCupon(response.data.data.tipo)
      setPrecioCupon(response.data.data.restriccion_precio)
      setSelection(response.data.data.seleccion_producto)
      setProductoCupon(response.data.data.restriccion_producto)
      setUsuarioCupon(response.data.data.restriccion_usuario)
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const editCupon = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    axios({
      method: "PUT",
      url : `${baseUrl}/Cupon/${IDCupon}`, 
      data: JSON.stringify({
        descripcion : NombreCupon,
        cantidad : CantidadCupon,
        monto : MontoCupon,
        fecha_expiracion : FechaCupon,
        restriccion_categoria : CategoriaCupon,
        tipo : TipoCupon,
        restriccion_precio : PrecioCupon,
        seleccion_producto : Selection,
        restriccion_producto : ProductoCupon,
        restriccion_usuario : UsuarioCupon,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response)=>{
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Editado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getCupon();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const componentDidMount = () => {
    $(document).ready(function() {
      $('#example').DataTable();
    });
  }

  useEffect(()=>{
    getCupon();
    componentDidMount();
  }, []);

  return (
    <div style={{ display: "contents" }}>
      <div className="admin espacio-admin">
        <main className="page-content">
          <h2 className="titulo-admin">MODULO DE CUPONES</h2>
          <div className="col-12 button-categoria">
            <button type="button" className="btn btn-inverse-success btn-fw" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Agregar Cupón
            </button>
          </div>
          <div className="tabla-admin">
            <table id="example" datatable className="display">
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ID</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>NOMBRE</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ESTADO</th>
                  <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ display: "none" }}> 
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                  <td>DASDASDASD</td>
                </tr>
                {Cupon.map((value, index) =>{
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{value.id}</td>
                      <td style={{ textAlign: "center" }}>{value.descripcion}</td>
                      <td style={{ textAlign: "center" }}>{value.estado}</td>
                      <td onClick={() => {clickCupon(value.id)}} style={{ textAlign: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" data-bs-toggle="modal" data-bs-target="#exampleModale" height="21" fill="currentColor" className="bi bi-pencil-square edit-admin" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </main>

        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ borderRadius: "10px" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Agregar Cupón</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form onSubmit={(e) => {addCupon(e)}}>
                <div className="modal-body">
                  <div style={{ display: "flex" }}>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="input" onChange={(e) => { setNombreCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Nombre del cupón</label>
                    </div>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="input" onChange={(e) => { setCantidadCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Cantidad</label>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="input" onChange={(e) => { setMontoCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Monto</label>
                    </div>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="date" onChange={(e) => { setFechaCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Fecha de expiración</label>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <label className="form__label" for="descripcion">Restricción de categorias</label>
                      <select className="form-select" aria-label="Default select example" onChange={(e) => { setCategoriaCupon(e.target.value) }}>
                        <option value="0">Seleccionar</option>
                        {Categorias.map((elemento, index)  => (
                          <option key={index} value={elemento.id}>{elemento.descripcion}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form__group col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <label className="form__label" for="descripcion">Tipo</label>
                      <select className="form-select" aria-label="Default select example" onChange={(e) => { setTipoCupon(e.target.value) }}>
                        <option value="0">Seleccionar</option>
                        <option value="1">Personal</option>
                        <option value="2">Masivo</option>
                      </select>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="input" onChange={(e) => { setPrecioCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Restricción de precio</label>
                    </div>
                    {TipoCupon == 1 &&
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <label className="form__label" for="descripcion">Elegir usuarios</label>
                        <select className="form-select" aria-label="Default select example" onChange={(e) => { setUsuarioCupon(e.target.value) }}>
                          <option value="0">Seleccionar</option>
                          {Usuarios.map((elemento, index)  => (
                            <option key={index} value={elemento.id}>{elemento.name}</option>
                          ))}
                        </select>
                      </div>
                    } 
                    {TipoCupon == 2 &&
                      <div className="form__group col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input type="checkbox" id="box-1"  value={Selection} onChange={(e) => { setSelection(e.target.checked) }} />
                        <label className="checkbox-custom-label" for="box-1">Restringir Productos</label>
                      </div>
                    }
                  </div>
                  {Selection == true &&
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <label className="form__label" for="descripcion">Elegir productos</label>
                      <select className="form-select" aria-label="Default select example" onChange={(e) => { setProductoCupon(e.target.value) }}>
                        <option value="0">Seleccionar</option>
                        {ProductosAll.map((elemento, index)  => (
                          <option key={index} value={elemento.id}>{elemento.description}</option>
                        ))}
                      </select>
                    </div>
                  } 
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-inverse-success btn-fw">
                    Guardar Cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleModale" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ borderRadius: "10px" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Editar Cupón</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form onSubmit={(e) => {editCupon(e)}}>
                <div className="modal-body">
                  <div style={{ display: "flex" }}>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="input" value={NombreCupon} onChange={(e) => { setNombreCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Nombre del cupón</label>
                    </div>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="input" value={CantidadCupon} onChange={(e) => { setCantidadCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Cantidad</label>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="input" value={MontoCupon} onChange={(e) => { setMontoCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Monto</label>
                    </div>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="date" value={FechaCupon} onChange={(e) => { setFechaCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Fecha de expiración</label>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <label className="form__label" for="descripcion">Restricción de categorias</label>
                      <select className="form-select" aria-label="Default select example" value={CategoriaCupon} onChange={(e) => { setCategoriaCupon(e.target.value) }}>
                        <option value="0">Seleccionar</option>
                        {Categorias.map((elemento, index)  => (
                          <option key={index} value={elemento.id}>{elemento.descripcion}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form__group col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <label className="form__label" for="descripcion">Tipo</label>
                      <select className="form-select" aria-label="Default select example" value={TipoCupon} onChange={(e) => { setTipoCupon(e.target.value) }}>
                        <option value="0">Seleccionar</option>
                        <option value="1">Personal</option>
                        <option value="2">Masivo</option>
                      </select>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input required="" placeholder="descripcion" className="form__field" type="input" value={PrecioCupon} onChange={(e) => { setPrecioCupon(e.target.value) }}/>
                      <label className="form__label" for="descripcion">Restricción de precio</label>
                    </div>
                    {TipoCupon == 1 &&
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <label className="form__label" for="descripcion">Elegir usuarios</label>
                        <select className="form-select" aria-label="Default select example" value={UsuarioCupon} onChange={(e) => { setUsuarioCupon(e.target.value) }}>
                          <option value="0">Seleccionar</option>
                          {Categorias.map((elemento, index)  => (
                            <option key={index} value={elemento.id}>{elemento.descripcion}</option>
                          ))}
                        </select>
                      </div>
                    } 
                    {TipoCupon == 2 &&
                      <div className="form__group col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        {Selection == true &&
                        <div>
                          <input type="checkbox" id="box-1" name="estado" value={Selection} checked onChange={(e) => { setSelection(e.target.checked) }}/>
                          <label className="checkbox-custom-label" for="box-1">Restringir Productos</label>
                        </div>
                        }
                        {Selection == false &&
                        <div>
                          <input type="checkbox" id="box-1" name="estado" value={Selection} onChange={(e) => { setSelection(e.target.checked) }} />
                          <label className="checkbox-custom-label" for="box-1">Restringir Productos</label>
                        </div>
                        }
                      </div>
                    }
                  </div>
                  {Selection == true &&
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <label className="form__label" for="descripcion">Elegir productos</label>
                      <select className="form-select" aria-label="Default select example" value={ProductoCupon} onChange={(e) => { setProductoCupon(e.target.value) }}>
                        <option value="0">Seleccionar</option>
                        {ProductosAll.map((elemento, index)  => (
                          <option key={index} value={elemento.id}>{elemento.description}</option>
                        ))}
                      </select>
                    </div>
                  } 
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-inverse-success btn-fw">
                    Guardar Cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Cupones