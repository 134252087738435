import React, { useState, useEffect } from 'react';
import { baseUrl, linkServidor } from '../../services/apirest';
import axios from 'axios';
import '../../assets/css/admin.css';
import foto from '../../assets/images/productos/auto.webp';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 
import Swal from 'sweetalert2';

function Pedido() {
  const [Pedido, setPedido] = useState([]);

  const [Actualizarr, setIDActualizar] = useState('');
  const [Nombre, setNombre] = useState('');
  const [NombreEmpresa, setNombreEmpresa] = useState('');
  const [Apellido, setApellido] = useState('');
  const [TipoDocumento, setTipoDocumento] = useState('');
  const [RUC, setRUC] = useState('');
  const [DNI, setDNI] = useState('');
  const [Telefono, setTelefono] = useState('');
  const [Email, setEmail] = useState('');
  const [TipoEnvio, setTipoEnvio] = useState('');
  const [Departamento, setDepartamento] = useState('');
  const [Provincia, setProvincia] = useState('');
  const [Distrito, setDistrito] = useState('');
  const [Direccion, setDireccion] = useState('');
  const [InfoAdicional, setInfoAdicional] = useState('');
  const [EstadoPedido, setEstadoPedido] = useState('');
  const [Ahorro, setAhorro] = useState('');
  const [CostoEnvio, setCostoEnvio] = useState('');
  const [Cupon, setCupon] = useState('');
  const [MontoCupon, setMontoCupon] = useState('');
  const [DescuentoCupon, setDescuentoCupon] = useState('');
  const [TipoCupon, setTipoCupon] = useState('');
  const [TipoPago, setTipoPago] = useState('');
  const [CodigoPago, setCodigoPago] = useState('');
  const [DetallePago, setDetallePago] = useState('');
  const [EstadoPago, setEstadoPago] = useState('');
  const [TokenTransaccion, setTokenTransaccion] = useState('');
  const [UrlPago, setUrlPago] = useState('');
  const [CantidadTotal, setCantidadTotal] = useState('');
  const [SubTotal, setSubTotal] = useState('');
  const [Total, setTotal] = useState('');

  const [ListaProductos, setListaProductos] = useState([]);

  const getPedido = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/pedido`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setPedido(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const verproductos = (edit) =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/pedido/${edit}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      setIDActualizar(response.data.data.websale.id);
      setNombre(response.data.data.websale.nombre);
      setNombreEmpresa(response.data.data.websale.nombre_empresa);
      setApellido(response.data.data.websale.apellido);
      setTipoDocumento(response.data.data.websale.tipo_docuemento);
      setRUC(response.data.data.websale.ruc);
      setDNI(response.data.data.websale.dni);
      setTelefono(response.data.data.websale.telefono);
      setEmail(response.data.data.websale.email);
      setTipoEnvio(response.data.data.websale.tipo_envio);
      setDepartamento(response.data.data.websale.departamento);
      setProvincia(response.data.data.websale.provincia);
      setDistrito(response.data.data.websale.distrito);
      setDireccion(response.data.data.websale.direccion);
      setInfoAdicional(response.data.data.websale.info_adicional);
      setEstadoPedido(response.data.data.websale.estado_pedido);
      setAhorro(response.data.data.websale.ahorro);
      setCostoEnvio(response.data.data.websale.costo_envio);
      setCupon(response.data.data.websale.cupon);
      setMontoCupon(response.data.data.websale.monto_cupon);
      setDescuentoCupon(response.data.data.websale.descuento_cupon);
      setTipoCupon(response.data.data.websale.tipo_cupon);
      setTipoPago(response.data.data.websale.tipo_pago);
      setCodigoPago(response.data.data.websale.codigo_pago);
      setDetallePago(response.data.data.websale.dtalle_pago);
      setEstadoPago(response.data.data.estado_pago);
      setTokenTransaccion(response.data.data.websale.token_transaccion);
      setUrlPago(response.data.data.websale.url_pago);
      setCantidadTotal(response.data.data.websale.cantidad_total);
      setSubTotal(response.data.data.websale.sub_total);
      setTotal(response.data.data.websale.total);
      setListaProductos(response.data.data.websaledetail);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const UpdatePedido = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    fetch(`${baseUrl}/pedido/${Actualizarr}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        estado_pedido : EstadoPedido,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardado con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      getPedido();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const componentDidMount = () => {
    $(document).ready(function() {
      $('#imageInput').on('change', function() {
        let $input = $(this);
        if($input.val().length > 0) {
          let fileReader = new FileReader();
          fileReader.onload = function (data) {
            $('.image-preview').attr('src', data.target.result);
          }
          fileReader.readAsDataURL($input.prop('files')[0]);
          $('.image-button').css('display', 'none');
          $('.image-preview').css('display', 'block');
          $('.change-image').css('display', 'block');
        }
      });
                    
      $('.change-image').on('click', function() {
        let $control = $(this);			
        $('#imageInput').val('');	
        let $preview = $('.image-preview');
        $preview.attr('src', '');
        $preview.css('display', 'none');
        $control.css('display', 'none');
        $('.image-button').css('display', 'block');
      });
      
      $(document).ready(function () {
        $('#example').DataTable();
      });
    });
  }

  useEffect(()=>{
    getPedido();
    componentDidMount();
  }, []);

  return (
    <div className="admin espacio-admin">
      <main className="page-content">
        <h2 className="titulo-admin">MODULO DE PEDIDOS</h2>
        <div className="col-12 button-categoria">
        </div>
        <div className="tabla-admin">
          <table id="example" className="display">
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ID</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>NOMBRE</th>
                <th scope="col" style={{ textAlign: "center", borderTop: "transparent" }}>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ display: "none" }}> 
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
                <td>DASDASDASD</td>
              </tr>
              {Pedido.map((value, index) =>{
                return (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{value.id}</td>
                    <td style={{ textAlign: "center" }}>{value.nombre?value.nombre:value.nombre_empresa}</td>
                    <td style={{ textAlign: "center" }} onClick={()=> {verproductos(value.id)}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" data-bs-toggle="modal" data-bs-target="#exampleModale" height="21" fill="currentColor" className="bi bi-pencil-square edit-admin" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="modal fade" id="exampleModale" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ borderRadius: "10px" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" style={{ margin: "auto" }}>Pedido</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div style={{ margin: "2% 1% 5%" }}>
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Detalle del usuario</button>
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#nav-venta" type="button" role="tab" aria-controls="nav-venta" aria-selected="true">Detalle de la venta</button>
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#nav-product" type="button" role="tab" aria-controls="nav-product" aria-selected="false">Productos</button>
                  </div>
                </nav>
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                  <div className="modal-body">
                    { Nombre != null &&
                      <div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Nombre} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Nombres</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Apellido} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Apellidos</label>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value="DNI" readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Tipo de documento</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={DNI} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>DNI</label>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Telefono} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Teléfono</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Email} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Email</label>
                          </div>
                        </div>
                      </div>
                    } 
                    { NombreEmpresa != null &&
                      <div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={NombreEmpresa} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Empresa</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value="RUC" readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Tipo de documento</label>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={RUC} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>RUC</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Telefono} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Teléfono</label>
                          </div>
                        </div>
                        <div className="form__group field col-12" style={{ padding: "7% 5% 8% 5%" }}>
                          <input placeholder="nombre" className="form__field" type="input" value={Email} readonly/>
                          <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Email</label>
                        </div>
                      </div>
                    } 
                    { TipoEnvio == 1 &&
                      <div style={{ display: "flex" }}>
                        <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                          <input placeholder="nombre" className="form__field" type="input" value="Recojo en Tienda" readonly/>
                          <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Tipo envío</label>
                        </div>
                        <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                          <input placeholder="nombre" className="form__field" type="input" value="Recojo en Tienda" readonly/>
                          <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Dirección</label>
                        </div>
                      </div>
                    } 
                    { TipoEnvio == 2 &&
                      <div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value="Envio a Domicilio" readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Tipo envío</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Departamento} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Departamento</label>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Provincia} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Provincia</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Distrito} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Distrito</label>
                          </div>
                        </div>
                        <div className="form__group field col-12" style={{ padding: "7% 5% 8% 5%" }}>
                          <input placeholder="nombre" className="form__field" type="input" value={Direccion} readonly/>
                          <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Dirección</label>
                        </div>
                        <div className="form__group field col-12" style={{ padding: "7% 5% 8% 5%" }}>
                          <input placeholder="nombre" className="form__field" type="input" value={InfoAdicional} readonly/>
                          <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Infomación adicional</label>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-venta" role="tabpanel" aria-labelledby="nav-venta-tab" tabindex="0">
                  <div className="modal-body">
                    <form onSubmit={(e) => {UpdatePedido(e)}}>
                      <div style={{ display: "flex" }}>
                        <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                          <label className="form__label" for="descripcion">Estado del pedido</label>
                          <select className="form-select" aria-label="Default select example" value={EstadoPedido} onChange={(e) => { setEstadoPedido(e.target.value) }}>
                            <option value="pedido_separado">Pedido separado</option>
                            <option value="pago_confirmado">Pago confirmado</option>
                            <option value="pedido_enviado">Pedido enviado</option>
                            <option value="listo_recojo">Listo recojo</option>
                            <option value="pedido_entregado">Pedido entregado</option>
                            <option value="pedido_anulado">Pedido anulado</option>
                          </select>
                        </div>
                        <div className="form__group field col-6">
                          <div className="button-categoria">
                            <button type="submit" className="btn btn-inverse-success btn-fw">
                              Actualizar estado
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div style={{ display: "flex" }}>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={CostoEnvio} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Costo de envío</label>
                      </div>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={Ahorro} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Ahorro</label>
                      </div>
                    </div>
                    { Cupon != null &&
                      <div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={Cupon} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Cupón</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={MontoCupon} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Monto del cupón</label>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={DescuentoCupon} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Descuento de cupón</label>
                          </div>
                          <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                            <input placeholder="nombre" className="form__field" type="input" value={TipoCupon} readonly/>
                            <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Tipo de cupón</label>
                          </div>
                        </div>
                      </div>
                    }
                    <div style={{ display: "flex" }}>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={TipoPago} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Tipo de pago</label>
                      </div>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={CodigoPago} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Codigo de pago</label>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={DetallePago} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Detalle de pago</label>
                      </div>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={EstadoPago} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Estado de pago</label>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={TokenTransaccion} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Token de transaccion</label>
                      </div>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={UrlPago} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Url de pago</label>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={CantidadTotal} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Cantidad del total</label>
                      </div>
                      <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                        <input placeholder="nombre" className="form__field" type="input" value={SubTotal} readonly/>
                        <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>SubTotal</label>
                      </div>
                    </div>
                    <div className="form__group field col-6" style={{ padding: "7% 5% 8% 5%" }}>
                      <input placeholder="nombre" className="form__field" type="input" value={Total} readonly/>
                      <label className="form__label" for="nombre" style={{ marginTop: "-2%" }}>Total</label>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-product" role="tabpanel" aria-labelledby="nav-product-tab" tabindex="0">
                  {ListaProductos.map((value, index) =>{
                    return (
                      <div className="modal-body" style={{ display: "flex" }}>
                        <div className='col-6'>
                          <img src={foto} width="100%"/>
                        </div>
                        <div className='col-6'>
                          <p style={{ fontSize: "15px", paddingBottom: "6%" }}><strong>{value.descripcion}</strong></p>
                          <p style={{ fontSize: "15px", paddingBottom: "2%" }}>Cantidad: <strong>{value.cantidad}</strong></p>
                          <p style={{ fontSize: "15px", paddingBottom: "2%" }}>Precio de venta: <strong>{value.precio_venta}</strong></p>
                          <p style={{ fontSize: "15px", paddingBottom: "2%" }}>Total: <strong>{value.subtotal}</strong></p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Pedido