import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import '../../assets/css/App.css';
import { Link } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import { ecoUrl, linkServidor, linkCuentas } from '../../services/apirest';
import axios from 'axios';
import tira from '../../assets/images/TIRRAARRIBA24.webp';
import tirasa from '../../assets/images/tiramovil.webp';
import laptop from '../../assets/images/Categorias/laptop.webp';
import pc from '../../assets/images/Categorias/pc.webp';
import teclado from '../../assets/images/Categorias/teclado.webp';
import tinta from '../../assets/images/Categorias/tinta.webp';
import almacenamiento from '../../assets/images/Categorias/almacenamiento.webp';
import impresora from '../../assets/images/Categorias/impresora.webp';
import mouse from '../../assets/images/Categorias/mouse.webp';
import camara from '../../assets/images/Categorias/camara.webp';
import laptop1 from '../../assets/images/Categorias/laptop-1.webp';
import pc1 from '../../assets/images/Categorias/pc-1.webp';
import teclado1 from '../../assets/images/Categorias/teclado-1.webp';
import tinta1 from '../../assets/images/Categorias/tinta-1.webp';
import almacenamiento1 from '../../assets/images/Categorias/almacenamiento-1.webp';
import impresora1 from '../../assets/images/Categorias/impresora-1.webp';
import mouse1 from '../../assets/images/Categorias/mouse-1.webp';
import camara1 from '../../assets/images/Categorias/camara-1.webp';
import whatsapp from '../../assets/images/CategoriasServicios/3-4.webp';
import precios from '../../assets/images/CategoriasServicios/1-4.webp';
import delivery from '../../assets/images/CategoriasServicios/4-4.webp';
import soles from '../../assets/images/CategoriasServicios/2-4.webp';
import facebokv from '../../assets/images/Centro/facebok.png';
import instagramv from '../../assets/images/Centro/instagram.png';
import tiktokv from '../../assets/images/Centro/tiktok.png';
import youtubev from '../../assets/images/Centro/youtube.png';
import facebookmovil from '../../assets/images/Centro/facebookmovil.webp';
import instagrammovil from '../../assets/images/Centro/instagrammovil.webp';
import tktokmovil from '../../assets/images/Centro/tktokmovil.webp';
import youtubemovil from '../../assets/images/Centro/youtubemovil.webp';
import tira2 from '../../assets/images/barra/TIRASOLO3PASOSGRANDE.webp';
import tiramovil2 from '../../assets/images/barra/BARRAICNOSmovil.webp';
import tira3 from '../../assets/images/barra/TIRAGARANTIAGRANDE.webp';
import tira4 from '../../assets/images/barra/TIRAGARANTiAmovil.webp';
import tiralarga from '../../assets/images/barra/bannercupongrande.webp';
import tiralamovil from '../../assets/images/barra/mobilenvio.webp';
import tiraregresoclases from '../../assets/images/barra/tiraregesoaclases.webp';
import subtiragruesamovil from '../../assets/images/barra/tiragruesamovil.webp';
import tiraofertas from '../../assets/images/barra/tiradeoffice.webp';
import tiraofertasmovil from '../../assets/images/barra/tiradeofficemovil.webp';
import LARGO1 from '../../assets/images/Promo/LARGO1.webp';
import LARGO2 from '../../assets/images/Promo/LARGO2.webp';
import LARGO3 from '../../assets/images/Promo/LARGO3.webp';
import NORMAL1 from '../../assets/images/Promo/NORMAL1.webp';
import NORMAL2 from '../../assets/images/Promo/NORMAL2.webp';
import hp1 from '../../assets/images/Marcas/hp1.webp';
import gigabyte from '../../assets/images/Marcas/gigabyte.webp';
import asus from '../../assets/images/Marcas/asus.webp';
import brother from '../../assets/images/Marcas/brother.webp';
import msi from '../../assets/images/Marcas/msi.webp';
import microsoft from '../../assets/images/Marcas/microsoft.webp';
import lenovo from '../../assets/images/Marcas/lenovo.webp';
import apple from '../../assets/images/Marcas/apple.webp';
import logitech from '../../assets/images/Marcas/logitech.webp';
import toshiba from '../../assets/images/Marcas/toshiba.webp';
import hyperx from '../../assets/images/Marcas/hyperx.webp';
import canon from '../../assets/images/Marcas/canon.webp';
import epson from '../../assets/images/Marcas/epson.webp';
import lg from '../../assets/images/Marcas/lg.webp';
import sansumg from '../../assets/images/Marcas/sansumg.webp';
import wacom from '../../assets/images/Marcas/wacom.webp';
import eset from '../../assets/images/Marcas/eset.webp';
import kaspersky from '../../assets/images/Marcas/kaspersky.webp';
import kingston from '../../assets/images/Marcas/kingston.webp';
import western from '../../assets/images/Marcas/western.webp';
import antryx from '../../assets/images/Marcas/antryx.webp';
import redragon from '../../assets/images/Marcas/redragon.webp';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import moment from 'moment';
import Swal from 'sweetalert2';
import algo from '../../assets/images/banners/1.webp';
import zonagamer from '../../assets/images/banners/ZONAGAMER.webp';
import bannersegundo from '../../assets/images/banners/2.webp';
import BANNER from '../../assets/images/banners/BANNERPRNCIPAL.webp';
import bannertercero from '../../assets/images/banners/3.webp';
import bannercuarto from '../../assets/images/banners/4.webp';
import bannersseis from '../../assets/images/banners/6.webp';
import bannerssiete from '../../assets/images/banners/7.webp';
import bannerocho from '../../assets/images/banners/cien.webp';
import iphone from '../../assets/images/banners/iphone.webp';
import bannernueve from '../../assets/images/banners/9.webp';
import Asus1 from '../../assets/images/banners/imgasus1.webp';
import Asus2 from '../../assets/images/banners/imgasus2.webp';
import Asus3 from '../../assets/images/banners/imgasus3.webp';

import asuslogo from '../../assets/images/Marcas/logoasus.webp';
import canonlogo from '../../assets/images/Marcas/logocanon.webp';
import epsonlogo from '../../assets/images/Marcas/logoepson.webp';
import hplogo from '../../assets/images/Marcas/logohp.webp';
import kingstonlogo from '../../assets/images/Marcas/logokingston.webp';
import lglogo from '../../assets/images/Marcas/logolg.webp';
import logitechlogo from '../../assets/images/Marcas/logologitech.webp';
import microsfotlogo from '../../assets/images/Marcas/logomicrosfot.webp';
import msilogo from '../../assets/images/Marcas/logomsi.webp';
import sansumglogo from '../../assets/images/Marcas/logosansumg.webp';
import toshibalogo from '../../assets/images/Marcas/logotoshiba.webp';
import antryxlogo from '../../assets/images/Marcas/logoantryx.webp';
import applelogo from '../../assets/images/Marcas/logoapple.webp';
import brotherlogo from '../../assets/images/Marcas/logobrother.webp';
import esetlogo from '../../assets/images/Marcas/logoeset.webp';
import gigabytelogo from '../../assets/images/Marcas/logogigabyte.webp';
import hyperxlogo from '../../assets/images/Marcas/logohyperx.webp';
import kaperskylogo from '../../assets/images/Marcas/logokapersky.webp';
import lenovologo from '../../assets/images/Marcas/logolenovo.webp';
import redragonlogo from '../../assets/images/Marcas/logoredragon.webp';
import wacomlogo from '../../assets/images/Marcas/logowacom.webp';
import wslogo from '../../assets/images/Marcas/logows.webp';

import BTONCOTIZAPC from '../../assets/images/BTONCOTIZAPC.webp';
import weblaptop from '../../assets/images/weblaptop.webp';
import popup from '../../assets/images/popups/perifericos.webp';

import img1 from '../../assets/images/6imagenes/1.1.webp';
import img2 from '../../assets/images/6imagenes/2.1.webp';
import img3 from '../../assets/images/6imagenes/3.1.webp';
import img4 from '../../assets/images/6imagenes/4.1.webp';
import img5 from '../../assets/images/6imagenes/5.1.webp';
import img6 from '../../assets/images/6imagenes/6.1.webp';


import cupon1 from '../../assets/images/6imagenes/cupon1.webp';
import cupon2 from '../../assets/images/6imagenes/cupon2.webp';
import cupon3 from '../../assets/images/6imagenes/cupon3.webp';

import $ from 'jquery';

function Inicio(){
  const [ProductosByEtiqueta, setProductosByEtiqueta] = useState([]);
  const [ProductosByMundoTecnologico, setProductosByMundoTecnologico] = useState([]);
  const [ProductosByProductosClases, setProductosByProductosClases] = useState([]);
  const [OfertasInsuperables, setOfertasInsuperables] = useState([]);
  const [Compupla, setCompuplaza] = useState([]);
  const [loader, setLoader] = useState(false);
  const [GetallCart, setItem] = useState([]);
  const [GetAllBanners, setGetAllBanners] = useState([]);
  const [Cantidades, setCantidades] = useState();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [Fecha, setFecha] = useState('');
  const [ValidoContador, setValidoContador] = useState('');
  const [TodoClickW, setTodoClickW] = useState({});
  const [TodoClickM, setTodoClickM] = useState({});
  const [MejoresOfertasW, setMejoresOfertasW] = useState({});
  const [MejoresOfertasM, setMejoresOfertasM] = useState({});
  const [EnviosPeruW, setEnviosPeruW] = useState({});
  const [EnviosPeruM, setEnviosPeruM] = useState({});
  const [DescuentoPortatilesW, setDescuentoPortatilesW] = useState({});
  const [Hyperw, setHyperw] = useState({});
  const [Antryxw, setAntryxw] = useState({});
  const [AsusW, setAsusW] = useState({});
  const [Impresorasw, setImpresorasw] = useState({});
  const [GratisLaptopsw, setGratisLaptopsw] = useState({});
  const [GratisLaptopsM, setGratisLaptopsM] = useState({});
  const [CompraRapidow, setCompraRapidow] = useState({});
  const [CompraRapidoM, setCompraRapidoM] = useState({});
  const [ProductosGarantiaW, setProductosGarantiaW] = useState({});
  const [ProductosGarantiaM, setProductosGarantiaM] = useState({});

  let item = JSON.parse(sessionStorage.getItem('carrito'))?JSON.parse(sessionStorage.getItem('carrito')):[];
  let cantidad = JSON.parse(sessionStorage.getItem('cantidad'))?JSON.parse(sessionStorage.getItem('cantidad')):0;

  const GetCart = () =>{
    setItem(item);
    setCantidades(cantidad);
  }
  
  const Modal = () =>{
    $("#example").click();
    if ($('.modal-backdrop').is(':visible')) {
      $('body').removeClass('modal-open'); 
      $('.modal-backdrop').remove(); 
    };
  }

  const getTime = () => {
    const time = moment(Fecha) - moment();
    // const time = moment('2023-10-23') - moment();

    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const getProductosByMundoTecnologico = () =>{
    setLoader(true)
    axios.get(`${ecoUrl}/Store/getProductosByEtiqueta/1`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      setProductosByMundoTecnologico(response.data.data);
      setLoader(false);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getProductosClases = () =>{
    setLoader(true)
    axios.get(`${ecoUrl}/Store/getProductosByEtiqueta/6`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      setProductosByProductosClases(response.data.data);
      setLoader(false);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getOfertasInsuperables = () =>{
    setLoader(true)
    axios.get(`${ecoUrl}/Store/getProductosByEtiqueta/3`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      setOfertasInsuperables(response.data.data);
      setLoader(false);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getCompuplaza = () =>{
    setLoader(true)
    axios.get(`${ecoUrl}/Store/getProductosByEtiqueta/7`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      setCompuplaza(response.data.data);
      setLoader(false);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const adicionarCarrito = (producto) =>{
    let IDencontrado = false;

    item.map((value, index)=>{
      if(value.id == producto.id){
        IDencontrado = true;
        // console.log(producto.stock + producto.stock_lima);
        if (value.cantidad_pd < (producto.stock + producto.stock_lima)) {
          parseInt(cantidad++);

          value.cantidad_pd++;
          GetCart();
          Swal.fire({
            title: 'Producto agregado',
            icon: 'success',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonColor: '#ed571d',
            confirmButtonText: 'Ir al carrito',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonColor: '#0e2f7f',
            cancelButtonText: 'Seguir comprando',
            cancelButtonAriaLabel: 'Thumbs down',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace(`${linkCuentas}/carrito`);
            }
          })
        } else {
          console.log('Sin stock');
        }
      }
    });

    if(!IDencontrado){
      cantidad = parseInt(cantidad+1);
      //console.log(cantidad);
      const product = { ...producto, cantidad_pd: 1, precio_venta_dp: getPriceProduct(producto)};
      GetCart();
      item.push(product);
      Swal.fire({
        title: 'Producto agregado',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonColor: '#ed571d',
        confirmButtonText:
          'Ir al carrito',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonColor: '#0e2f7f',
        cancelButtonText:
          'Seguir comprando',
        cancelButtonAriaLabel: 'Thumbs down'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.replace(`${linkCuentas}/carrito`);
        }
      })
    }

    sessionStorage.setItem("carrito", JSON.stringify(item));
    sessionStorage.setItem('cantidad', JSON.stringify(cantidad));
  }

  const getPriceProduct = (producto) =>{
    let price = 0;
    if (producto.precio_activo == 1) {
      price = producto.precio;
    } else if(producto.precio_activo == 2) {
      price = producto.precio_web;
    }else{
      price = producto.precio_prom;
    }
    return price;
  }

  const getPromocionesFlash = () =>{
    setLoader(true)
    axios.get(`${ecoUrl}/Store/getPromocionesFlash`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response.data);
      console.log(response.data.data);
      console.log(response.data.data.fecha_expiracion);
      setValidoContador(response.data.data);
      setFecha(response.data.data.fecha_expiracion);
      setProductosByEtiqueta(response.data.data.productos);
      setLoader(false);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getAllBanners = () =>{
    axios.get(`${ecoUrl}/Store/getAllBanners`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      // console.log(response.data.data);
      setGetAllBanners(response.data.data);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const getAllTiras = () =>{
    axios.get(`${ecoUrl}/Store/getAllPropaganda`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      console.log(response.data.data);
      setTodoClickW(response.data.data[0]);
      setTodoClickM(response.data.data[1]);
      setMejoresOfertasW(response.data.data[2]);
      setMejoresOfertasM(response.data.data[3]);
      setEnviosPeruW(response.data.data[4]);
      setEnviosPeruM(response.data.data[5]);
      setDescuentoPortatilesW(response.data.data[6]);
      setHyperw(response.data.data[7]);
      setAntryxw(response.data.data[8]);
      setAsusW(response.data.data[9]);
      setImpresorasw(response.data.data[10]);
      setGratisLaptopsw(response.data.data[11]);
      setGratisLaptopsM(response.data.data[12]);
      setCompraRapidow(response.data.data[13]);
      setCompraRapidoM(response.data.data[14]);
      setProductosGarantiaW(response.data.data[15]);
      setProductosGarantiaM(response.data.data[16]);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const componentDidMount = () => {
    $('.ir-arriba').click(function(){
      $('body, html').animate({
        scrollTop: '0px'
      }, 0);
    });

    $(window).scroll(function(){
      if( $(this).scrollTop() > 0 ){
        $('.ir-arriba').slideDown(600);
      } else {
        $('.ir-arriba').slideUp(600);
      }
    });
  }

  useEffect(()=>{
    getPromocionesFlash();
    getOfertasInsuperables();
    getProductosByMundoTecnologico();
    getProductosClases();
    getAllBanners();
    getAllTiras();
    componentDidMount();
    Modal();
    getCompuplaza();
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  // }, ['2023-09-18']);
    }, [Fecha]);

  return(
    <div>
      <Navbar/>
      <div className="tiraweb">
        {TodoClickW.posicion == 1 &&
          <div>
            <div className='tirawebonline1'>
              {TodoClickW.version == 'w' &&
                <div>
                  <Link style={{ display: "grid" }} to={`/${TodoClickW.link}/`}>
                    <LazyLoadImage width="100%" src={linkServidor + '/' + TodoClickW.dir_img} alt="logo" className='tirawebonline1'  effect='opacity'/>
                  </Link>
                </div>
              }
            </div>
            <div className='tirawebonline2'>
              {TodoClickM.version == 'm' &&
                <div>
                  <Link style={{ display: "grid" }} to={`/${TodoClickM.link}/`}>
                    <LazyLoadImage width="100%" src={linkServidor + '/' + TodoClickM.dir_img} alt="logo" className='tirawebonline2'  effect='opacity'/>
                  </Link>
                </div>
              }
            </div>
          </div>
        }
      </div>
      <div className='banners-index'>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{ clickable: true }}
          autoplay={{ delay: 3500, disableOnInteraction: false }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {loader ? 
            <div className="contenedor-productos-principal-escolar contenedor_icon">
              <div className="productos-index">
                <div className="card card--skeleton">
                  <div className="img-cont">
                  
                  </div>
                </div>
              </div>
            </div>
            :GetAllBanners.map((value) =>{
              return (
                <SwiperSlide>
                  <Link to={`/${value.link}/`} style={{ display: "flex" }}>
                    <LazyLoadImage width="100%"  src={linkServidor + '/' + value.dir_img} alt="logo" effect='opacity'/>
                  </Link>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
      {/* <a className="ir-arriba"  href="#" title="Volver arriba">
        <span className="fa-stack">
          <i className="fa fa-circle fa-stack-2x"></i>
          <i className="fa fa-angle-double-up fa-stack-1x fa-inverse" style={{ fontSize: "30px" }}></i>
        </span>
      </a> */}
      {/* <Link to={''} rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" /> */}
      {/* <Link to="/cotizaciones" type="button" className='ButtonCoti btn btn-primary'>
        <img src={BTONCOTIZAPC} style={{ width: "100%" }}/>
      </Link>
      <a href="https://bit.ly/WHATSAPPCOMPUPLAZANEW" className="float" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" style={{ marginTop: "-15%" }} class="bi bi-whatsapp" viewBox="0 0 16 16">
          <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
        </svg>
      </a> */}
      <div className="categorias-principales">
        <Link to={'/search/Portátiles'} className='productos-filtro categorias'>
          <div>
            <LazyLoadImage width="100%" className='primerimagen' src={laptop} style={{ width: "80%" }} alt="logo"/>
            <div style={{ display: "flex" }}>
              <LazyLoadImage width="100%" className='segundaimagen' src={laptop1} style={{ width: "75%" }} alt="logo"/>
              <span className='texto-categoria'>Laptops</span>
            </div>
          </div>
        </Link>
        <Link to={'/cotizaciones'} className='productos-filtro categorias'>
          <div>
            <LazyLoadImage width="100%" className='primerimagen' src={pc} style={{ width: "88%" }} alt="logo"/>
            <div style={{ display: "flex" }}>
              <LazyLoadImage width="100%" className='segundaimagen' src={pc1} style={{ width: "75%" }} alt="logo"/>
              <span className='texto-categoria'>Computadoras</span>
            </div>
          </div>
        </Link>
        <Link to={'/search/Teclado'} className='productos-filtro categorias'>
          <div>
            <LazyLoadImage width="100%" className='primerimagen' src={teclado} style={{ width: "90%" }} alt="logo"/>
            <div style={{ display: "flex" }}>
              <LazyLoadImage width="100%" className='segundaimagen' src={teclado1} style={{ width: "80%" }} alt="logo"/>
              <span className='texto-categoria'>Teclados</span>
            </div>
          </div>
        </Link>
        <Link to={'/search/Tintas'} className='productos-filtro categorias'>
          <div>
            <LazyLoadImage width="100%" className='primerimagen' src={tinta} style={{ width: "90%" }} alt="logo"/>
            <div style={{ display: "flex" }}>
              <LazyLoadImage width="100%" className='segundaimagen' src={tinta1} style={{ width: "80%" }} alt="logo"/>
              <span className='texto-categoria'>Tintas</span>
            </div>
          </div>
        </Link>
        <Link to={'/search/Almacenamiento'} className='productos-filtro categorias'>
          <div>
            <LazyLoadImage width="100%" className='primerimagen' src={almacenamiento} style={{ width: "80%" }} alt="logo"/>
            <div style={{ display: "flex" }}>
              <LazyLoadImage width="100%" className='segundaimagen' src={almacenamiento1} style={{ width: "75%" }} alt="logo"/>
              <span className='texto-categoria'>Almacenamiento</span>
            </div>
          </div>
        </Link>
        <Link to={'/search/Impresoras'} className='productos-filtro categorias'>
          <div>
            <LazyLoadImage width="100%" className='primerimagen' src={impresora} style={{ width: "88%" }} alt="logo"/>
            <div style={{ display: "flex" }}>
              <LazyLoadImage width="100%" className='segundaimagen' src={impresora1} style={{ width: "75%" }} alt="logo"/>
              <span className='texto-categoria'>Impresoras</span>
            </div>
          </div>
        </Link>
        <Link to={'/search/Mouse'} className='productos-filtro categorias'>
          <div>
            <LazyLoadImage width="100%" className='primerimagen' src={mouse} style={{ width: "90%" }} alt="logo"/>
            <div style={{ display: "flex" }}>
              <LazyLoadImage width="100%" className='segundaimagen' src={mouse1} style={{ width: "80%" }} alt="logo"/>
              <span className='texto-categoria'>Mouse</span>
            </div>
          </div>
        </Link>
        <Link to={'/cotizaciones'} className='productos-filtro categorias'>
          <div>
            <LazyLoadImage width="100%" className='primerimagen' src={camara} style={{ width: "90%" }} alt="logo"/>
            <div style={{ display: "flex" }}>
              <LazyLoadImage width="100%" className='segundaimagen' src={camara1} style={{ width: "80%" }} alt="logo"/>
              <span className='texto-categoria'>Cámaras</span>
            </div>
          </div>
        </Link>
      </div>
      <div>
        <div className="servicios-index">
          <Swiper
            slidesPerView={2}
            spaceBetween={0}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              }
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <div>
              <div className="col-3 contenedor-index">
                <SwiperSlide>
                  <LazyLoadImage width="85%" src={precios} alt="logo" effect='opacity'/>
                </SwiperSlide>
              </div>
              <div className="col-3 contenedor-index">
                <SwiperSlide>
                  <LazyLoadImage width="85%" src={soles} alt="logo" effect='opacity'/>
                </SwiperSlide>
              </div>
              <div className="col-3 contenedor-index">
                <SwiperSlide>
                  <LazyLoadImage width="85%" src={whatsapp} alt="logo" effect='opacity'/>
                </SwiperSlide>
              </div>
              <div className="col-3 contenedor-index">
                <SwiperSlide>
                  <LazyLoadImage width="85%" src={delivery} alt="logo" effect='opacity'/>
                </SwiperSlide>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
      {ValidoContador == '' &&
        <div></div>
      }
      {ValidoContador != '' &&
        <div className='col-12 container-style-contador'>
          <div className='poppins-bold contador-style'>
            <div className='contador-style-productos'>
              <span className='texto-contador-tyle'> OFERTAS <br></br> NAVIDEÑAS </span>
              <span style={{ color: "white", fontSize: "23px", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                <div style={{ marginRight: "16%" }}>
                  <span id="hour" style={{ background: "white", color: "#ed571d", padding: "0% 10%", borderRadius: "5px", fontWeight: "600", textAlign: "center", display: "inline-grid" }}>
                    {hours < 10 ? "0" + hours : hours}
                    <span style={{ fontSize: "7px", marginTop: "-32%" }}>Horas</span>
                  </span>
                </div>
                <div style={{ marginRight: "16%" }}>
                  <span id="minute" style={{ background: "white", color: "#ed571d", padding: "0% 10%", borderRadius: "5px", fontWeight: "600", textAlign: "center", display: "inline-grid" }}>
                    {minutes < 10 ? "0" + minutes : minutes}
                    <span style={{ fontSize: "7px", marginTop: "-30%" }}>Minutos</span>
                  </span>
                </div>
                <div>
                  <span id="second" style={{ background: "white", color: "#ed571d", padding: "0% 10%", borderRadius: "5px", fontWeight: "600", textAlign: "center", display: "inline-grid" }}>
                    {seconds < 10 ? "0" + seconds : seconds}
                    <span style={{ fontSize: "7px", marginTop: "-26%" }}>Segundos</span>
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div className='espacio_productos_promo'>
            <div className="contenedor-productos-principal mtop">
              <Swiper
                slidesPerView={2}
                spaceBetween={1}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                navigation={true}
                loop={true}
                breakpoints={{
                  628: {
                    slidesPerView: 2,
                    spaceBetween: 1,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 1,
                  },
                  1300: {
                    slidesPerView: 4,
                    spaceBetween: 1,
                  },
                  1860: {
                    slidesPerView: 5,
                    spaceBetween: 1,
                  },
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                {loader ? 
                  <div className="contenedor-producto-principal contenedor_icon">
                    <div className="productos-index">
                      <div className="card card--skeleton">
                        <div className="img-cont">
                        
                        </div>
                      </div>
                    </div>
                  </div>
                :ProductosByEtiqueta.map((value, index) =>{
                  return (
                    <SwiperSlide>
                      <div key={index} className="contenedor-producto-principal contenedor_icon">
                        <div className="productos-index">
                          <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black", display: "grid" }}>
                            <LazyLoadImage width="100%" src={linkServidor + '/' + value.dir_img_pri} alt="logo" effect='opacity'/>
                          </Link>
                          <div className="panel">
                            <div className="con-tooltip right">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-heart-fill icon_product" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                              </svg>
                              <div className="tooltip ">
                                <p style={{ margin: "0px", textAlign: "center", color: "white" }}>Favorito</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black" }}>
                            <div className="nombre-producto-principal dinproRegular">
                              {value.description}
                            </div>
                          </Link>
                          {value.precio_activo == 3 &&
                            <div className='fondo_precios'>
                              <p className="precio-online"> 
                                <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_prom / value.precio))*100)}%</span> DSCTO</span>
                                <span className='poppins-medium oferta-ciento'>S/ {value.precio_prom}</span>
                              </p>
                              <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                                <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                                  <p className="precio-normal poppins-regular precio-general">
                                    ONLINE: <span className="rallaparaprecio poppins-regular">S/ {value.precio_web}</span>
                                  </p>
                                </div>
                                <div className='col-4' style={{ justifyContent: "start", padding: "0" }}>
                                  <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                    NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          }
                          {value.precio_activo == 2 &&
                            <div className='fondo_precios'>
                              <p className="precio-online"> 
                                <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_web / value.precio))*100)}%</span> DSCTO</span>
                                <span className='poppins-medium oferta-ciento'>S/ {value.precio_web}</span>
                              </p>
                              <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                                <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                                </div>
                                <div className='col-4' style={{ justifyContent: "start", padding: "0" }}>
                                  <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                    NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          }
                          <div className='fondo_precios'>
                            {(value.stock + value.stock_lima) == 0 &&
                              <button type="button" className="btn btn-stock" style={{ width: "auto", fontSize: "13px", marginBottom: "10%" }} >SIN STOCK</button>
                            }
                            {(value.stock + value.stock_lima) != 0 &&
                              <button type="button" className="btn btn-resumen" style={{ width: "auto", fontSize: "13px" }} onClick={()=>adicionarCarrito(value)}>Agregar al carrito</button>
                            }
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </div>
      }

      {/* <div className='col-12 contador_navidad'>
        <div className='col-lg-4 col-md-4 col-sm-6 col-6 contenedor_img_navidad'>
          <div className='col-10 contenedores_img_navidad'>
            <LazyLoadImage src={weblaptop} alt="logo" className='img_navidad' effect='opacity'/>
          </div>
        </div>
        <div className='col-lg-8 col-md-8 col-sm-6 col-6' style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {ValidoContador == '' &&
            <div></div>
          }
          {ValidoContador != '' &&
            <div className='col-12 container-style-contador'>
              <div className='poppins-bold contador-style'>
                <div className='contador-style-productos'>
                  <span className='texto-contador-tyle'> OFERTAS <br></br> NAVIDEÑAS </span>
                  <span style={{ color: "white", fontSize: "23px", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ marginRight: "16%" }}>
                      <span id="hour" style={{ background: "white", color: "#ed571d", padding: "0% 10%", borderRadius: "5px", fontWeight: "600", textAlign: "center", display: "inline-grid" }}>
                        {hours < 10 ? "0" + hours : hours}
                        <span style={{ fontSize: "7px", marginTop: "-32%" }}>Horas</span>
                      </span>
                    </div>
                    <div style={{ marginRight: "16%" }}>
                      <span id="minute" style={{ background: "white", color: "#ed571d", padding: "0% 10%", borderRadius: "5px", fontWeight: "600", textAlign: "center", display: "inline-grid" }}>
                        {minutes < 10 ? "0" + minutes : minutes}
                        <span style={{ fontSize: "7px", marginTop: "-30%" }}>Minutos</span>
                      </span>
                    </div>
                    <div>
                      <span id="second" style={{ background: "white", color: "#ed571d", padding: "0% 10%", borderRadius: "5px", fontWeight: "600", textAlign: "center", display: "inline-grid" }}>
                        {seconds < 10 ? "0" + seconds : seconds}
                        <span style={{ fontSize: "7px", marginTop: "-26%" }}>Segundos</span>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div className='espacio_productos_web'>
                <div className="contenedor-productos-principal mtop">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={1}
                    // autoplay={{ delay: 2500, disableOnInteraction: false }}
                    navigation={true}
                    loop={true}
                    breakpoints={{
                      628: {
                        slidesPerView: 1,
                        spaceBetween: 1,
                      },
                      767: {
                        slidesPerView: 2,
                        spaceBetween: 1,
                      },
                      1200: {
                        slidesPerView: 2,
                        spaceBetween: 1,
                      },
                      1300: {
                        slidesPerView: 3,
                        spaceBetween: 1,
                      },
                      1860: {
                        slidesPerView: 3,
                        spaceBetween: 1,
                      },
                    }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                  >
                    {loader ? 
                      <div className="contenedor-producto-principal contenedor_icon">
                        <div className="productos-index">
                          <div className="card card--skeleton">
                            <div className="img-cont">
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    :ProductosByEtiqueta.map((value, index) =>{
                      return (
                        <SwiperSlide>
                          <div key={index} className="contenedor-producto-principal contenedor_icon">
                            <div className="productos-index">
                              <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black", display: "grid" }}>
                                <LazyLoadImage width="100%" src={linkServidor + '/' + value.dir_img_pri} alt="logo" effect='opacity'/>
                              </Link>
                              <div className="panel">
                                <div className="con-tooltip right">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-heart-fill icon_product" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                  </svg>
                                  <div className="tooltip ">
                                    <p style={{ margin: "0px", textAlign: "center", color: "white" }}>Favorito</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black" }}>
                                <div className="nombre-producto-principal dinproRegular">
                                  {value.description}
                                </div>
                              </Link>
                              {value.precio_activo == 3 &&
                                <div className='fondo_precios'>
                                  <p className="precio-online"> 
                                    <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_prom / value.precio))*100)}%</span> DSCTO</span>
                                    <span className='poppins-medium oferta-ciento'>S/ {value.precio_prom}</span>
                                  </p>
                                  <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                                    <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                                      <p className="precio-normal poppins-regular precio-general">
                                        ONLINE: <span className="rallaparaprecio poppins-regular">S/ {value.precio_web}</span>
                                      </p>
                                    </div>
                                    <div className='col-4' style={{ justifyContent: "start", padding: "0" }}>
                                      <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                        NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              }
                              {value.precio_activo == 2 &&
                                <div className='fondo_precios'>
                                  <p className="precio-online"> 
                                    <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_web / value.precio))*100)}%</span> DSCTO</span>
                                    <span className='poppins-medium oferta-ciento'>S/ {value.precio_web}</span>
                                  </p>
                                  <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                                    <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                                    </div>
                                    <div className='col-4' style={{ justifyContent: "start", padding: "0" }}>
                                      <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                        NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              }
                              <div className='fondo_precios'>
                                {(value.stock + value.stock_lima) == 0 &&
                                  <button type="button" className="btn btn-stock" style={{ width: "auto", fontSize: "13px", marginBottom: "0%" }} >SIN STOCK</button>
                                }
                                {(value.stock + value.stock_lima) != 0 &&
                                  <button type="button" className="btn btn-resumen" style={{ width: "auto", fontSize: "13px" }} onClick={()=>adicionarCarrito(value)}>Agregar al carrito</button>
                                }
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          }
        </div>
      </div> */}

      <div className='mundo-tecnologico'>
        <div className="contenedor-productos-principal escolar-imagenes" style={{ paddingTop: "0%" }}>
          <Swiper
            slidesPerView={2}
            spaceBetween={1}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            breakpoints={{
              628: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 1,
              },
              1300: {
                slidesPerView: 6,
                spaceBetween: 1,
              },
              1860: {
                slidesPerView: 6,
                spaceBetween: 1,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {loader ? 
              <div className="contenedor-productos-principal-escolar contenedor_icon">
                <div className="productos-index">
                  <div className="card card--skeleton">
                    <div className="img-cont">
                    
                    </div>
                  </div>
                </div>
              </div>
            :Compupla.map((value, index) =>{
              return (
                <SwiperSlide>
                  <div key={index} className="contenedor-productos-index contenedor_icon">
                    <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black", display: "grid" }}>
                      <LazyLoadImage width="100%"  src={linkServidor + '/' + value.dir_img_sec} alt="logo" effect='opacity'/>
                    </Link>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>

      <div className='mundo-tecnologico'>
        <div>
          <h2 className="titulo-productos-principal poppins-bold">¡DISFRUTA DEL MUNDO TECNOLÓGICO!</h2>
        </div>
        <div className="contenedor-productos-principal" style={{ padding: "0% 0% 1%" }}>
          <Swiper
            slidesPerView={2}
            spaceBetween={1}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            navigation={true}
            loop={true}
            breakpoints={{
              628: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 1,
              },
              1300: {
                slidesPerView: 4,
                spaceBetween: 1,
              },
              1860: {
                slidesPerView: 5,
                spaceBetween: 1,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {loader ? 
              <div className="contenedor-producto-principal contenedor_icon">
                <div className="productos-index">
                  <div className="card card--skeleton">
                    <div className="img-cont">
                    
                    </div>
                  </div>
                </div>
              </div>
            :ProductosByMundoTecnologico.map((value, index) =>{
              return (
                <SwiperSlide>
                  <div key={index} className="contenedor-producto-principal contenedor_icon">
                    <div className="productos-index">
                      <div className='espacionombreindex'>
                        <span className='avertaRegular contenedornombreindex'>
                          <span className='avertaBold numeronombreindex'>{Math.round((1-(value.precio_prom / value.precio))*100)}</span>
                          <div className='avertaBold' style={{ display: "grid", marginLeft: "5%" }}>
                            <span className='porcentajenombreindex'>%</span>
                            <span className='descuentonombreindex'>Dscto</span>
                          </div>
                        </span>
                      </div>
                      <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black", display: "grid" }}>
                        <LazyLoadImage width="100%" src={linkServidor + '/' + value.dir_img_pri} alt="logo" effect='opacity'/>
                      </Link>
                      <div className="panel">
                        <div className="con-tooltip right">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-heart-fill icon_product" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                          </svg>
                          <div className="tooltip ">
                            <p style={{ margin: "0px", textAlign: "center", color: "white" }}>Favorito</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black" }}>
                        <div className="nombre-producto-principal dinproRegular">
                          {value.description}
                        </div>
                      </Link>
                      {value.precio_activo == 3 &&
                        <div className='fondo_precios'>
                          <p>
                            <span className="avertaBold" style={{ fontSize: "18px", background: "#001267", padding: "1% 4%", borderRadius: "10px", color: "white"  }}>S/ {value.precio_prom}</span>
                          </p>
                          <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                            ONLINE <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio_web}</span>
                          </p>
                          <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                            NORMAL <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio}</span>
                          </p>

                          {/* <p className="precio-online"> 
                            <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_prom / value.precio))*100)}%</span> DSCTO</span>
                            <span className='poppins-medium oferta-ciento'>S/ {value.precio_prom}</span>
                          </p>
                          <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                            <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                              <p className="precio-normal poppins-regular precio-general">
                                ONLINE: <span className="rallaparaprecio poppins-regular">S/ {value.precio_web}</span>
                              </p>
                            </div>
                            <div className='col-4' style={{ justifyContent: "start", display: "flex" }}>
                              <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                              </p>
                            </div>
                          </div> */}
                        </div>
                      }
                      {value.precio_activo == 2 &&
                        <div className='fondo_precios'>
                          <p>
                            <span className="avertaBold" style={{ fontSize: "18px", background: "#001267", padding: "1% 4%", borderRadius: "10px", color: "white"  }}>S/ {value.precio_web}</span>
                          </p>
                          <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                            NORMAL <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio}</span>
                          </p>

                          {/* <p className="precio-online"> 
                            <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_web / value.precio))*100)}%</span> DSCTO</span>
                            <span className='poppins-medium oferta-ciento'>S/ {value.precio_web}</span>
                          </p>
                          <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                            <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                            </div>
                            <div className='col-4' style={{ justifyContent: "start", display: "flex" }}>
                              <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                              </p>
                            </div>
                          </div> */}
                        </div>
                      }
                      <div className='fondo_precios'>
                        {(value.stock + value.stock_lima) == 0 &&
                          <button type="button" className="btn btn-stock" style={{ width: "auto", fontSize: "13px" }}>SIN STOCK</button>
                        }
                        {(value.stock + value.stock_lima) != 0 &&
                          <button type="button" class="btn btn-resumen" style={{ width: "auto", fontSize: "13px" }} onClick={()=>adicionarCarrito(value)}>Agregar al carrito</button>
                        }
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>

      {MejoresOfertasW.posicion == 2 &&
        <div>
          <div className="tiracentroweb" style={{ margin: "3% 0% 2%" }}>
            {MejoresOfertasW.version == 'w' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${MejoresOfertasW.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + MejoresOfertasW.dir_img} alt="logo" effect='opacity'/>
                </Link>
              </div>
            }
          </div>
          <div className="tiracentrowebsubmovil" style={{ margin: "3% 0% 2%" }}>
            {MejoresOfertasM.version == 'm' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${MejoresOfertasM.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + MejoresOfertasM.dir_img} alt="logo" effect='opacity'/>
                </Link>
              </div>
            }
          </div>
        </div>
      }
      
      {/* <div className="tiracentroweb" style={{ margin: "3% 0% 2%" }}>
        <Link style={{ display: "grid" }} to={`/search/todoenunclick`}>
          <LazyLoadImage width="100%" src={tiraregresoclases} alt="logo" effect='opacity'/>
        </Link>
      </div>
      <div className="tiracentrowebsubmovil" style={{ margin: "3% 0% 2%" }}>
        <Link style={{ display: "grid" }} to={`/search/todoenunclick`}>
          <LazyLoadImage width="100%" src={subtiragruesamovil} alt="logo" effect='opacity'/>
        </Link>
      </div> */}

      <div className='mundo-tecnologico'>
        <div style={{ margin: "2%" }}>
          <h2 className="titulo-productos-principal poppins-bold" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>¡LAS MEJORES OFERTAS!
            <Link to={`/search/mejoresofertas`} type="button" className="btn btn-resumen" style={{ width: "auto", fontSize: "13px", marginBottom: "0px", marginLeft: "1%" }} >Ver Todo</Link>
          </h2>
        </div>
        <div className="contenedor-productos-principal escolar-imagenes" style={{ paddingTop: "0%" }}>
          <Swiper
            slidesPerView={2}
            spaceBetween={1}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            breakpoints={{
              628: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 1,
              },
              1300: {
                slidesPerView: 4,
                spaceBetween: 1,
              },
              1860: {
                slidesPerView: 5,
                spaceBetween: 1,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {loader ? 
              <div className="contenedor-productos-principal-escolar contenedor_icon">
                <div className="productos-index">
                  <div className="card card--skeleton">
                    <div className="img-cont">
                    
                    </div>
                  </div>
                </div>
              </div>
            :ProductosByProductosClases.map((value, index) =>{
              return (
                <SwiperSlide>
                  <div key={index} className="contenedor-productos-principal-escolar contenedor_icon">
                    <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black", display: "grid" }}>
                      <LazyLoadImage width="100%"  src={linkServidor + '/' + value.dir_img_sec} alt="logo" effect='opacity'/>
                    </Link>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>

      {EnviosPeruW.posicion == 3 &&
        <div>
          <div className="tiracentroweb" style={{ margin: "3% 0% 2%" }}>
            {EnviosPeruW.version == 'w' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${EnviosPeruW.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + EnviosPeruW.dir_img} alt="logo" effect='opacity'/>
                </Link>
              </div>
            }
          </div>
          <div className="tiracentrowebsubmovil" style={{ margin: "3% 0% 2%" }}>
            {EnviosPeruM.version == 'm' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${EnviosPeruM.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + EnviosPeruM.dir_img} alt="logo" effect='opacity'/>
                </Link>
              </div>
            }
          </div>
        </div>
      }

      {/* <div className="tiracentroweb">
        <LazyLoadImage width="100%"  src={tiralarga} alt="logo" effect='opacity'/>
      </div>
      <div className="tiralagamovil">
        <LazyLoadImage width="100%" src={tiralamovil} alt="logo" effect='opacity'/>
      </div> */}

      <div style={{ padding: "0% 4%" }}>
        <div style={{ width: "100%" }}>
          <div className='row'>
            <div className='col-sm-12 col-md-6'>
              {DescuentoPortatilesW.posicion == 4 &&
                <div>
                  <div className='computadora-index-publi'>
                    {DescuentoPortatilesW.version == 'w' &&
                      <div>
                        <Link style={{ display: "grid" }} to={`/${DescuentoPortatilesW.link}/`}>
                          <LazyLoadImage width="100%" src={linkServidor + '/' + DescuentoPortatilesW.dir_img} alt="logo" effect='opacity'/>
                        </Link>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
            <div className='col-sm-12 col-md-6' style={{ display: "flex", paddingRight: "0px" }}>
              {Hyperw.posicion == 5 &&
                <div style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                  {Hyperw.version == 'w' &&
                    <div>
                      <Link style={{ display: "grid" }} to={`/${Hyperw.link}/`}>
                        <LazyLoadImage width="100%" src={linkServidor + '/' + Hyperw.dir_img} style={{ width: "95%", height: "100%" }} effect='opacity'/>
                      </Link>
                    </div>
                  }
                </div>
              }
              {Antryxw.posicion == 6 &&
                <div style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                  {Antryxw.version == 'w' &&
                    <div>
                      <Link style={{ display: "grid" }} to={`/${Antryxw.link}/`}>
                        <LazyLoadImage width="100%" src={linkServidor + '/' + Antryxw.dir_img} style={{ width: "95%", height: "100%" }} effect='opacity'/>
                      </Link>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
          <div className='row' style={{ marginTop: "2%", marginBottom: "3%" }}>
            <div className='col-sm-12 col-md-6'>
              {AsusW.posicion == 7 &&
                <div className='computadora-index-publi'>
                  {AsusW.version == 'w' &&
                    <div>
                      <Link style={{ display: "grid" }} to={`/${AsusW.link}/`}>
                        <LazyLoadImage width="100%" src={linkServidor + '/' + AsusW.dir_img} effect='opacity'/>
                      </Link>
                    </div>
                  }
                </div>
              }
            </div>
            <div className='col-sm-12 col-md-6'>
              {Impresorasw.posicion == 8 &&
                <div>
                  {Impresorasw.version == 'w' &&
                    <div>
                      <Link style={{ display: "grid" }} to={`/${Impresorasw.link}/`}>
                        <LazyLoadImage width="100%" src={linkServidor + '/' + Impresorasw.dir_img} effect='opacity'/>
                      </Link>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {GratisLaptopsw.posicion == 9 &&
        <div>
          <div className='laptopsgratis'>
            {GratisLaptopsw.version == 'w' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${GratisLaptopsw.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + GratisLaptopsw.dir_img} style={{ width: "100%" }} effect='opacity'/>
                </Link>
              </div>
            }
          </div>
          <div className='laptopsgratismovil'>
            {GratisLaptopsM.version == 'm' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${GratisLaptopsM.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + GratisLaptopsM.dir_img} style={{ width: "100%" }} effect='opacity'/>
                </Link>
              </div>
            }
          </div>
        </div>
      }
            
      {/* <div className='laptopsgratis'>
        <LazyLoadImage width="100%" src={tiraofertas}/>
      </div>
      <div className='laptopsgratismovil'>
        <LazyLoadImage width="100%" src={tiraofertasmovil} style={{ width: "100%" }} effect='opacity'/>
      </div> */}

      <div>
        <div className="row marco-productos-secundarios">
          <div className="col-12" style={{ margin: "auto" }}>
            <div style={{ margin: "2%" }}>
              <h2 className="titulo-productos-principal poppins-bold" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                ¡OFERTAS INSUPERABLES! 
                <Link to={`/search/ofertasinsuperables`} type="button" className="btn btn-resumen" style={{ width: "auto", fontSize: "13px", marginBottom: "0px", marginLeft: "1%" }}>Ver Todo</Link>
              </h2>
            </div>
            {/* <div style={{ border: "1px solid #ed571d", padding: "2%", borderRadius: "30px" }}> */}
            <div>
              {/* <Link to={'/search/OfertasInsuperables'} className='todoproducto'><div>Ver todo</div></Link> */}
              <Swiper
                slidesPerView={2}
                spaceBetween={1}
                // autoplay={{ delay: 2500, disableOnInteraction: false }}
                navigation={true}
                loop={true}
                breakpoints={{
                  628: {
                    slidesPerView: 2,
                    spaceBetween: 1,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 1,
                  },
                  1300: {
                    slidesPerView: 4,
                    spaceBetween: 1,
                  },
                  1860: {
                    slidesPerView: 5,
                    spaceBetween: 1,
                  },
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                {loader ? 
                  <div className="contenedor-producto-principal contenedor_icon">
                    <div className="productos-index">
                      <div className="card card--skeleton">
                        <div className="img-cont">
                        
                        </div>
                      </div>
                    </div>
                  </div>
                :OfertasInsuperables.map((value, index) =>{
                  return (
                    <SwiperSlide>
                      {/* <div key={index} className="contenedor-producto-principal contenedor_icon"> */}
                      <div key={index} className="contenedor-producto-principal contenedor_icon" style={{ border: "1px solid #0e2f7f", cursor: "pointer", borderRadius: "5px" }}>
                        <div className="productos-index">
                          <div className='espacionombreindex'>
                            <span className='avertaRegular contenedornombreindex'>
                              <span className='avertaBold numeronombreindex'>{Math.round((1-(value.precio_prom / value.precio))*100)}</span>
                              <div className='avertaBold' style={{ display: "grid", marginLeft: "5%" }}>
                                <span className='porcentajenombreindex'>%</span>
                                <span className='descuentonombreindex'>Dscto</span>
                              </div>
                            </span>
                          </div>
                          <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black", display: "grid" }}>
                            <LazyLoadImage width="100%" src={linkServidor + '/' + value.dir_img_pri} alt="logo"  effect='opacity'/>
                          </Link>
                          <div className="panel">
                            <div className="con-tooltip right">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-heart-fill icon_product" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                              </svg>
                              <div className="tooltip ">
                                <p style={{ margin: "0px", textAlign: "center", color: "white" }}>Favorito</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Link to={`/${value.description.split(" ").join("-")}/${value.id}/p`} style={{ textDecoration: "none", color: "black" }}>
                            <div className="nombre-producto-principal dinproRegular">
                              {value.description}
                            </div>
                          </Link>
                          {value.precio_activo == 3 &&
                            <div className='fondo_precios'>
                              <p>
                                <span className="avertaBold" style={{ fontSize: "18px", background: "#001267", padding: "1% 4%", borderRadius: "10px", color: "white"  }}>S/ {value.precio_prom}</span>
                              </p>
                              <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                                ONLINE <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio_web}</span>
                              </p>
                              <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                                NORMAL <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio}</span>
                              </p>

                              {/* <p className="precio-online"> 
                                <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_prom / value.precio))*100)}%</span> DSCTO</span>
                                <span className='poppins-medium oferta-ciento'>S/ {value.precio_prom}</span>
                              </p>
                              <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                                <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                                  <p className="precio-normal poppins-regular precio-general">
                                    ONLINE: <span className="rallaparaprecio poppins-regular">S/ {value.precio_web}</span>
                                  </p>
                                </div>
                                <div className='col-4' style={{ justifyContent: "start", padding: "0" }}>
                                  <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                    NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                          }
                          {value.precio_activo == 2 &&
                            <div className='fondo_precios'>
                              <p>
                                <span className="avertaBold" style={{ fontSize: "18px", background: "#001267", padding: "1% 4%", borderRadius: "10px", color: "white"  }}>S/ {value.precio_web}</span>
                              </p>
                              <p className="avertaRegular" style={{ fontSize: "11px", marginBottom: "1%", color: "#0e2f7f", lineHeight: "1" }}>
                                NORMAL <span style={{ fontSize: "11px", color: "#ed571d" }} className="rallaparaprecio avertaRegular">S/ {value.precio}</span>
                              </p>
                              {/* <p className="precio-online"> 
                                <span className='poppins-bold oferta-producto'><span className='ciento'>{Math.round((1-(value.precio_web / value.precio))*100)}%</span> ONLINE</span>
                                <span className='poppins-medium oferta-ciento'>S/ {value.precio_web}</span>
                              </p>
                              <div className='col-12' style={{ display: "flex", padding: "0", margin: "0", justifyContent: "center" }}>
                                <div className='col-4' style={{ justifyContent: "end", padding: "0" }}>
                                </div>
                                <div className='col-4' style={{ justifyContent: "start", padding: "0" }}>
                                  <p className="precio-normal poppins-regular precio-general" style={{ marginTop: "0%", lineHeight: "1" }}>
                                    NORMAL: <span className="rallaparaprecio poppins-regular">S/ {value.precio}</span>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                          }
                        </div>
                        <div className='fondo_precios'>
                          {(value.stock + value.stock_lima) == 0 &&
                            <button type="button" className="btn btn-stock" style={{ width: "auto", fontSize: "13px" }} >SIN STOCK</button>
                          }
                          {(value.stock + value.stock_lima) != 0 &&
                            <button type="button" className="btn btn-resumen" style={{ width: "auto", fontSize: "13px" }} onClick={()=>adicionarCarrito(value)}>Agregar al carrito</button>
                          }
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {CompraRapidow.posicion == 10 &&
        <div>
          <div className="tiralarga">
            {CompraRapidow.version == 'w' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${CompraRapidow.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + CompraRapidow.dir_img} alt="logo" effect='opacity'/>
                </Link>
              </div>
            }
          </div>
          <div className="tiralargamovil">
            {CompraRapidoM.version == 'm' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${CompraRapidoM.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + CompraRapidoM.dir_img} alt="logo" effect='opacity'/>
                </Link>
              </div>
            }
          </div>
        </div>
      }

      {/* <div>
        <div className="tiralarga">
          <LazyLoadImage width="100%" src={tira2} alt="logo" effect='opacity'/>
        </div>
        <div className="tiralargamovil">
          <LazyLoadImage width="100%" src={tiramovil2} alt="logo" effect='opacity'/>
        </div>
      </div> */}

      <div>
        <div>
          <h2 className="titulo-redes poppins">MARCAS</h2>
        </div>
        <div style={{ margin: "0% 6%" }}>
          <div className="contenedor-productos-principal escolar-imagenes" style={{ paddingTop: "0%" }}>
            <Swiper
              slidesPerView={2}
              spaceBetween={1}
              autoplay={{ delay: 1000, disableOnInteraction: false }}
              loop={true}
              breakpoints={{
                628: {
                  slidesPerView: 3,
                  spaceBetween: 1,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 1,
                },
                1300: {
                  slidesPerView: 4,
                  spaceBetween: 1,
                },
                1860: {
                  slidesPerView: 4,
                  spaceBetween: 1,
                },
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/redragon`}>
                    <LazyLoadImage width="100%"  src={redragon} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/antryx`}>
                    <LazyLoadImage width="100%"  src={antryx} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/hp`}>
                    <LazyLoadImage width="100%"  src={hp1} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/gigabyte`}>
                    <LazyLoadImage width="100%"  src={gigabyte} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/asus`}>
                    <LazyLoadImage width="100%"  src={asus} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/brother`}>
                    <LazyLoadImage width="100%"  src={brother} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/msi`}>
                    <LazyLoadImage width="100%"  src={msi} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/microsoft`}>
                    <LazyLoadImage width="100%"  src={microsoft} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/lenovo`}>
                    <LazyLoadImage width="100%"  src={lenovo} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/apple`}>
                    <LazyLoadImage width="100%"  src={apple} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/logitech`}>
                    <LazyLoadImage width="100%"  src={logitech} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/toshiba`}>
                    <LazyLoadImage width="100%"  src={toshiba} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/hyperx`}>
                    <LazyLoadImage width="100%"  src={hyperx} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/canon`}>
                    <LazyLoadImage width="100%"  src={canon} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/epson`}>
                    <LazyLoadImage width="100%"  src={epson} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/lg`}>
                    <LazyLoadImage width="100%"  src={lg} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/samsung`}>
                    <LazyLoadImage width="100%"  src={sansumg} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/wacom`}>
                    <LazyLoadImage width="100%"  src={wacom} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/eset`}>
                    <LazyLoadImage width="100%"  src={eset} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/kaspersky`}>
                    <LazyLoadImage width="100%"  src={kaspersky} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/kingston`}>
                    <LazyLoadImage width="100%"  src={kingston} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="contenedor-productos-index contenedor_icon">
                  <Link to={`/search/western`}>
                    <LazyLoadImage width="100%"  src={western} alt="logo" effect='opacity'/>
                  </Link>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "3%" }}>
        <Swiper
          slidesPerView={2}
          slidesPerGroup={1}
          spaceBetween={0}
          loopFillGroupWithBlank={true}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          loop={true}
          modules={[Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 6,
            }
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/redragon'>
               <img src={redragonlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "50%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/antryx'>
                <img src={antryxlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/hp'>
                <img src={hplogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/gigabyte'>
                <img src={gigabytelogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/asus'>
                <img src={asuslogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/brother'>
                <img src={brotherlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/msi'>
                <img src={msilogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/microsoft'>
                <img src={microsfotlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/lenovo'>
                <img src={lenovologo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/apple'>
                <img src={applelogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/logitech'>
                <img src={logitechlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/toshiba'>
                <img src={toshibalogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "30%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/hyperx'>
                <img src={hyperxlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/canon'>
                <img src={canonlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/epson'>
                <img src={epsonlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/lg'>
                <img src={lglogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/samsung'>
                <img src={sansumglogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/wacom'>
                <img src={wacomlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/eset'>
                <img src={esetlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/kaspersky'>
                <img src={kaperskylogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/kingston'>
                <img src={kingstonlogo}/>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ width: "40%", margin: "auto", cursor: "pointer" }}>
              <a href='/search/western'>
                <img src={wslogo}/>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div>
        <div>
          <h2 className="titulo-redes poppins">REDES</h2>
        </div>
        <div className="contenedor-redes">
          <div className="col-2 contenedores-redes">
            <a href='https://www.tiktok.com/@compuplazaperu' target="_blank">
              <img width="100%" src={tiktokv} alt="logo" effect='opacity'/>
            </a>
          </div>
          <div className="col-2 contenedores-redes">
            <a href='https://www.instagram.com/compuplazaperu/' target="_blank">
              <img width="100%" src={instagramv} alt="logo" effect='opacity'/>
            </a>
          </div>
          <div className="col-3 contenedores-redes">
            <a href='https://www.facebook.com/CompuplazaPeru' target="_blank">
              <img width="100%" src={facebokv} alt="logo" effect='opacity'/>
            </a>
          </div>
          <div className="col-5 contenedores-redes">
            <a href='https://www.youtube.com/@compuplazaperu2885' target="_blank">
              <img width="100%" src={youtubev} alt="logo" effect='opacity'/>
            </a>
          </div>
        </div>
        <div className='button-redes-index'>
          <div className="col-3">
            <center>
              <a href='https://www.tiktok.com/@compuplazaperu' target="_blank">
                <img className='redesmovil' src={tktokmovil} alt="logo" effect='opacity'/>
              </a>
            </center>
          </div>
          <div className="col-3">
            <center>
              <a href='https://www.instagram.com/compuplazaperu/' target="_blank">
                <img className='redesmovil' src={instagrammovil} alt="logo" effect='opacity'/>
              </a>
            </center>
          </div>
          <div className="col-3">
            <center>
              <a href='https://www.facebook.com/CompuplazaPeru' target="_blank">
                <img className='redesmovil' src={facebookmovil} alt="logo" effect='opacity'/>
              </a>
            </center>
          </div>
          <div className="col-3">
            <center>
              <a href='https://www.youtube.com/@compuplazaperu2885' target="_blank">
                <img className='redesmovil' src={youtubemovil} alt="logo" effect='opacity'/>
              </a>
            </center>
          </div>
        </div>
      </div>

      {ProductosGarantiaW.posicion == 11 &&
        <div>
          <div className="tira-footer">
            {ProductosGarantiaW.version == 'w' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${ProductosGarantiaW.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + ProductosGarantiaW.dir_img} alt="logo" effect='opacity'/>
                </Link>
              </div>
            }
          </div>
          <div className="subtira-footer" style={{ padding: "6% 0% 3%" }}>
            {ProductosGarantiaM.version == 'm' &&
              <div>
                <Link style={{ display: "grid" }} to={`/${ProductosGarantiaM.link}/`}>
                  <LazyLoadImage width="100%" src={linkServidor + '/' + ProductosGarantiaM.dir_img} alt="logo" effect='opacity'/>
                </Link>
              </div>
            }
          </div>
        </div>
      }

      {/* <div className="tira-footer">
        <LazyLoadImage width="100%" src={tira3} alt="logo" effect='opacity'/>
      </div>
      <div className="subtira-footer">
        <LazyLoadImage width="100%" src={tira4} alt="logo" effect='opacity'/>
      </div> */}

      {/* <div>
        <div>
          <h2 className="titulo-redes poppins" style={{ marginBottom: "0%" }}>CUPONES</h2>
        </div>
        <div className="servicios-index" style={{ marginBottom: "1%" }}>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              }
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <div>
              <div className="col-4 contenedor-index">
                <SwiperSlide>
                  <LazyLoadImage width="85%" src={cupon1} alt="logo" effect='opacity'/>
                </SwiperSlide>
              </div>
              <div className="col-4 contenedor-index">
                <SwiperSlide>
                  <LazyLoadImage width="85%" src={cupon2} alt="logo" effect='opacity'/>
                </SwiperSlide>
              </div>
              <div className="col-4 contenedor-index">
                <SwiperSlide>
                  <LazyLoadImage width="85%" src={cupon3} alt="logo" effect='opacity'/>
                </SwiperSlide>
              </div>
            </div>
          </Swiper>
        </div>
      </div> */}
      <Footer/>

      {/* <button type="button" className="btn btn-primary" id='example' data-bs-toggle="modal" style={{ display: "none" }} data-bs-target="#exampleModalote">
      </button>

      <div className="modal fade" id="exampleModalote" tabindex="-1" data-backdrop="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ background: "transparent", border: "transparent" }}>
            <div className="modal-header" style={{ position: "absolute", right: "0%", top: "5%" }}>
              <button type="button" data-bs-dismiss="modal" aria-label="Close" style={{ background: "#ed571d", color: "white", zIndex: "1", fontSize: "25px", fontWeight: "900", width: "45px", height: "45px", border: "2px solid #ed571d", borderRadius: "50px", display: "flex", justifyContent: "center", marginTop: "-7%" }}>x</button>
            </div>
            <div className="modal-body" style={{ background: "transparent" }}>
              <img src={popup} alt="logo" style={{ width: "100%" }}/>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Inicio;