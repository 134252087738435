import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/Logos/prin-logo.webp';
// import logo from '../../assets/images/Logos/logo.svg';
import logo2 from '../../assets/images/Logos/logofondo.webp';
import logo3 from '../../assets/images/Logos/logocompuplaza.png';
import logocompu from '../../assets/images/Logos/logocompu.png';
import registro from '../../assets/images/ICONOREGISTRO.webp';
import image from '../../assets/images/lupa.png';
import login from '../../assets/images/LOGIN.webp';
import { baseUrl, ecoUrl, linkCuentas } from '../../services/apirest';
import { Link } from "react-router-dom";
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import {gapi} from 'gapi-script';

function Navbar(){
  const [CatsAndSubCats, setCatsAndSubCats] = useState([]);
  const [name, setName] = useState('');
  const [Apellido, setApellido] = useState('');
  const [Telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPassword_Confirmation] = useState('');
  const [ShowPwd, setShowPwd] = useState(false);
  const navigate = useNavigate();
  const [Usuario, setUsuario] = useState('');

  let cantidad = JSON.parse(sessionStorage.getItem('cantidad'))?JSON.parse(sessionStorage.getItem('cantidad')):0;

  const getUsuario = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${baseUrl}/getUsuario`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      // console.log(response.data.data.usuario.name);
      setUsuario(response.data.data.usuario.name);
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  const Register = (e) => {
    e.preventDefault();
    fetch(`${baseUrl}/registro`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name : name,
        lastname: Apellido,
        phone: Telefono,
        email : email,
        password : password,
        password_confirmation : password_confirmation,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      //console.log(json);
      localStorage.setItem("token", json.data.access_token);
      $("#registromodal").click();
      $('.modal-backdrop').remove();
      getUsuario();
    })  
    .catch((err)=>{
      console.log(err);
    });
  }

  const logGoogle = (e) => {
    fetch(`${baseUrl}/logGoogle`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name : e.profileObj.name,
        email : e.profileObj.email,
        password : e.googleId,
        password_confirmation : e.googleId,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      //console.log(json);
      localStorage.setItem("token", json.data.access_token);
      $("#CloseLogin").click();
      $("#closemovil").click();
      getUsuario();
    })  
    .catch((err)=>{
      console.log(err);
    });
  }

  const Login = (e) => {
    e.preventDefault();
    fetch(`${baseUrl}/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email : email,
        password : password,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      //console.log(json);
      localStorage.setItem("token", json.data.access_token);
      $("#CloseLogin").click();
      $("#closemovil").click();
      getUsuario();
    })  
    .catch((err)=>{
      console.log(err);
    });
  }

  const getAllCatsAndSubCats = () =>{
    axios.get(`${ecoUrl}/Store/getAllCatsAndSubCats`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      setCatsAndSubCats(response.data.data);
      $("#closemovil").click();
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  function handleSubmit(e) {
    e.preventDefault();    
    // console.log(e.target.children.busqueda.value);
    let busqueda = e.target.children.busqueda.value
    if(busqueda != ''){
      navigate('/search/'+busqueda);
      $("#close").click();
      $("#closemovil").click();
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    }else{
      console.log('busca algo')
    }
  }

  const Categoria = (value) =>{
    let BCategoria = value.descripcion;
    if(BCategoria != ''){
      navigate('/search/'+BCategoria);
      $("#close").click();
      $("#closemovil").click();
    }else{
      console.log('busca algo')
    }
  }

  const SubCategoria = (value) =>{
    let SubCategoria = value.descripcion;
    if(SubCategoria != ''){
      navigate('/search/'+SubCategoria);
      $("#close").click();
      $("#closemovil").click();
    }else{
      console.log('busca algo')
    }
  }

  const prueba = () =>{
    $("#close").click();
    $("#closemovil").click();
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  const componentDidMount = () => {
    $( "#registromodal" ).click(function() { 
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    })
    
    window.addEventListener("click", function(e){
      const detectarclass = e.target.classList.contains('offcanvas-backdrop');
      if(detectarclass == true){
        $("#close").click();
      }
      if(detectarclass == true){
        $("#closemovil").click();
      }

      const clases = e.target.classList.contains('modal');
      if(clases == true){
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      }
    })
  }

  const sesion = () =>{
    localStorage.removeItem('token');
    window.location.replace(`${linkCuentas}`);
  }

  const movilsesion = () =>{
    $("#close").click();
    $("#closemovil").click();
  }

  const cerrarnavbar = () =>{
    $("#close").click();
    $("#closemovil").click();
  }

  const clientId="587858083446-02d5lhr9iu6dahb93j6h07v9scuma79f.apps.googleusercontent.com";

  useEffect(()=>{
    getAllCatsAndSubCats();
    getUsuario();
    componentDidMount();
    gapi.load("client:auth2",()=>{
      gapi.auth2.init({clientId:clientId})
    })
  }, []);

  const containerStyle = {
    backgroundImage:
      `url(${image})`,
  };

  const responseGoogle = (response) => {
    logGoogle(response);
  }
  
  return(
    <div className="header-principal">
      <div className="card-header">
        <ul className="nav nav-tabs card-header-tabs" id="bologna-list" role="tablist">
          <p className="texto-header dinproLigth segundo col-12">¡ENCUENTRA TUS PRODUCTOS TECNOLÓGICOS AL MEJOR PRECIO. ENVÍOS A NIVEL NACIONAL!</p>
          <li className="nav-item">
            <Link to={`/`} className="nav-link dinproRegular" style={{ background: "#0e2f7f", color: "white" }}>Tienda online</Link>
          </li>
          <li className="nav-item">
            <Link to={`/corporativo`} className="nav-link dinproRegular" style={{ color: "white", background: "#0e2f7f" }} >Corporativo</Link>
          </li>
          <p className="texto-header dinproLigth primero">¡ENCUENTRA TUS PRODUCTOS TECNOLÓGICOS AL MEJOR PRECIO. ENVÍOS A NIVEL NACIONAL!</p>
        </ul>
      </div>
      <div className="navbar-logo activo-navbar" style={{ height: "70px" }}>
        <div className="col-3 cajon-logo">
          <Link to={`/`} style={{ display: "flex", background: "white", width: "60%", justifyContent: "center", borderBottomRightRadius: "20px", borderTopRightRadius: "20px" }}>
            <img src={logocompu} className="logo" alt="logo" style={{ padding: "4% 0%" }} />
          </Link>
          <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" className='barramenu'>
            <div className="menu-icon menu-icon-3">
              <div className="bar bar-1"></div>
              <div className="bar bar-2"></div>
              <div className="bar bar-3"></div>
            </div>
            <span className='stylomenu dinpro'>Menú</span>
          </a>
        </div>
        <div className="col-6 caja-buscador-header">
          <form onSubmit={handleSubmit} >  
            <input name="busqueda" style={containerStyle} className="buscador-header dinproRegular" type="search" placeholder="¿Qué estás buscando?"/>
          </form>
        </div>
        <div className="col-3 enlaces-navbar">
          {Usuario == "" &&
            <button type="button" className="cuenta-header" id='modallitosesion' data-bs-toggle="modal" data-bs-target="#exampleModall">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person icono-user-header" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
              </svg>
              <span className='dinproRegular'>Mi Cuenta</span>
            </button>
          }
          {Usuario != "" &&
            <div style={{ padding: "2%", alignItems: "center", display: "flex" }}>
              <ul>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    <button type="button" className="cuenta-header" style={{ padding: "2%" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person icono-user-header" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                      </svg> 
                      <span className='dinpro' style={{ fontSize: "13px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", paddingTop: "0%" }}>{Usuario}</span>
                    </button>
                  </a>
                  <ul className="dropdown" style={{ backgroundColor: "white", width: "120px", position: "absolute", marginTop: "0.5%", textAlign: "center", marginLeft: "-6%", borderRadius: "5px" }}>
                    <li style={{ padding: "6% 0%" }}>
                      <Link to="/perfil" style={{ fontSize: "16px", textDecoration: "none" }} className='dinproRegular'>Mi cuenta</Link>
                    </li>
                    <li style={{ padding: "6% 0%" }}>
                      <Link to="/pedidos" style={{ fontSize: "16px", textDecoration: "none" }} className='dinproRegular'>Mis pedidos</Link>
                    </li>
                    <li style={{ padding: "6% 0%" }}>
                      <a onClick={()=>sesion()} style={{ fontSize: "16px", textDecoration: "none" }} className='dinproRegular'>Cerrar sesión</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          }
          <div className="cuenta-header">
            <Link to={`/carrito`} style={{ color: "white", display: "inline-grid", textDecoration: "none" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-cart4 icono-user-header" viewBox="0 0 16 16">
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
              </svg>
              <span className='dinproRegular'>Carrito</span>
              <span className="position-absolute top-0 start-100 badge rounded-pill contador-carrito poppins-bold" style={{background: "#0e2f7f"}}>
                {cantidad}
              </span>
            </Link>
          </div>
        </div>
      </div>

      <div className='col-12 activo-navbar-secun-logo'>
        <img src={logocompu} className="logo3" alt="logo" width='14%' />
      </div>

      <div className="navbar-logo activo-navbar-secun">
        <div className="col-2 col-md-2 col-sm-3 col-lg-2 cajon-logo">
          {/* <Link to={`/`} style={{ display: "flex"}}>
            <img src={logo} className="logo" alt="logo" width='100%' />
            <img src={logo2} className="logo2" alt="logo" width='100%' />
          </Link> */}
          <a data-bs-toggle="offcanvas" href="#ExampleMovil" role="button" aria-controls="ExampleMovil" className='barramenumovil'>
            <div className="menu-icon menu-icon-3">
              <div className="bar bar-1"></div>
              <div className="bar bar-2"></div>
              <div className="bar bar-3"></div>
            </div>
            <span className='stylomenu dinpro'>Menú</span>
          </a>
          <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" className='barramenu'>
            <div className="menu-icon menu-icon-3">
              <div className="bar bar-1"></div>
              <div className="bar bar-2"></div>
              <div className="bar bar-3"></div>
            </div>
            <span className='stylomenu dinpro'>Menú</span>
          </a>
        </div>
        <div className="col-10 col-md-10 col-sm-9 col-lg-10 caja-buscador-header">
          <form onSubmit={handleSubmit} className='buscadormovil'>  
            <input name="busqueda" style={containerStyle} className="buscador-header dinproRegular" type="search" placeholder="¿Qué estás buscando?"/>
          </form>
        </div>
      </div>

      {/* SIDEBAR DE LAS CATEGORIAS */}
      <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">¡Hola!</h5>
          <button type="button" className="btn-close" id='close' data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="menu">
            {CatsAndSubCats.map((value, index) =>{
              return (
                <li key={index}>
                  <a style={{ paddingLeft: "19%" }} onClick={()=>Categoria(value)}>{value.descripcion}</a>
                  <ul className="submenu" style={{ paddingBottom: "4%" }}>
                    <li style={{ padding: "2.5%", fontSize: "20px", width: "65%", margin: "3% 6%", fontWeight: "700", color: "#ed571d" }}>
                      {value.descripcion}
                    </li>
                    <a onClick={()=>Categoria(value)} className="btn btn-inverse-success btn-fw" style={{ float: "right", marginTop: "-14%", marginRight: "3%", padding: "3%", border: "1px solid #ed571d", background: "#ed571d", color: "white" }}>
                      Ver todo
                    </a>
                    {value.subcats.map((value, index) =>{
                      return (
                      <li key={index} style={{ padding: "0% 3%" }}>
                        <a onClick={()=>SubCategoria(value)} style={{ paddingLeft: "9%", fontSize: "12px" }}>{value.descripcion}</a>
                      </li>
                      )
                    })}
                  </ul>
                </li>
              )
            })}

            <li>
              <Link style={{ paddingLeft: "19%" }} onClick={()=>prueba()} to={`/search/promociones`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ marginRight: "2%", color: "#ed571d" }} fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                </svg>
                Promociones
              </Link>
              <ul className="submenu" style={{ paddingBottom: "4%" }}>
                <li style={{ padding: "2.5%", fontSize: "20px", width: "65%", margin: "3% 6%", fontWeight: "700", color: "#ed571d" }}>
                  Promociones
                </li>
                <Link to={`/search/promociones`} onClick={()=>prueba()} className="btn btn-inverse-success btn-fw" style={{ float: "right", marginTop: "-14%", marginRight: "3%", padding: "3%", border: "1px solid #ed571d", background: "#ed571d", color: "white" }}>
                  Ver todo
                </Link>
                <li style={{ padding: "0% 3%" }}>
                  <Link to={`/search/MundoTecnologico`} onClick={()=>prueba()} style={{ paddingLeft: "9%", fontSize: "12px" }}>Mundo Tecnológico</Link>
                </li>
                <li style={{ padding: "0% 3%" }}>
                  <Link to={`/search/mejoresofertas`} onClick={()=>prueba()} style={{ paddingLeft: "9%", fontSize: "12px" }}>Mejores Ofertas</Link>
                </li>
                <li style={{ padding: "0% 3%" }}>
                  <Link to={`/search/OfertasInsuperables`} onClick={()=>prueba()} style={{ paddingLeft: "9%", fontSize: "12px" }}>Ofertas Insuperables</Link>
                </li>
              </ul>
            </li> 
          </ul>          
        </div>
      </div>

      <div className="offcanvas offcanvas-start" tabindex="-1" id="ExampleMovil" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          {Usuario == "" &&
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">¡Hola!</h5>
          }
          {Usuario != "" &&
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">¡Hola <span className='dinpro'> {Usuario}!</span></h5>
          }
          <button type="button" className="btn-close" id='closemovil' data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          {CatsAndSubCats.map((value) =>{
            return (
              <details style={{ width: "100%" }}>
                <summary href="" >
                  <a onClick={()=>Categoria(value)} className="categorias-movil">
                  {value.descripcion}
                  </a>
                </summary>
                {value.subcats.map((value) =>{
                  return (
                    <li style={{ marginBottom: "1%" }}>
                      <a onClick={()=>SubCategoria(value)} className="feat-btn2">{value.descripcion}</a>      
                    </li>
                  )
                })}
              </details>
            )
          })}

          <details style={{ width: "100%" }}>
            <summary href="" >
              <Link to={`/search/promociones`} onClick={()=>prueba()} className="categorias-movil">
                Promociones
              </Link>
            </summary>
            <li style={{ marginBottom: "1%" }}>
              <Link to={`/search/MundoTecnologico`} onClick={()=>prueba()} className="feat-btn2">Mundo Tecnológico</Link>      
            </li>
            <li style={{ marginBottom: "1%" }}>
              <Link to={`/search/mejoresofertas`} onClick={()=>prueba()} className="feat-btn2">Mejores Ofertas</Link>      
            </li>
            <li style={{ marginBottom: "1%" }}>
              <Link to={`/search/OfertasInsuperables`} onClick={()=>prueba()} className="feat-btn2">Ofertas Insuperables</Link>      
            </li>
          </details>

          <div style={{ position: "absolute", bottom: "0px", width: "100%", left: "0px" }}>
            {Usuario == "" &&
              <button type="button" onClick={()=>movilsesion()} className="btn btn-primary col-6" data-bs-toggle="modal" data-bs-target="#exampleModall" style={{ background: "#001267", border: "1px solid #001267" }}>Mi Cuenta</button>
            }
            {Usuario != "" &&
              <a onClick={()=>sesion()} className="btn btn-primary col-6" style={{ background: "#001267", border: "1px solid #001267" }}>
                <span style={{ color: "white", display: "inline-grid", textDecoration: "none" }}>
                  Cerrar sesión
                </span>
              </a>
            }
            <button type="button" onClick={()=>cerrarnavbar()} className="btn btn-primary col-6" style={{ background: "#001267", border: "1px solid #001267" }}>
              <Link to={`/carrito`} style={{ color: "white", display: "inline-grid", textDecoration: "none" }}>
                <span className='dinproRegular'>Carrito</span>
                <span className="position-absolute top-0 start-100 badge rounded-pill contador-carrito poppins-bold" style={{background: "#0e2f7f"}}>
                  {cantidad}
                </span>
              </Link>
            </button>
          </div>
        </div>
      </div>

      {/* MODAL DE INICIO DE SESIÓN */}
      <div className="modal fade" id="exampleModall" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content login-box">
            <img src={login} className="avatar" alt="Avatar Image" />
            <div className="modal-header">
              <button type="button" className="btn-close" id='CloseLogin' data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{ padding: "12% 8% 0%" }}>
              <form onSubmit={(e) => {Login(e)}}>
                <div className="input-group" style={{ marginBottom: "7%" }}>
                  <span className="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                    </svg>
                  </span>
                  <input type="email" className="form-control input-correo" placeholder="Correo electrónico" onChange={(e) => { setEmail(e.target.value) }} />
                </div>
                <div className="input-group mb-3 contraseña-campo">
                  <span className="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "white" }} fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
                    </svg>
                  </span>
                  <input type={ShowPwd ? "text" : "password"} id="typepass" className="form-control input-contraseña" placeholder="Contraseña" onChange={(e) => { setPassword(e.target.value) }} />
                  <span className='ojo-cotraseña' onClick={()=>setShowPwd(!ShowPwd)}>
                    {ShowPwd ? 
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ zIndex:"1" }} fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                      </svg> :
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ zIndex:"1" }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    }
                  </span>
                </div>
                <div>
                  <a style={{ fontSize: "14px", marginLeft: "4%", fontStyle: "oblique", textDecoration: "underline", color: "#8b8b8e"}}>Olvidé contraseña</a>
                </div>
                <div style={{ marginTop: "5%", display: "flex" }}>
                  <div className='col-6'>
                    <button type="submit" className="btn btn-ingresar-login">Ingresar</button>
                  </div>
                  <div className='col-6'>
                    <h6 style={{ textAlign: "center", fontSize: "15px" }}>o inicia sesión con</h6>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <GoogleLogin clientId={clientId} shadow-none buttonText="GOOGLE" onSuccess={responseGoogle} onFailure={responseGoogle} cookiePolicy={'single_host_origin'}/>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <h6 style={{ fontSize: "15px", margin: "5%", textAlign: "center", color: "#8b8b8e" }}>¿No tienes una cuenta?</h6>
            </div>
            <div>
              <button type="button" style={{ borderBottomRightRadius: "15px", borderBottomLeftRadius: "15px", background: "#fe6313", border: "1px solid #fe6313", color: "white", fontSize: "25px", fontWeight: "700" }} className="btn btn-outline-primary estilo-button-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Regístrate</button>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL DE REGISTRO */}
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content login-box">
            <form onSubmit={(e) => {Register(e)}}>
              <img src={registro} className="avatar" alt="Avatar Image" />
              <div className="modal-header">
                <button type="button" className="btn-close" id='registromodal' data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" style={{ padding: "8% 8% 0%" }}>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                    </svg>
                  </span>
                  <input type="text" className="form-control input-correo" style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }} placeholder="Nombre" onChange={(e) => { setName(e.target.value) }} />
                </div>
                <input type="text" className="form-control input-correo" placeholder="Apellido" style={{ marginBottom: "7%", width: "89.5%", float: "right", borderTopRightRadius: "0px", borderBottomLeftRadius: "15px" }} onChange={(e) => { setApellido(e.target.value) }} />
                
                <div className="input-group" style={{ marginBottom: "7%" }}>
                  <span className="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>
                  </span>
                  <input type="text" className="form-control input-correo" placeholder="Teléfono" onChange={(e) => { setTelefono(e.target.value) }} />
                </div>

                <div className="input-group" style={{ marginBottom: "7%" }}>
                  <span className="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{ color: "white" }} fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                    </svg>
                  </span>
                  <input type="email" className="form-control input-correo" placeholder="Correo electrónico" onChange={(e) => { setEmail(e.target.value) }} />
                </div>

                <div className="input-group contraseña-campo">
                  <span className="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "white" }} fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
                    </svg>
                  </span>
                  <input type={ShowPwd ? "text" : "password"} className="form-control input-contraseña" placeholder="Contraseña" id="password" name="password" onChange={(e) => { setPassword(e.target.value) }} />
                  <span className='ojo-cotraseña' style={{ borderBottomRightRadius: "0px" }} onClick={()=>setShowPwd(!ShowPwd)}>
                    {ShowPwd ? 
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ zIndex:"1" }} fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                      </svg> :
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{ zIndex:"1" }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    }
                  </span>
                </div>
                <input type={ShowPwd ? "text" : "password"} className="form-control input-contraseña" placeholder="Confirmar Contraseña" style={{ marginBottom: "7%", width: "89.5%", float: "right", borderTopRightRadius: "0px", borderBottomLeftRadius: "15px", borderRight: "1px solid #0056b9", borderLeft: "1px solid #0056b9", borderBottomRightRadius: "15px" }} onChange={(e) => { setPassword_Confirmation(e.target.value) }} />
              </div>
              <div>
                <button type="submit" className="btn estilo-button" style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px", padding: "2% 0%", fontSize: "25px", fontWeight: "700" }}>Registrarme</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;